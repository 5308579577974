import React from 'react';
import { Icon, IconProps, Box, BoxProps } from '@chakra-ui/react';
import {
  MdNotes,
  MdSubject,
  MdOutlineRadioButtonChecked,
  MdOutlineRadioButtonUnchecked,
  MdOutlineCheckBox,
  MdOutlineCheckBoxOutlineBlank,
  MdOutlineArrowDropDown,
  MdOutlineArrowDropDownCircle,
} from 'react-icons/md';
import { AnswerType } from '../../types/webInterview';

type Props = {
  boxProps: BoxProps;
  iconProps: IconProps;
  answerType: AnswerType;
  checked: boolean;
};

export const AnswerTypeIcon: React.VFC<Props> = ({
  boxProps,
  iconProps,
  answerType,
  checked,
}) => {
  return (
    <Box {...boxProps}>
      <Icon
        as={
          answerType === 'textbox'
            ? MdNotes
            : answerType === 'textline'
            ? MdSubject
            : answerType === 'radiobutton'
            ? checked
              ? MdOutlineRadioButtonChecked
              : MdOutlineRadioButtonUnchecked
            : answerType === 'checkbox'
            ? checked
              ? MdOutlineCheckBox
              : MdOutlineCheckBoxOutlineBlank
            : answerType === 'combobox'
            ? checked
              ? MdOutlineArrowDropDownCircle
              : MdOutlineArrowDropDown
            : MdNotes
        }
        {...iconProps}
        data-testid={`${answerType.toString()}-${
          checked ? 'checked' : 'noCheck'
        }`}
      />
    </Box>
  );
};
