import {
  PostInterviewSheet,
  PutInterviewSheet,
  PutInterviewSheetsSort,
} from '../types/interviewSheetApiTypes';
import { PostInterviewResult } from '../types/interviewResultApiTypes';
import { PutPersonalInformation } from '../types/interviewResultConfirmApiTypes';
import { PostTag, PutTag } from '../types/tagApiTypes';
import { PutClinicNotice } from '../types/clinicNoticeApiTypes';
import { PutHeaderImage } from '../types/headerImageApiTypes';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_WEB_INTERVIEW;
const S3_URL = process.env.REACT_APP_S3_ENDPOINT_WEB_INTERVIEW;

export const getInterviewSheets = async (tenantId: string) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/interview-sheets`, {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
};

export const getInterviewSheet = async (
  tenantId: string,
  interviewSheetId: string,
) => {
  return await fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-sheets/${interviewSheetId}`,
    {
      headers: {
        'Content-Type': 'application/json',
      },
      mode: 'cors',
    },
  );
};

export const postInterviewSheet = (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PostInterviewSheet,
) => {
  return fetch(`${BASE_URL}/tenants/${tenantId}/interview-sheets`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const putInterviewSheet = (
  tenantId: string,
  interviewSheetId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PutInterviewSheet,
) => {
  return fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-sheets/${interviewSheetId}`,
    {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
        'x-mic-user-id': userId,
        'x-mic-user-name': userName,
      },
      mode: 'cors',
      body: JSON.stringify(data),
    },
  );
};

export const putInterviewSheetsSort = (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PutInterviewSheetsSort,
) => {
  return fetch(`${BASE_URL}/tenants/${tenantId}/interview-sheets/sort`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const deleteInterviewSheet = (
  tenantId: string,
  interviewSheetId: string,
) => {
  //todo:
};

export const getInterviewResults = async (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
  queryParameters?: { key: string; value: string }[],
) => {
  const queryString = queryParameters
    ?.map((q) => `${q.key}=${q.value}`)
    .join('&');
  return await fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-results?${queryString}`,
    {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
        'x-mic-user-id': userId,
        'x-mic-user-name': userName,
      },
      mode: 'cors',
    },
  );
};

export const getInterviewResult = async (
  tenantId: string,
  interviewResultId: string,
  userId: string,
  userName: string,
  accessToken: string,
) => {
  return await fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-results/${interviewResultId}`,
    {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
        'x-mic-user-id': userId,
        'x-mic-user-name': userName,
      },
      mode: 'cors',
    },
  );
};

export const postInterviewResult = (
  tenantId: string,
  data: PostInterviewResult,
) => {
  return fetch(`${BASE_URL}/tenants/${tenantId}/interview-results`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const putInterviewResult = (
  tenantId: string,
  interviewResultId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PutPersonalInformation,
) => {
  return fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-results/${interviewResultId}`,
    {
      method: 'PUT',
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
        'x-mic-user-id': userId,
        'x-mic-user-name': userName,
      },
      mode: 'cors',
      body: JSON.stringify(data),
    },
  );
};

export const getInterviewResultHistories = async (
  tenantId: string,
  interviewResultId: string,
  userId: string,
  userName: string,
  accessToken: string,
) => {
  return fetch(
    `${BASE_URL}/tenants/${tenantId}/interview-results/${interviewResultId}/histories`,
    {
      headers: {
        accept: 'application/json',
        authorization: `Bearer ${accessToken}`,
        'x-mic-user-id': userId,
        'x-mic-user-name': userName,
      },
      mode: 'cors',
    },
  );
};

export const getTags = async (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/tags`, {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
  });
};

export const postTag = async (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PostTag,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/tags`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const putTag = async (
  tenantId: string,
  tagId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PutTag,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/tags/${tagId}`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const deleteTag = async (
  tenantId: string,
  tagId: string,
  userId: string,
  userName: string,
  accessToken: string,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/tags/${tagId}`, {
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
  });
};

export const getClinicNotice = async (tenantId: string) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/clinic-notice`, {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
};

export const putClinicNotice = async (
  tenantId: string,
  userId: string,
  userName: string,
  accessToken: string,
  data: PutClinicNotice,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/clinic-notice`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const getHeaderImage = async (tenantId: string) => {
  return await fetch(`${S3_URL}/${tenantId}/header.jpg`, {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
};

export const putHeaderImage = async (
  tenantId: string,
  accessToken: string,
  data: PutHeaderImage,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/header-image`, {
    method: 'PUT',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};

export const deleteHeaderImage = async (
  tenantId: string,
  accessToken: string,
) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}/header-image`, {
    method: 'DELETE',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
    },
    mode: 'cors',
  });
};
