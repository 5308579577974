import React from 'react';
import { useHistory } from 'react-router-dom';
import { Auth0Provider, CacheLocation } from '@auth0/auth0-react';
import { getConfig } from './config';

const config = getConfig();

type Props = {
  children: React.ReactNode;
};

export const Auth0ProviderWithHistory: React.VFC<Props> = ({ children }) => {
  const history = useHistory();

  const onRedirectCallback = (appState: any) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  const providerConfig = {
    domain: config.domain,
    clientId: config.clientId,
    connection: config.connection,
    ...(config.audience ? { audience: config.audience } : null),
    scope: config.scope,
    redirectUri: `${window.location.origin}/Dashboard`,
    prompt: config.prompt,
    onRedirectCallback: onRedirectCallback,
    useRefreshTokens: true,
    cacheLocation: 'localstorage' as CacheLocation,
  };

  return <Auth0Provider {...providerConfig}>{children}</Auth0Provider>;
};
