import React, { useContext } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { Button, Text, VStack } from '@chakra-ui/react';
import { Paper } from './atoms/Paper';
import { InterviewResultContext } from '../contexts/InterviewResultContext';

type Props = {
  pageNumber: number;
};

export const Stepper: React.VFC<Props> = ({ pageNumber }) => {
  const history = useHistory();
  const { id: tenantId } = useParams<{ id: string }>();
  const { state } = useContext(InterviewResultContext);
  const steps = [
    {
      number: 1,
      title:
        pageNumber === 1
          ? '問診票選択'
          : state[0]?.interviewSheetName ?? '問診票選択',
      url: `/tenants/${tenantId}/interviewSelect`,
    },
    {
      number: 2,
      title: '個人情報入力',
      url: `/tenants/${tenantId}/interviewPersonalInformation?interviewSheetId=${state[0]?.interviewSheetId}`,
    },
    {
      number: 3,
      title: '問診票入力',
      url: `/tenants/${tenantId}/interviewQuestion`,
    },
    {
      number: 4,
      title: '確認',
      url: `/tenants/${tenantId}/interviewQuestion`,
    },
  ];
  return (
    <VStack spacing="3" mb="4">
      {steps.map(({ number, title, url }, idx) =>
        pageNumber > number ? (
          <Button
            colorScheme="webInterview"
            w="full"
            onClick={() => history.push(url)}
            key={idx}
          >
            {title}
          </Button>
        ) : pageNumber === number ? (
          <Paper w="full" h="40px" p="2" key={idx}>
            <Text align="center" color="gray.600" fontWeight="bold">
              {title}
            </Text>
          </Paper>
        ) : (
          <Paper w="full" h="40px" p="2" bg="gray.100" key={idx}>
            <Text align="center" color="gray.400" fontWeight="bold">
              {title}
            </Text>
          </Paper>
        ),
      )}
    </VStack>
  );
};
