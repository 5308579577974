import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { useGetMetadata } from '../hooks/useGetMetadata';
import { PATHNAME, ROLES } from '../constant/constant';
import { Paper } from './atoms/Paper';

type Props = {
  children: React.ReactNode;
  pathName: string;
};

export const Authorization: React.VFC<Props> = ({ children, pathName }) => {
  const { auth0Roles } = useGetMetadata();

  // ロールによるアクセス制限の定義
  const accessAuthority = [
    {
      name: PATHNAME.INTERVIEW_SHEET,
      permission: [ROLES.ADMINISTRATOR, ROLES.MANAGER],
    },
    {
      name: PATHNAME.INTERVIEW_SHEETS,
      permission: [ROLES.ADMINISTRATOR, ROLES.MANAGER],
    },
    {
      name: PATHNAME.INTERVIEW_RESULT,
      permission: [ROLES.ADMINISTRATOR, ROLES.MANAGER, ROLES.USER],
    },
    {
      name: PATHNAME.INTERVIEW_RESULTS,
      permission: [ROLES.ADMINISTRATOR, ROLES.MANAGER, ROLES.USER],
    },
    {
      name: PATHNAME.CLINIC_NOTICE,
      permission: [ROLES.ADMINISTRATOR, ROLES.MANAGER, ROLES.USER],
    },
  ];

  // ロールの確認
  const permission = accessAuthority
    .find((a) => a.name === pathName)
    ?.permission.filter((p) => auth0Roles.includes(p));
  const isAuthorityEnabled = permission ? permission.length > 0 : false;

  return (
    <>
      {isAuthorityEnabled ? (
        <> {children}</>
      ) : (
        <>
          <Paper>
            <Box textAlign="center">
              <Box
                w="100px"
                h="100px"
                borderRadius="full"
                bg="white"
                borderWidth="10px"
                borderStyle="solid"
                borderColor="yellow.200"
                display="inline-block"
                textAlign="center"
                m="10"
              >
                <Text color="gray.600" fontSize="53" fontWeight="bold">
                  ！
                </Text>
              </Box>
              <Text color="gray.600" fontSize="2xl" mb="4">
                このページへのアクセス権限がありません
              </Text>
              <Text color="gray.600" mb="10" fontSize="sm">
                システム管理者へお問い合わせください
              </Text>
            </Box>
          </Paper>
        </>
      )}
    </>
  );
};
