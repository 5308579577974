import React from 'react';
import { Box, Button, Image, Heading, VStack } from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Copyright } from '../components/Layout/Copyright';

export const Login: React.VFC = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <>
      <Box bg="gray.50" pt="20" minH="100vH">
        <VStack
          maxW="sm"
          minH="50vH"
          bg="white"
          mx="auto"
          borderRadius="15"
          boxShadow="md"
          p="10"
          spacing="12"
        >
          <Heading color="blue.300" mt="10">
            Web問診票
          </Heading>
          <Box color="gray.500" textAlign="center">
            アカウントの作成は
            <br />
            MICベースシステム（Windowsアプリ）
            <br />
            から行ってください
          </Box>
          <Box w="full">
            <Button
              colorScheme="blue"
              w="full"
              onClick={() => loginWithRedirect({})}
            >
              ログイン
            </Button>
          </Box>
          <Box>
            <Image
              src="https://bws.mic.jp/wp-content/uploads/2021/05/logo.png"
              alt="mic logo"
              mb="2"
            />
            <Copyright />
          </Box>
        </VStack>
      </Box>
    </>
  );
};
