import React, { createContext } from 'react';
import { ConditionAction } from '../actions/conditionAction';
import { Condition } from '../types/condition';

export const ConditionContext = createContext(
  {} as {
    state: Condition[];
  },
);

export const SetConditionContext = createContext(
  {} as {
    dispatch: React.Dispatch<ConditionAction>;
  },
);
