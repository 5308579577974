import React from 'react';
import { Box, HStack, Text, Tag } from '@chakra-ui/react';
import { Tag as TagType } from '../../types/interviewResultConfirm';
import { AnswerType } from '../../types/webInterview';

type Props = {
  answerType: AnswerType;
  description: string;
  descriptionNotesPrefix: string;
  descriptionNotesSuffix: string;
  tags: TagType[];
};

export const InterviewResultAnswerText: React.VFC<Props> = ({
  answerType,
  description,
  descriptionNotesPrefix,
  descriptionNotesSuffix,
  tags,
}) => {
  return (
    <>
      {answerType === 'textline' ? (
        <Box textAlign="left">
          {description ? (
            <>
              <Text color="gray.600">{descriptionNotesPrefix ?? ''}</Text>
              <Box
                bg="gray.100"
                px="2"
                borderWidth="1px"
                my="1"
                w="fit-content"
              >
                {description.split(/\n/).map((line, idx) => (
                  <Text key={idx} align="start" color="gray.600">
                    {line}
                  </Text>
                ))}
              </Box>
              <Text color="gray.600">{descriptionNotesSuffix ?? ''}</Text>
            </>
          ) : (
            <Text color="gray.600">入力なし</Text>
          )}
        </Box>
      ) : (
        <HStack textAlign="left">
          {description ? (
            <>
              <Text color="gray.600">{descriptionNotesPrefix ?? ''}</Text>
              <Text
                color="gray.600"
                bg="gray.100"
                px="2"
                w="fit-content"
                borderWidth="1px"
              >
                {description}
              </Text>
              <Text color="gray.600">{descriptionNotesSuffix ?? ''}</Text>
            </>
          ) : (
            <Text color="gray.600">入力なし</Text>
          )}
        </HStack>
      )}
      <HStack my="3">
        {tags.map((t, idx) => (
          <Tag
            key={idx}
            size="sm"
            colorScheme={t.isSystemOnly ? 'blue' : !t.enable ? 'red' : 'gray'}
          >
            {t.name}
          </Tag>
        ))}
      </HStack>
    </>
  );
};
