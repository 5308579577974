import { useCallback, useContext, useEffect, useState } from 'react';
import { TenantContext, SetTenantContext } from '../contexts/TenantContext';
import { GET_TENANT } from '../actions/tenantAction';
import { getTenant } from '../api/tenant';
import { GetTenant } from '../types/tenantApiTypes';
import { toTenant } from '../domains/toTenant';

export const useTenant = (tenantId: string) => {
  const { state } = useContext(TenantContext);
  const { dispatch } = useContext(SetTenantContext);
  const [resultStatus, setResultStatus] = useState('');
  const getTenantData = useCallback(async (): Promise<void> => {
    try {
      const result = await getTenant(tenantId);
      if (result.ok) {
        const data: GetTenant = await result.json();
        dispatch({
          type: GET_TENANT,
          data: toTenant(data),
        });
      }
      setResultStatus(result.status.toString());
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, tenantId]);

  useEffect(() => {
    if (tenantId !== state.tenantId) {
      getTenantData();
    }
  }, [state, tenantId, getTenantData]);

  return { state, resultStatus };
};
