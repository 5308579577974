import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Flex,
  Input,
  Button,
  Text,
  useToast,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { BUTTON } from '../../components/atoms/Button';
import { Tag } from '../../types/tag';
import { newId } from '../../domains/newId';
import { PostTag, PutTag } from '../../types/tagApiTypes';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { postTag, putTag } from '../../api';

type Props = {
  tag: Tag;
  handleCancel: () => void;
  setLoaded: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  setEditing?: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  setLoadingCircle: React.Dispatch<React.SetStateAction<boolean>>;
};

const createPostData: (tag: Tag, tagName: string) => PostTag = (
  { enable, isSystemOnly },
  tagName,
) => {
  return {
    id: newId('Tag'),
    name: tagName,
    is_system_only: isSystemOnly,
    enable,
  };
};

const createPutData: (tag: Tag, tagName: string) => PutTag = (
  { enable, isSystemOnly, version },
  tagName,
) => {
  return {
    name: tagName,
    is_system_only: isSystemOnly,
    enable,
    version,
  };
};

export const TagRowEdit: React.VFC<Props> = ({
  tag,
  handleCancel,
  setLoaded,
  setEditing,
  setLoadingCircle,
}) => {
  const [tagName, setTagName] = useState(tag.name);
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const isNew = tag.tagId.length === 0;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const toast = useToast();

  const handleSave = async () => {
    const accessToken = await getAccessTokenSilently({
      audience: 'https://www.webinterview.mic.jp/',
      scope: isNew ? 'create:tags' : 'update:tags',
    });
    const postData = async (): Promise<void> => {
      try {
        setLoadingCircle(true);
        const result = isNew
          ? await postTag(
              tenantId,
              userId,
              userName,
              accessToken,
              createPostData(tag, tagName),
            )
          : await putTag(
              tenantId,
              tag.tagId,
              userId,
              userName,
              accessToken,
              createPutData(tag, tagName),
            );
        if (result.ok) {
          setLoaded.toggle();
          toast({
            title: '保存完了',
            description: 'タグの変更内容を保存しました',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        setLoaded.toggle();
        console.log(error);
      }
    };
    postData();
    if (setEditing) {
      setEditing.off();
    }
  };
  const isError = tagName.length > 20;
  return (
    <FormControl isInvalid={isError}>
      <Flex>
        <Input
          size="sm"
          value={tagName}
          onChange={(e) => setTagName(e.target.value)}
          maxLength={20}
        />
        <BUTTON
          text="保存"
          buttonProps={{ size: 'sm', ml: '2', isDisabled: tagName.length < 1 }}
          onClick={handleSave}
        ></BUTTON>
        <Button size="sm" ml="2" onClick={handleCancel}>
          <Text size="sm" m="3">
            キャンセル
          </Text>
        </Button>
      </Flex>
      {!isError ? (
        <></>
      ) : (
        <FormErrorMessage fontWeight="normal">
          タグ名は20文字以内で入力してください
        </FormErrorMessage>
      )}
    </FormControl>
  );
};
