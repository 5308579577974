import React, { createContext } from 'react';
import { InterviewResultAction } from '../actions/interviewResultAction';
import { InterviewResult } from '../types/InterviewResult';

export const InterviewResultContext = createContext(
  {} as {
    state: InterviewResult[];
  },
);

export const SetInterviewResultContext = createContext(
  {} as {
    dispatch: React.Dispatch<InterviewResultAction>;
  },
);
