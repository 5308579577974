import React, { useState, useContext, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Flex,
  Heading,
  Text,
  useToast,
  useDisclosure,
  CircularProgress,
} from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DraggingStyle,
  NotDraggingStyle,
  DropResult,
} from 'react-beautiful-dnd';
import { useAuth0 } from '@auth0/auth0-react';
import { ReleaseBadge } from './ReleaseBadge';
import { reorder } from '../../utils/reorder';
import { InterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { ModalDialog } from '../../components/ModalDialog';
import { PutInterviewSheetsSort } from '../../types/interviewSheetApiTypes';
import { putInterviewSheetsSort } from '../../api';
import {
  TOAST_SUCCESS_OPTION,
  TOAST_ERROR_OPTION,
} from '../../constant/systemMessage';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  setReload: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const SortModal: React.FC<Props> = ({ isOpen, onClose, setReload }) => {
  const { state } = useContext(InterviewSheetContext);
  const [interviewSheets, setInterviewSheets] = useState(state);
  const [loading, setLoading] = useState(false);

  const toast = useToast();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const userId = user?.sub ?? '';
  const userName = user?.name ?? '';
  const { tenantId } = auth0UserData;

  const handleSave = async () => {
    try {
      setLoading(true);
      saveOnClose();
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'update:sort-sheets',
      });
      const data: PutInterviewSheetsSort = {
        request_list: interviewSheets.map(
          ({ interviewSheetId, displayPriority }) => ({
            id: interviewSheetId,
            display_priority: displayPriority,
          }),
        ),
      };
      const response = await putInterviewSheetsSort(
        tenantId,
        userId,
        encodeURI(userName),
        accessToken,
        data,
      );
      if (response.ok) {
        toast({
          title: '並び替えた内容を保存しました',
          ...TOAST_SUCCESS_OPTION,
        });
      } else {
        throw new Error(response.status.toString());
      }
    } catch (error) {
      toast({
        title: `エラー {${error}}`,
        description: (
          <>
            <Text>エラーが発生しました。</Text>
            <Text>恐れ入りますが、再度操作をやり直してください。</Text>
          </>
        ),
        ...TOAST_ERROR_OPTION,
      });
      console.error(error);
    } finally {
      onClose();
      setReload.toggle();
      setLoading(false);
    }
  };

  useEffect(() => {
    setInterviewSheets(state);
  }, [state, isOpen]);

  const getItemStyle = (
    isDragging: boolean,
    draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
  ) => ({
    background: isDragging ? '#EBF8FF' : 'white',
    ...draggableStyle,
  });

  const handleOnDragEnd = ({ source, destination }: DropResult) => {
    if (!destination) {
      return;
    }

    const reorderedSheets = reorder(
      interviewSheets,
      source.index,
      destination.index,
    ).map((sheet, idx) => ({ ...sheet, displayPriority: idx + 1 }));
    setInterviewSheets(reorderedSheets);
  };

  const {
    isOpen: saveIsOpen,
    onOpen: saveOnOpen,
    onClose: saveOnClose,
  } = useDisclosure();
  const {
    isOpen: cancelIsOpen,
    onOpen: cancelOnOpen,
    onClose: cancelOnClose,
  } = useDisclosure();
  const saveCancelRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const handleDiscard = () => {
    cancelOnClose();
    onClose();
  };

  return (
    <>
      <ModalDialog
        header={
          loading ? (
            <></>
          ) : (
            <Heading size="md" color="gray.600" textAlign="left">
              並び替え
            </Heading>
          )
        }
        body={
          loading ? (
            <Flex w="full" h="lg" justifyContent="center" alignItems="center">
              <CircularProgress isIndeterminate color="blue.300" />
            </Flex>
          ) : (
            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="interviewSheets">
                {(provided) => (
                  <Box
                    className="interviewSheets"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {interviewSheets.map(
                      (
                        { interviewSheetId, name, description, releaseDate },
                        idx,
                      ) => (
                        <Draggable
                          key={interviewSheetId}
                          draggableId={interviewSheetId}
                          index={idx}
                        >
                          {(provided, snapshot) => (
                            <Flex
                              borderWidth="1px"
                              borderRadius="6px"
                              mb="3"
                              shadow="lg"
                              key={interviewSheetId}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                              )}
                            >
                              <Box flex="1">
                                <Box
                                  ml="5px"
                                  p="1"
                                  fontWeight="bold"
                                  w="100%"
                                  color="blue.600"
                                >
                                  {name}
                                </Box>
                                <Flex justifyContent="space-between" w="100%">
                                  <Flex>
                                    <Box
                                      ml="5px"
                                      p="1"
                                      fontSize="12px"
                                      color="gray.600"
                                    >
                                      {description}
                                    </Box>
                                  </Flex>
                                  <Flex mr="5px" mb="5px" alignItems="flex-end">
                                    <Box>
                                      <ReleaseBadge
                                        releaseDate={new Date(releaseDate)}
                                      />
                                    </Box>
                                  </Flex>
                                </Flex>
                              </Box>
                            </Flex>
                          )}
                        </Draggable>
                      ),
                    )}
                    {provided.placeholder}
                  </Box>
                )}
              </Droppable>
            </DragDropContext>
          )
        }
        footer={
          loading ? (
            <></>
          ) : (
            <>
              <Button colorScheme="webInterview" mr={3} onClick={saveOnOpen}>
                保存
              </Button>
              <Button onClick={cancelOnOpen}>キャンセル</Button>
            </>
          )
        }
        isOpen={isOpen}
        onClose={onClose}
      />

      <ConfirmDialog
        isOpen={saveIsOpen}
        cancelRef={saveCancelRef}
        onClose={saveOnClose}
        headerText={'並び替えの保存'}
        bodyText={'並び替えた内容を保存します。よろしいですか？'}
        confirmOk={
          <Button colorScheme="webInterview" onClick={handleSave} mr={3}>
            保存
          </Button>
        }
        confirmCancel={
          <Button ref={saveCancelRef} onClick={saveOnClose}>
            キャンセル
          </Button>
        }
      />
      <ConfirmDialog
        isOpen={cancelIsOpen}
        cancelRef={cancelRef}
        onClose={cancelOnClose}
        headerText={'並び替えの破棄'}
        bodyText={'並び替えた内容を破棄します。よろしいですか？'}
        confirmOk={
          <Button colorScheme="red" onClick={handleDiscard} mr={3}>
            破棄
          </Button>
        }
        confirmCancel={
          <Button ref={cancelRef} onClick={cancelOnClose}>
            キャンセル
          </Button>
        }
      />
    </>
  );
};
