import React from 'react';
import { Box, ListItem, Text, OrderedList } from '@chakra-ui/react';

type Props = {};

export const PrivacyPolicy: React.VFC<Props> = () => {
  return (
    <Box
      h="400px"
      border="1px"
      borderRadius="md"
      borderColor="gray.200"
      overflow="auto"
    >
      <Header>プライバシーポリシー</Header>
      <Line>
        株式会社ミック（以下、「当社」といいます。）は、本ウェブサイト上で提供するサービス（以下、「本サービス」といいます。）における、利用者の個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
      </Line>
      <Header>第 1 条（個人情報）</Header>
      <Line>
        「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
      </Line>
      <Header>第 2 条（個人情報の収集方法）</Header>
      <Line>
        当社は、利用者が利用登録をする際に氏名、生年月日、電話番号、メールアドレスなどの個人情報をお尋ねすることがあります。また、利用者と提携先（医療機関などを含みます。以下、｢提携先｣といいます。）などとの間でなされた利用者の個人情報を含む本サービスの使用に関する情報を、当社の提携先などから収集することがあります。
      </Line>
      <Header>第 3 条（個人情報を収集・利用する目的）</Header>
      <Line>当社が個人情報を収集・利用する目的は、以下のとおりです。</Line>
      <OrderedList>
        <LItem>本サービスの提供・運営のため</LItem>
        <LItem>
          利用者からのお問い合わせに回答するため（本人確認を行うことを含む）
        </LItem>
        <LItem>
          利用規約に違反した利用者や、不正・不当な目的で本サービスを利用しようとする利用者の特定をし、ご利用をお断りするため
        </LItem>
        <LItem>上記の利用目的に付随する目的</LItem>
      </OrderedList>
      <Header>第 4 条（利用目的の変更）</Header>
      <OrderedList>
        <LItem>
          当社は、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。
        </LItem>
        <LItem>
          利用目的の変更を行った場合には、変更後の目的について、当社所定の方法により、利用者に通知し、または本ウェブサイト上に公表するものとします。
        </LItem>
      </OrderedList>
      <Header>第 5 条（個人情報の第三者提供）</Header>
      <OrderedList>
        <LItem>
          当社は、次に掲げる場合を除いて、あらかじめ利用者の同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
        </LItem>
        <OrderedList>
          <LItem>
            人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき
          </LItem>
          <LItem>
            公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき
          </LItem>
          <LItem>
            国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき
          </LItem>
          <LItem>
            予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき
          </LItem>
          <OrderedList>
            <LItem>利用目的に第三者への提供を含むこと</LItem>
            <LItem>第三者に提供されるデータの項目</LItem>
            <LItem>第三者への提供の手段または方法</LItem>
            <LItem>
              本人の求めに応じて個人情報の第三者への提供を停止すること
            </LItem>
            <LItem>本人の求めを受け付ける方法</LItem>
          </OrderedList>
        </OrderedList>
        <LItem>
          前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
        </LItem>
        <OrderedList>
          <LItem>
            当社が利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合
          </LItem>
          <LItem>
            合併その他の事由による事業の承継に伴って個人情報が提供される場合
          </LItem>
          <LItem>
            個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
          </LItem>
        </OrderedList>
      </OrderedList>
      <Header>第 6 条（個人情報の開示）</Header>
      <OrderedList>
        <LItem>
          当社は、利用者から個人情報の開示を求められたときは、利用者ご本人からのご請求であることを確認のうえで、利用者に対し、遅滞なく開示を行います。但し、個人情報保護法その他の法令により、当社が開示の義務を負わない場合は、この限りではありません。なお、個人情報の開示に際しては、1
          件あたり 400 円（消費税別途）の手数料を申し受けます。
        </LItem>
        <LItem>
          前項の定めにかかわらず、履歴情報および特性情報などの個人情報以外の情報については、原則として開示いたしません。
        </LItem>
      </OrderedList>
      <Header>第 7 条（個人情報の訂正および利用停止等）</Header>
      <OrderedList>
        <LItem>
          当社は、利用者から、（１）個人情報が真実でないという理由によって個人情報保護法の定めに基づきその内容の訂正を求められた場合、及び（２）予め公表された利用目的の範囲を超えて取り扱われているという理由又は偽りその他不正の手段により取得されたものであるという理由によって個人情報保護法の定めに基づきその利用の停止を求められた場合には、お客様ご本人からの請求であることを確認の上で、遅滞なく必要な調査を行い、その結果に基づき、個人情報の内容の訂正又は利用停止を行い、利用者に対してその旨を通知します。
        </LItem>
        <LItem>
          当社は、利用者から、利用者の個人情報について消去を求められた場合には、当社が当該請求に応じる必要があると判断した場合に限り、利用者ご本人からの請求であることを確認の上で、遅滞なく個人情報の消去を行い、利用者に対してその旨を通知します。
        </LItem>
        <LItem>
          前各項の請求に際しては、次の内容その他当社所定の情報・資料を送付してください。
        </LItem>
        <OrderedList>
          <LItem>氏名・住所・電話番号・メールアドレス</LItem>
          <LItem>請求内容・経緯・根拠資料</LItem>
          <LItem>本人確認資料</LItem>
        </OrderedList>
        <LItem>
          以下の各号のいずれかに該当する場合は、第１項及び第２項の請求に応じることができない場合があります。その際は、利用者に対してその旨を通知すると共に、理由を説明します。
        </LItem>
        <OrderedList>
          <LItem>
            利用者又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合
          </LItem>
          <LItem>
            当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合
          </LItem>
          <LItem>法令に違反することとなる場合</LItem>
          <LItem>利用者ご本人からの請求であることが確認できない場合</LItem>
        </OrderedList>
      </OrderedList>
      <Header>第８条（プライバシーポリシーの変更）</Header>
      <OrderedList>
        <LItem>
          本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、利用者に通知することなく、変更することができるものとします。
        </LItem>
        <LItem>
          当社が別途定める場合を除いて、変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
        </LItem>
      </OrderedList>
      <Header>第９条（お問い合わせ窓口）</Header>
      <Line>
        本ポリシーに関するお問い合わせは，下記の窓口までお願いいたします。
      </Line>
      <LineSpacing />
      <Line>個人情報保護に関してのお問い合わせ先</Line>
      <Line>当社の個人情報保護管理者 : 株式会社ミック 人事総務担当取締役</Line>
      <Line>住所 : 千葉県柏市若柴226番地44 中央141街区1 KOIL TERRACE 5F</Line>
      <Line>E-mail : privacy@mic.jp</Line>
      <Line>TEL : 04-7130-9003 FAX : 04-7130-9002</Line>
    </Box>
  );
};

type ContentsProps = {
  children?: React.ReactNode;
};

const Header: React.VFC<ContentsProps> = ({ children }) => (
  <Text align="start" color="gray.600" fontWeight="bold" m="3">
    {children}
  </Text>
);
const Line: React.VFC<ContentsProps> = ({ children }) => (
  <Text align="start" color="gray.600" mx="3">
    {children}
  </Text>
);

const LineSpacing = () => <Box h="16px"></Box>;

const LItem: React.VFC<ContentsProps> = ({ children }) => (
  <ListItem ml="6">
    <Text align="start">{children}</Text>
  </ListItem>
);
