export const ageCalculation = (data: string) => {
  const date = new Date(data);
  const today = new Date();
  const thisYearsBirthday = new Date(
    today.getFullYear(),
    date.getMonth(),
    date.getDate(),
  );
  const age = today.getFullYear() - date.getFullYear();

  return today < thisYearsBirthday ? age - 1 : age;
};
