import { useAuth0 } from '@auth0/auth0-react';
import { ROLES } from '../constant/constant';

// Auth0 Rules [Add id Token - for frontend]
type userMetadataType = {
  tenant_id: string;
};
type appMetadataType = {
  previousLogin: string;
};

// hooks return
type auth0UserDataType = {
  tenantId: string;
};
type auth0AppDataType = {
  previousLogin: Date;
};

export const useGetMetadata = () => {
  const userNameSpace = 'https://mic-base-system.com/user_metadata';
  const appNameSpace = 'https://mic-base-system.com/app_metadata';
  const rolesNameSpace = 'https://mic-base-system.com/roles';

  const { user } = useAuth0<{
    [userNameSpace]: userMetadataType;
    [appNameSpace]: appMetadataType;
    [rolesNameSpace]: string[];
  }>();

  const auth0UserData: auth0UserDataType = {
    tenantId: user ? user[userNameSpace].tenant_id : '',
  };
  const auth0AppData: auth0AppDataType = {
    previousLogin: new Date(
      user ? user[appNameSpace].previousLogin : '1900-01-01T00:00',
    ),
  };
  const auth0Roles: string[] = user
    ? user[rolesNameSpace].filter(
        (role) =>
          role.indexOf('Web Interview') > -1 || role === ROLES.ADMINISTRATOR,
      )
    : [];

  return { auth0UserData, auth0AppData, auth0Roles };
};
