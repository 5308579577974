import { QuestionItem } from '../types/questionItem';
export const ADD_QUESTION_ITEM = 'ADD_QUESTION_ITEM';
export const REMOVE_QUESTION_ITEM = 'REMOVE_QUESTION_ITEM';
export const REMOVE_QUESTION_ITEMS = 'REMOVE_QUESTION_ITEMS';
export const CHANGE_CHOICE_TEXT = 'CHANGE_CHOICE_TEXT';
export const CHANGE_DESCRIPTION_PREFIX = 'CHANGE_DESCRIPTION_PREFIX';
export const CHANGE_DESCRIPTION_SUFFIX = 'CHANGE_DESCRIPTION_SUFFIX';
export const CHANGE_DESCRIPTION_PLACEHOLDER = 'CHANGE_DESCRIPTION_PLACEHOLDER';
export const CHANGE_HAS_CHOICE_NOTES = 'CHANGE_HAS_CHOICE_NOTES';
export const CHANGE_CHOICE_NOTES_PREFIX = 'CHANGE_CHOICE_NOTES_PREFIX';
export const CHANGE_CHOICE_NOTES_SUFFIX = 'CHANGE_CHOICE_NOTES_SUFFIX';
export const CHANGE_CHOICE_NOTES_PLACEHOLDER =
  'CHANGE_CHOICE_NOTES_PLACEHOLDER';
export const CHANGE_CHOICE_SORT_NUMBER = 'CHANGE_CHOICE_SORT_NUMBER';
export const CHANGE_TAGS = 'CHANGE_TAGS';
export const GET_INTERVIEWSHEET = 'GET_INTERVIEWSHEET';

export type QuestionItemAction =
  | {
      type: 'ADD_QUESTION_ITEM';
      questionId: string;
    }
  | {
      type: 'REMOVE_QUESTION_ITEM';
      questionItemId: string;
    }
  | {
      type: 'REMOVE_QUESTION_ITEMS';
    }
  | { type: 'CHANGE_CHOICE_TEXT'; questionItemId: string; text: string }
  | {
      type: 'CHANGE_DESCRIPTION_PREFIX';
      questionItemId: string;
      prefix: string;
    }
  | {
      type: 'CHANGE_DESCRIPTION_SUFFIX';
      questionItemId: string;
      suffix: string;
    }
  | {
      type: 'CHANGE_DESCRIPTION_PLACEHOLDER';
      questionItemId: string;
      placeholder: string;
    }
  | {
      type: 'CHANGE_HAS_CHOICE_NOTES';
      questionItemId: string;
      hasNotes: boolean;
    }
  | {
      type: 'CHANGE_CHOICE_NOTES_PREFIX';
      questionItemId: string;
      prefix: string;
    }
  | {
      type: 'CHANGE_CHOICE_NOTES_SUFFIX';
      questionItemId: string;
      suffix: string;
    }
  | {
      type: 'CHANGE_CHOICE_NOTES_PLACEHOLDER';
      questionItemId: string;
      placeholder: string;
    }
  | {
      type: 'CHANGE_CHOICE_SORT_NUMBER';
      questionItemId: string;
      sortNumber: number;
    }
  | { type: 'CHANGE_TAGS'; questionItemId: string; tags: string[] }
  | { type: 'GET_INTERVIEWSHEET'; data: QuestionItem[] };
