import {
  PrerequisiteAction,
  ADD_PREREQUISITE_ITEM,
  REMOVE_PREREQUISITE_ITEM,
  REMOVE_PREREQUISITE_ITEMS,
  CHANGE_PREREQUISITE_QUESTION,
  GET_INTERVIEWSHEET,
} from '../actions/prerequisiteAction';
import { Prerequisite } from '../types/prerequisite';
import { newId } from '../domains/newId';

export const defaultPrerequisite: Prerequisite = {
  prerequisiteId: '',
  conditionId: '',
  questionItemId: '',
  questionId: '',
};

export const initialState: Prerequisite[] = [];

export const prerequisiteReducer = (
  state = initialState,
  action: PrerequisiteAction,
) => {
  switch (action.type) {
    case ADD_PREREQUISITE_ITEM:
      return [
        ...state,
        {
          ...defaultPrerequisite,
          prerequisiteId: newId(),
          conditionId: action.conditionId,
          questionId: action.questionId,
          questionItemId: action.questionItemId,
        },
      ];
    case REMOVE_PREREQUISITE_ITEM:
      return state.filter((p) => p.prerequisiteId !== action.prerequisiteId);
    case CHANGE_PREREQUISITE_QUESTION:
      return state.filter(
        (p) =>
          p.conditionId !== action.conditionId ||
          (p.conditionId === action.conditionId &&
            p.questionId === action.questionId),
      );
    case GET_INTERVIEWSHEET:
      return action.data;
    case REMOVE_PREREQUISITE_ITEMS:
      return initialState;
    default:
      return state;
  }
};
