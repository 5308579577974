import React from 'react';
import {
  Link,
  Tooltip,
  IconButton,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdHelp } from 'react-icons/md';

export const HelpDeskButton: React.VFC = () => {
  return (
    <Link href={process.env.REACT_APP_HELPDESK_URL} isExternal>
      <Tooltip label="ヘルプ">
        <IconButton
          isRound={true}
          aria-label="Help"
          color="white"
          colorScheme="blue"
          size={useBreakpointValue({ base: 'sm', md: 'lg' })}
          icon={<MdHelp size="24" />}
        />
      </Tooltip>
    </Link>
  );
};
