import { useContext, useEffect } from 'react';
import {
  ConditionContext,
  SetConditionContext,
} from '../contexts/ConditionContext';
import { ADD_CONDITION } from '../actions/conditionAction';

export const useAddCondition = (questionId: string) => {
  const { state } = useContext(ConditionContext);
  const { dispatch } = useContext(SetConditionContext);
  const conditions = state.filter((c) => c.questionId === questionId);
  useEffect(() => {
    if (conditions.length === 0) {
      dispatch({ type: ADD_CONDITION, questionId });
    }
  }, [dispatch, conditions, questionId]);
  return conditions;
};
