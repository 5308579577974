import React, { useState, useRef } from 'react';
import {
  Text,
  Flex,
  Box,
  Image,
  IconButton,
  AspectRatio,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Area } from 'react-easy-crop/types';
import { MdAddPhotoAlternate, MdClose } from 'react-icons/md';
import { CropperModal } from './CropperModal';
import getCroppedImg from './getCroppedImg';
import {
  FILESIZE_LIMIT,
  TOAST_ERROR_OPTION,
} from '../../constant/systemMessage';
import { ASPECT_RATIO } from '../../constant/constant';

type Props = {
  uploadedUrl: string;
  isImgInS3: boolean;
  imgSource: string;
  setImgSource: (imgSource: string) => void;
  croppedImgSource: string;
  setCroppedImgSource: (imgSource: string) => void;
  deleteButtonClicked: boolean;
  setDeleteButtonClicked: (deleteButtonClicked: boolean) => void;
  setBase64CroppedImg: (imgSource: string) => void;
};

export const ImageEditor: React.FC<Props> = ({
  uploadedUrl,
  isImgInS3,
  imgSource,
  setImgSource,
  croppedImgSource,
  setCroppedImgSource,
  deleteButtonClicked,
  setDeleteButtonClicked,
  setBase64CroppedImg,
}) => {
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({
    width: 0,
    height: 0,
    x: 0,
    y: 0,
  });

  const clickRef = useRef<HTMLInputElement>(null);
  const toast = useToast();

  const onCropedComplete = (_croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const showCroppedImage = async () => {
    if (!croppedAreaPixels) return;
    const croppedImage = await getCroppedImg(imgSource, croppedAreaPixels);
    setCroppedImgSource(croppedImage.blobUrl);
    setBase64CroppedImg(croppedImage.base64Url);
  };

  const { isOpen, onOpen, onClose } = useDisclosure();
  const MAX_FILE_SIZE_BYTE = 15728640;

  return (
    <Box>
      <Flex mb="20px">
        <Box flex="1" textAlign="start" mr="3">
          <Text>イメージ画像</Text>
        </Box>
        <Box
          flex="5"
          position="relative"
          justifyContent="center"
          alignItems="center"
        >
          <AspectRatio ratio={ASPECT_RATIO}>
            <Box bgColor="#000000" position="relative">
              <Image
                src={
                  croppedImgSource
                    ? croppedImgSource
                    : isImgInS3 && !deleteButtonClicked
                    ? uploadedUrl
                    : process.env.PUBLIC_URL + '/header.png'
                }
                alt="header"
                objectFit="cover"
                mx="auto"
                opacity="0.7"
                position="absolute"
                w="100%"
                pointerEvents="none"
              />
              <Flex justifyContent="center" alignItems="center">
                <IconButton
                  aria-label="add"
                  icon={<MdAddPhotoAlternate />}
                  colorScheme="blue"
                  isRound={true}
                  fontSize="20px"
                  opacity="0.9"
                  onClick={() => {
                    clickRef.current?.click();
                  }}
                ></IconButton>
                {croppedImgSource || (isImgInS3 && !deleteButtonClicked) ? (
                  <IconButton
                    aria-label="delete"
                    icon={<MdClose />}
                    colorScheme="blue"
                    isRound={true}
                    fontSize="20px"
                    ml="20px"
                    opacity="0.9"
                    onClick={async () => {
                      setCroppedImgSource('');
                      setImgSource('');
                      setDeleteButtonClicked(true);
                    }}
                  ></IconButton>
                ) : (
                  <></>
                )}
              </Flex>
            </Box>
          </AspectRatio>
          <Flex justifyContent="end" mt="2">
            <Box>
              <Text textAlign="right">ファイル形式：png, jpg</Text>
              <Text textAlign="right">最大サイズ：15MB</Text>
            </Box>
          </Flex>
          <input
            type="file"
            accept=".png,.jpg"
            ref={clickRef}
            hidden
            onClick={(event: React.MouseEvent<HTMLInputElement>) => {
              // 同じファイルを選択した際にChangeEventが発火しない問題の処理
              event.currentTarget.value = '';
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              const files = event.currentTarget.files;
              if (!files || files?.length === 0) return;

              const file = files[0];
              if (file.size > MAX_FILE_SIZE_BYTE) {
                toast({
                  title: FILESIZE_LIMIT.title,
                  description: FILESIZE_LIMIT.description,
                  ...TOAST_ERROR_OPTION,
                });
                return;
              }

              const reader = new FileReader();
              reader.addEventListener('load', () => {
                setImgSource(reader.result ? reader.result.toString() : '');
                onOpen();
              });
              reader.readAsDataURL(file);
            }}
          />
          <CropperModal
            isOpen={isOpen}
            onClose={onClose}
            imgSource={imgSource}
            setImgSource={setImgSource}
            onCropedComplete={onCropedComplete}
            showCroppedImage={showCroppedImage}
            zoom={zoom}
            setZoom={setZoom}
          />
        </Box>
      </Flex>
    </Box>
  );
};
