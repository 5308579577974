export const PATHNAME = {
  INTERVIEW_SHEET: 'InterviewSheet',
  INTERVIEW_SHEETS: 'InterviewSheets',
  INTERVIEW_RESULT: 'InterviewResult',
  INTERVIEW_RESULTS: 'InterviewResults',
  CLINIC_NOTICE: 'ClinicNotice',
};

export const ROLES = {
  ADMINISTRATOR: 'Administrator',
  MANAGER: 'Web Interview Manager',
  USER: 'Web Interview',
};

export const PERMISSIONS = {
  INTERVIEW_SHEETS: {
    CREATE: 'create:interview-sheets',
    UPDATE: 'update:interview-sheets',
    DELETE: 'delete:interview-sheets',
  },
  INTERVIEW_RESULTS: {
    READ: 'read:interview-results',
    UPDATE: 'update:interview-results',
    DELETE: 'delete:interview-sheets',
  },
  TAGS: {
    CREATE: 'create:tags',
    READ: 'read:tags',
    UPDATE: 'update:tags',
    DELETE: 'delete:tags',
  },
  CLINIC_NOTICE: {
    UPDATE: 'update:clinic-notice',
    DELETE: 'delete:clinic-notice',
  },
};

export const ASPECT_RATIO = 4 / 1;
