import React, { useState } from 'react';
import {
  Box,
  Heading,
  Text,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Spacer,
  Flex,
  CircularProgress,
} from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { InterviewResultHistory } from '../../types/interviewResultHistory';
import { dateConvert } from '../../utils/dateConvert';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { GetInterviewResultHistory } from '../../types/interviewResultConfirmApiTypes';
import { toInterviewResultHistory } from '../../domains/toInterviewResultConfirm';
import { getInterviewResultHistories } from '../../api/index';

type Props = {};

export const UpdateHistory: React.VFC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const [isEmpty, setIsEmpty] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessed, setIsProcess] = useState(false);
  const [histories, setHistories] = useState({} as InterviewResultHistory[]);

  const handleGetHistories = async () => {
    if (isEmpty && !isProcessed) {
      try {
        setIsLoading(true);
        const accessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:interview-results',
        });
        const result = await getInterviewResultHistories(
          tenantId,
          id,
          userId,
          userName,
          accessToken,
        );

        if (result.ok) {
          const data: GetInterviewResultHistory = await result.json();
          if (data.length > 0) {
            setHistories(
              toInterviewResultHistory(data)
                .slice()
                .sort((a, b) => (a.createdAt < b.createdAt ? 1 : -1))
                .filter((history) => history.eventName !== 'change_pnumber'),
            );
            setIsEmpty(false);
          }
        } else {
          console.log(result.status);
        }
        setIsProcess(true);
      } catch (error) {
        console.error({ error });
      } finally {
        setIsLoading(false);
      }
    }
  };
  const updateHistoryConversion = (
    eventName: string,
    previousState: string,
    currentState: string,
    createdByName: string,
  ) => {
    if (eventName === 'change_identifieddate') {
      return `${createdByName} が 本人確認 を ${
        currentState ? '確定' : '解除'
      } しました`;
    } else if (eventName === 'delete_interviewresult') {
      return `${createdByName} が 問診票 を 無効にしました`;
    } else {
      const display = (state: string) => {
        if (eventName === 'change_sex') {
          return state === 'male' ? '男' : state === 'female' ? '女' : '不明';
        }
        return state;
      };
      const name = () => {
        switch (eventName) {
          case 'change_pid':
            return '患者番号';
          case 'change_lastname':
            return '氏名（姓）';
          case 'change_firstname':
            return '氏名（名）';
          case 'change_lastkana':
            return '氏名カナ（姓）';
          case 'change_firstkana':
            return '氏名カナ（名）';
          case 'change_sex':
            return '性別';
          case 'change_birthday':
            return '生年月日';
          case 'change_zipcode':
            return '郵便番号';
          case 'change_streetaddress':
            return '住所';
          case 'change_phonenumber':
            return '電話番号';
          case 'change_mailaddress':
            return 'メールアドレス';
          case 'change_reservationdate':
            return '予約日時';
          default:
            return '';
        }
      };
      return `${createdByName} が ${name()} を ${display(
        previousState,
      )} から ${display(currentState)} に変更しました`;
    }
  };

  return (
    <Box mx="4">
      <Paper px="5">
        <Accordion allowMultiple onChange={handleGetHistories}>
          <AccordionItem border="none">
            <AccordionButton pt="5" pb="4" px="0" _hover={{ bg: 'white' }}>
              <Heading size="md" color="gray.600" textAlign="left">
                変更履歴
              </Heading>
              <Spacer />
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel pt="0" pb="2" px="0">
              {isLoading ? (
                <Flex w="full" justifyContent="center" alignItems="center">
                  <CircularProgress isIndeterminate color="blue.300" />
                </Flex>
              ) : isEmpty ? (
                <Box w="full" justify="center" align="center" mt="3" mb="5">
                  <Text size="sm" color="gray.600" textAlign="left">
                    履歴がありません
                  </Text>
                </Box>
              ) : (
                <>
                  {histories.map(
                    (
                      {
                        eventName,
                        previousState,
                        currentState,
                        createdAt,
                        createdByName,
                      },
                      idx,
                    ) => (
                      <Box
                        key={idx}
                        w="full"
                        py="4"
                        textAlign="left"
                        borderTopWidth="1px"
                      >
                        <Text color="gray.600" fontWeight="bold" fontSize="sm">
                          {dateConvert(createdAt, 'seconds')}
                        </Text>
                        <Text color="gray.600" fontWeight="bold">
                          {updateHistoryConversion(
                            eventName,
                            previousState,
                            currentState,
                            createdByName,
                          )}
                        </Text>
                      </Box>
                    ),
                  )}
                </>
              )}
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </Paper>
    </Box>
  );
};
