import React from 'react';
import { IconButton, IconButtonProps } from '@chakra-ui/react';

export const ICON_BUTTON: React.VFC<IconButtonProps> = (props) => {
  return (
    <IconButton
      fontSize="36px"
      size="lg"
      color="white"
      bg="blue.200"
      _hover={{ bg: 'blue.300' }}
      _active={{
        bg: 'blue.400',
        borderColor: '#bec3c9',
      }}
      _focus={{
        boxShadow:
          '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
      }}
      {...props}
    />
  );
};
