import React, { useState } from 'react';
import {
  Heading,
  VStack,
  Button,
  HStack,
  Spacer,
  Flex,
  Input,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Text,
  useToast,
  CircularProgress,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { ModalDialog } from '../../components/ModalDialog';
import { Updated } from './Updated';
import { PersonalInformation } from '../../types/personalInformation';
import { Sex } from '../../types/webInterview';
import { dateConvert } from '../../utils/dateConvert';
import { useParams } from 'react-router-dom';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewResult, putInterviewResult } from '../../api/index';
import { toApiPersonalInformation } from '../../domains/toInterviewResultConfirm';
import { useForm } from 'react-hook-form';
import { mailAddressValidator } from '../../validations/mailAddress';
import { GetInterviewResult } from '../../types/interviewResultConfirmApiTypes';
import { streetAddressValidator } from '../../validations/streetAddress';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  personalInformation: PersonalInformation;
  save: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

type Inputs = {
  pid: string;
  lastName: string;
  firstName: string;
  lastKana: string;
  firstKana: string;
  sex: Sex;
  birthDay: string;
  zipCode: string;
  streetAddress: string;
  phoneNumber: string;
  mailAddress: string;
  reservationDate: string;
};

export const PersonalInformationEdit: React.VFC<Props> = ({
  isOpen,
  onClose,
  personalInformation,
  save,
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const toast = useToast();
  const {
    register,
    formState: { errors, dirtyFields },
    handleSubmit,
    reset,
  } = useForm<Inputs>({ mode: 'all' });
  const [sexRadio, setSexRadio] = useState<Sex>(personalInformation.sex);

  const sexArray = [
    { value: 'male', text: '男性' },
    { value: 'female', text: '女性' },
  ];
  const handleOnClose = () => {
    reset();
    onClose();
  };

  const onSubmit = (data: Inputs) => {
    const putData = async (): Promise<void> => {
      try {
        setLoading(true);
        const getAccessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:interview-results',
        });
        const status = await getInterviewResult(
          tenantId,
          id,
          userId,
          userName,
          getAccessToken,
        );
        if (status.ok) {
          const resultData: GetInterviewResult = await status.json();
          if (resultData.status === 'deleted') {
            toast({
              title: 'エラー',
              description: '問診結果が無効に変更されています',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            save.toggle();
          } else {
            const updateAccessToken = await getAccessTokenSilently({
              audience: 'https://www.webinterview.mic.jp/',
              scope: 'update:interview-results',
            });
            const result = await putInterviewResult(
              tenantId,
              id,
              userId,
              userName,
              updateAccessToken,
              toApiPersonalInformation({
                ...personalInformation,
                pid: data.pid,
                reservationDate: dateConvert(
                  data.reservationDate,
                  'seconds',
                  'T',
                  '+09:00',
                ),
                lastName: data.lastName,
                firstName: data.firstName,
                lastKana: data.lastKana,
                firstKana: data.firstKana,
                sex: data.sex,
                birthday: data.birthDay,
                zipCode: data.zipCode,
                streetAddress: data.streetAddress,
                phoneNumber: data.phoneNumber,
                mailAddress: data.mailAddress,
              } as PersonalInformation),
            );
            if (result.ok) {
              toast({
                title: '保存完了',
                description: '問診の変更内容を保存しました',
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
              save.toggle();
            } else {
              throw new Error(result.status.toString());
            }
          }
        } else {
          throw new Error(status.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.error({ error });
        onClose();
      } finally {
        setLoading(false);
      }
    };
    putData();
  };

  return (
    <ModalDialog
      header={
        loading ? (
          <></>
        ) : (
          <Heading
            size="lg"
            color="gray.600"
            textAlign="left"
            pb="4"
            borderBottomWidth="1px"
          >
            患者情報修正
          </Heading>
        )
      }
      body={
        loading ? (
          <Flex w="full" h="lg" justifyContent="center" alignItems="center">
            <CircularProgress isIndeterminate color="blue.300" />
          </Flex>
        ) : (
          <form id="personalInformation" onSubmit={handleSubmit(onSubmit)}>
            <VStack mt="2" spacing="3">
              <FormControl isInvalid={errors.pid ? true : false}>
                <Flex align="center">
                  <FormLabel
                    htmlFor="pid"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    患者番号
                  </FormLabel>
                  <Input
                    id="pId"
                    placeholder="000000"
                    defaultValue={personalInformation.pid}
                    {...register('pid', {
                      maxLength: {
                        value: 16,
                        message: '患者番号は16文字以内で入力してください',
                      },
                    })}
                    maxLength={16}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>{errors?.pid?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.lastName || errors.firstName ? true : false}
                isRequired={true}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="name"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                    mr="0"
                  >
                    氏名
                  </FormLabel>
                  <HStack flex="3" spacing="5">
                    <Input
                      id="lastName"
                      placeholder="姓"
                      defaultValue={personalInformation.lastName}
                      {...register('lastName', {
                        required: '姓は必須入力です',
                        maxLength: {
                          value: 16,
                          message: '姓は16文字以内で入力してください',
                        },
                      })}
                      maxLength={16}
                      isInvalid={errors.lastName ? true : false}
                    />
                    <Input
                      id="firstName"
                      placeholder="名"
                      defaultValue={personalInformation.firstName}
                      {...register('firstName', {
                        required: '名は必須入力です',
                        maxLength: {
                          value: 16,
                          message: '名は16文字以内で入力してください',
                        },
                      })}
                      maxLength={16}
                      isInvalid={errors.firstName ? true : false}
                    />
                  </HStack>
                </Flex>
                <FormErrorMessage>
                  {errors?.lastName?.message} {errors?.firstName?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired={true}
                isInvalid={errors.lastKana || errors.firstKana ? true : false}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="kana"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                    mr="0"
                  >
                    カナ
                  </FormLabel>
                  <HStack flex="3" spacing="5">
                    <Input
                      id="lastKana"
                      placeholder="セイ"
                      defaultValue={personalInformation.lastKana}
                      {...register('lastKana', {
                        required: 'セイは必須入力です',
                        maxLength: {
                          value: 16,
                          message: 'セイは16文字以内で入力してください',
                        },
                        pattern: {
                          value: /^[ァ-ーa-zA-Z0-9]+$/,
                          message:
                            'セイは全角カナまたは半角英数字で入力してください',
                        },
                      })}
                      maxLength={16}
                      isInvalid={errors.lastKana ? true : false}
                    />
                    <Input
                      id="firstKana"
                      placeholder="メイ"
                      defaultValue={personalInformation.firstKana}
                      {...register('firstKana', {
                        required: 'メイは必須入力です',
                        maxLength: {
                          value: 16,
                          message: 'メイは16文字以内で入力してください',
                        },
                        pattern: {
                          value: /^[ァ-ーa-zA-Z0-9]+$/,
                          message:
                            'メイは全角カナまたは半角英数字で入力してください',
                        },
                      })}
                      maxLength={16}
                      isInvalid={errors.firstKana ? true : false}
                    />
                  </HStack>
                </Flex>
                <FormErrorMessage>
                  {errors?.lastKana?.message} {errors?.firstKana?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isRequired={true}>
                <Flex align="center">
                  <FormLabel
                    htmlFor="sex"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    m="0"
                  >
                    性別
                  </FormLabel>
                  <RadioGroup
                    onChange={(value) => setSexRadio(value as Sex)}
                    defaultValue={personalInformation.sex}
                    value={sexRadio}
                    minH="30px"
                    flex="3"
                    mt="2"
                  >
                    <HStack spacing="10">
                      {sexArray.map(({ value, text }, idx) => (
                        <Radio
                          value={value}
                          colorScheme="webInterview"
                          isChecked={value === sexRadio}
                          {...register('sex')}
                          key={idx}
                        >
                          {text}
                        </Radio>
                      ))}
                    </HStack>
                  </RadioGroup>
                </Flex>
              </FormControl>

              <FormControl
                isInvalid={errors.birthDay ? true : false}
                isRequired={true}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="birthday"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    生年月日
                  </FormLabel>
                  <Input
                    id="birthday"
                    type="date"
                    max={'2100-01-01'}
                    defaultValue={dateConvert(
                      personalInformation.birthday,
                      'date',
                    )}
                    {...register('birthDay', {
                      required: '生年月日は必須入力です',
                      validate: {
                        positiveDate: (value) =>
                          new Date(value) > new Date('1910/01/01') &&
                          new Date(value) < new Date(Date.now()),
                      },
                    })}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>
                  {errors.birthDay?.type === 'positiveDate'
                    ? '有効な日付を入力してください'
                    : errors?.birthDay?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.zipCode ? true : false}
                isRequired={false}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="zipCode"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    郵便番号
                  </FormLabel>
                  <Input
                    id="zipCode"
                    placeholder="1638001"
                    defaultValue={personalInformation.zipCode}
                    {...register('zipCode', {
                      pattern: {
                        value: /^\d{7}$/,
                        message:
                          '郵便番号はハイフン抜きの7桁で入力してください',
                      },
                    })}
                    maxLength={7}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>{errors?.zipCode?.message}</FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.streetAddress ? true : false}
                isRequired={false}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="streetAddress"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    住所
                  </FormLabel>
                  <Input
                    id="streetAddress"
                    type="text"
                    defaultValue={personalInformation.streetAddress}
                    placeholder="東京都新宿区西新宿2-8-1"
                    {...register('streetAddress', {
                      ...streetAddressValidator,
                    })}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>
                  {errors?.streetAddress?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.phoneNumber ? true : false}
                isRequired={true}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="phoneNumber"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    電話番号
                  </FormLabel>
                  <Input
                    id="phoneNumber"
                    type="tel"
                    defaultValue={personalInformation.phoneNumber}
                    placeholder="0123456789"
                    {...register('phoneNumber', {
                      required: '電話番号は必須入力です',
                      maxLength: {
                        value: 15,
                        message: '電話番号は15文字以下で入力してください',
                      },
                      pattern: {
                        value: /^0\d{9,15}$/,
                        message: '電話番号は半角数字のみで入力してください',
                      },
                    })}
                    maxLength={15}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>
                  {errors?.phoneNumber?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.mailAddress ? true : false}
                isRequired={false}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="mailAddress"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    メールアドレス
                  </FormLabel>
                  <Input
                    id="mailAddress"
                    type="email"
                    defaultValue={personalInformation.mailAddress}
                    placeholder="web-interview@sample.com"
                    {...register('mailAddress', mailAddressValidator)}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>
                  {errors?.mailAddress?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isInvalid={errors.reservationDate ? true : false}
                isRequired={true}
              >
                <Flex align="center">
                  <FormLabel
                    htmlFor="reservationDate"
                    color="gray.600"
                    fontWeight="bold"
                    flex="1"
                    my="0"
                  >
                    予約日時
                  </FormLabel>
                  <Input
                    id="reservationDate"
                    type="datetime-local"
                    max={'2100-01-01 00:00:00'}
                    defaultValue={dateConvert(
                      personalInformation.reservationDate,
                      'minutes',
                      'T',
                    )}
                    {...register('reservationDate', {
                      validate: {
                        positiveDate: (value) =>
                          new Date(value) >= new Date('1910/01/01') &&
                          new Date(value) <= new Date('2100/12/31'),
                      },
                    })}
                    flex="3"
                  />
                </Flex>
                <FormErrorMessage>
                  {errors.reservationDate?.type === 'positiveDate'
                    ? '有効な日付を入力してください'
                    : errors?.reservationDate?.message}
                </FormErrorMessage>
              </FormControl>
              <Updated
                updatedAt={dateConvert(
                  personalInformation.updatedAt,
                  'seconds',
                )}
                upudatedBy={personalInformation.updatedByName}
              />
            </VStack>
          </form>
        )
      }
      footer={
        loading ? (
          <></>
        ) : (
          <HStack spacing="5" w="full" borderTopWidth="1px" pt="5">
            <Spacer />
            <Button
              colorScheme="webInterview"
              w="36"
              type="submit"
              form="personalInformation"
              isDisabled={
                Object.keys(dirtyFields).length === 0 &&
                dirtyFields.constructor === Object
              }
            >
              保存
            </Button>
            <Button w="36" onClick={handleOnClose}>
              キャンセル
            </Button>
          </HStack>
        )
      }
      isOpen={isOpen}
      onClose={handleOnClose}
      size="2xl"
    />
  );
};
