import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { MdErrorOutline } from 'react-icons/md';

type Props = {};

export const NoData: React.VFC<Props> = () => {
  return (
    <Flex minH="sm" justifyContent="center">
      <MdErrorOutline size="1.5rem" color="red" />
      <Text color="gray.600" fontWeight="bold">
        データが存在しません
      </Text>
    </Flex>
  );
};
