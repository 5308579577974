import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Link, Text } from '@chakra-ui/react';
import { EntryLayout } from '../components/Layout/EntryLayout';
import { Paper } from '../components/atoms/Paper';

export const ReEnterContent: React.VFC = () => {
  const { id: tenantId } = useParams<{ id: string }>();
  return (
    <Paper>
      <Box textAlign="center">
        <Box
          w="100px"
          h="100px"
          borderRadius="full"
          bg="white"
          borderWidth="10px"
          borderStyle="solid"
          borderColor="red.400"
          display="inline-block"
          textAlign="center"
          m="10"
        >
          <Text color="gray.600" fontSize="53" fontWeight="bold">
            !
          </Text>
        </Box>
        <Text color="gray.600" fontSize="2xl" mb="4">
          入力中の情報が失われました
        </Text>
        <Text color="gray.600" fontSize="sm" mb="10">
          <Link
            href={`/tenants/${tenantId}/interviewSelect`}
            color="blue.400"
            fontWeight="bold"
          >
            問診票の選択
          </Link>{' '}
          からやり直してください
        </Text>
      </Box>
    </Paper>
  );
};

export const ReEnter: React.VFC = () => {
  return (
    <EntryLayout hideHeader={true}>
      <Box pt="10">
        <ReEnterContent />
      </Box>
    </EntryLayout>
  );
};
