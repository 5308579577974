import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, HStack, Text, Spacer } from '@chakra-ui/react';
import { PopoverNavItem } from '../PopoverNavItem';
import { UserMenu } from './UserMenu';
import { HelpDeskButton } from '../HelpDeskButton';

type Props = {
  height: string;
};

export const Header: React.VFC<Props> = ({ height }) => {
  return (
    <Flex
      as="header"
      position="fixed"
      top={0}
      w="full"
      h={height}
      background="blue.500"
      py={4}
      px={{ base: '4', md: '8' }}
      zIndex="999"
    >
      <Flex w="full" maxW="7xl" mx="auto">
        <HStack align="center">
          <Text
            fontSize={{ base: 'xl', md: '3xl' }}
            fontWeight="bold"
            color="white"
            px={{ base: 8, sm: 2 }}
            size="lg"
            as={Link}
            to="/Dashboard"
          >
            Web問診票管理
          </Text>
        </HStack>
        <Spacer />
        <PopoverNavItem />
        <Spacer />
        <HStack align="center">
          <HelpDeskButton />
          <UserMenu />
        </HStack>
      </Flex>
    </Flex>
  );
};
