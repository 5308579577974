export const mailAddressValidator = {
  pattern: {
    value: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9.-]+.[a-zA-Z]{2,}/,
    message: 'メールアドレスの形式で入力してください',
  },
  maxLength: {
    value: 128,
    message: 'メールアドレスは128文字以下で入力してください',
  },
};
