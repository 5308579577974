import {
  ConditionAction,
  ADD_CONDITION,
  REMOVE_CONDITION,
  REMOVE_CONDITIONS,
  CHANGE_AGE_REQUIRED,
  CHANGE_AGE,
  CHANGE_AGE_OPERATOR,
  CHANGE_SEX_REQUIRED,
  CHANGE_SEX,
  CHANGE_QUESTION_REQUIRED,
  CHANGE_PREREQUISITE_QUESTION_ID,
  GET_INTERVIEWSHEET,
} from '../actions/conditionAction';
import { AgeOperator, Sex } from '../types/webInterview';
import { Condition } from '../types/condition';
import { newId } from '../domains/newId';

export const defaultCondition: Condition = {
  conditionId: '',
  ageRequired: false,
  age: 20,
  ageOperator: 'greater' as AgeOperator,
  sexRequired: false,
  sex: 'male' as Sex,
  questionRequired: false,
  prerequisiteQuestionId: '',
  questionId: '',
};

export const initialState: Condition[] = [];

export const conditionReducer = (
  state = initialState,
  action: ConditionAction,
) => {
  switch (action.type) {
    case ADD_CONDITION:
      const conditionId = newId();
      return [
        ...state,
        { ...defaultCondition, conditionId, questionId: action.questionId },
      ];
    case REMOVE_CONDITION:
      return state.filter(
        (condition) => condition.conditionId === action.conditionId,
      );
    case CHANGE_AGE_REQUIRED:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, ageRequired: !condition.ageRequired }
          : condition,
      );
    case CHANGE_AGE:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, age: action.age }
          : condition,
      );
    case CHANGE_AGE_OPERATOR:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, ageOperator: action.ageOperator }
          : condition,
      );
    case CHANGE_SEX_REQUIRED:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, sexRequired: !condition.sexRequired }
          : condition,
      );
    case CHANGE_SEX:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? {
              ...condition,
              sex: (condition.sex === 'male' ? 'female' : 'male') as Sex,
            }
          : condition,
      );
    case CHANGE_QUESTION_REQUIRED:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, questionRequired: !condition.questionRequired }
          : condition,
      );
    case CHANGE_PREREQUISITE_QUESTION_ID:
      return state.map((condition) =>
        condition.conditionId === action.conditionId
          ? { ...condition, prerequisiteQuestionId: action.questionId }
          : condition,
      );
    case GET_INTERVIEWSHEET:
      return action.data;
    case REMOVE_CONDITIONS:
      return initialState;
    default:
      return state;
  }
};
