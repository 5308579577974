import {
  GetInterviewResult,
  Answer as ApiAnswer,
  AnswerItem as ApiAnswerItem,
  InterviewSheetHistory as ApiInterviewSheetHistory,
  Question as ApiQuestion,
  QuestionItem as ApiQuestionItem,
  Tag as ApiTag,
  PutPersonalInformation,
  GetInterviewResultHistory,
} from '../types/interviewResultConfirmApiTypes';
import { Answer, AnswerItem } from '../types/answer';
import {
  InterviewSheetHistory,
  Question,
  QuestionItem,
  Tag,
} from '../types/interviewSheetHistory';
import { PersonalInformation } from '../types/personalInformation';
import { InterviewResultHistory } from '../types/interviewResultHistory';

export const toPersonalInformation = (data: GetInterviewResult) => {
  const result: PersonalInformation = {
    id: data.id,
    tenantId: data.tenant_id,
    pnumber: data.pnumber,
    pid: data.pid,
    status: data.status,
    identifiedDate: data.identified_date,
    identifiedName: data.identified_name,
    reservationDate: data.reservation_date,
    lastName: data.last_name,
    firstName: data.first_name,
    lastKana: data.last_kana,
    firstKana: data.first_kana,
    sex: data.sex,
    birthday: data.birthday,
    zipCode: data.zip_code,
    streetAddress: data.street_address,
    phoneNumber: data.phone_number,
    mailAddress: data.mail_address,
    createdAt: data.created_at,
    updatedAt: data.updated_at,
    createdBy: data.created_by,
    updatedBy: data.updated_by,
    createdByName: data.created_by_name,
    updatedByName: data.updated_by_name,
    version: data.version,
  };
  return result;
};

export const toAnswers = (data: ApiAnswer[]) => {
  return data.map<Answer>((a) => ({
    id: a.id,
    questionId: a.question_id,
    answerItems: toAnswerItems(a.answer_items),
  }));
};

export const toAnswerItems = (data: ApiAnswerItem[]) => {
  return data.map<AnswerItem>((ai) => ({
    id: ai.id,
    questionItemId: ai.question_item_id,
    description: ai.description,
    checked: ai.checked,
    choiceNotes: ai.choice_notes,
  }));
};

export const toInterviewSheetHistory = (data: ApiInterviewSheetHistory) => {
  const result: InterviewSheetHistory = {
    id: data.id,
    tenantId: data.tenant_id,
    name: data.name,
    description: data.description,
    releaseDate: data.release_date,
    conditionPid: data.condition_pid,
    conditionZipCode: data.condition_zip_code,
    conditionStreetAddress: data.condition_street_address,
    conditionMailAddress: data.condition_mail_address,
    createdAt: data.created_at,
    createdBy: data.created_by,
    createdByName: data.created_by_name,
    questions: toQuestions(data.questions),
  };
  return result;
};

export const toQuestions = (data: ApiQuestion[]) => {
  return data.map<Question>((q) => ({
    id: q.id,
    type: q.type,
    text: q.text,
    required: q.required,
    sortNumber: q.sort_number,
    questionItems: toQuestionItems(q.question_items),
  }));
};

export const toQuestionItems = (data: ApiQuestionItem[]) => {
  return data.map<QuestionItem>((qi) => ({
    id: qi.id,
    choiceText: qi.choice_text,
    hasChoiceNotes: qi.has_choice_notes,
    choiceNotesPrefix: qi.choice_notes_prefix,
    choiceNotesSuffix: qi.choice_notes_suffix,
    choiceNotesMaxLength: qi.choice_notes_max_length,
    choicePlaceholder: qi.choice_placeholder,
    descriptionMaxLines: qi.description_max_lines,
    descriptionMaxLength: qi.description_max_length,
    descriptionNotesPrefix: qi.description_notes_prefix,
    descriptionNotesSuffix: qi.description_notes_suffix,
    descriptionPlaceholder: qi.description_placeholder,
    sortNumber: qi.sort_number,
    tags: toTags(qi.tags),
  }));
};

export const toTags = (data: ApiTag[]) => {
  return data.map<Tag>((t) => ({
    id: t.id,
    name: t.name,
    enable: t.enable,
    isSystemOnly: t.is_system_only,
    createdAt: t.created_at,
    updatedAt: t.updated_at,
    createdBy: t.created_by,
    updatedBy: t.updated_by,
    createdByName: t.created_by_name,
    updatedByName: t.updated_by_name,
    version: t.version,
  }));
};

export const toApiPersonalInformation = (data: PersonalInformation) => {
  const result: PutPersonalInformation = {
    pnumber: data.pnumber,
    pid: data.pid,
    status: data.status,
    reservation_date: data.reservationDate,
    last_name: data.lastName,
    first_name: data.firstName,
    last_kana: data.lastKana,
    first_kana: data.firstKana,
    sex: data.sex,
    birthday: data.birthday,
    zip_code: data.zipCode,
    street_address: data.streetAddress,
    phone_number: data.phoneNumber,
    mail_address: data.mailAddress,
    version: data.version,
  };
  return result;
};

export const toInterviewResultHistory = (data: GetInterviewResultHistory) => {
  return data.map<InterviewResultHistory>((h) => ({
    id: h.id,
    interviewResultId: h.interview_result_id,
    tenantId: h.tenant_id,
    eventName: h.event_name,
    previousState: h.previous_state,
    currentState: h.current_state,
    createdAt: h.created_at,
    createdBy: h.created_by,
    createdByName: h.created_by_name,
  }));
};
