import { v4 as uuidv4 } from 'uuid';

type ShchemaName =
  | 'None'
  | 'InterviewSheet'
  | 'InterviewSheetHistory'
  | 'InterviewSheetResult'
  | 'Tag';

export const newId: (shemaName?: ShchemaName) => string = (
  shemaName = 'None',
) => {
  const newId = uuidv4();
  switch (shemaName) {
    case 'None':
      return newId;
    case 'InterviewSheet':
      return 'ins_' + newId;
    case 'InterviewSheetHistory':
      return 'inh_' + newId;
    case 'InterviewSheetResult':
      return 'inr_' + newId;
    case 'Tag':
      return 'tag_' + newId;
    default:
      const err: never = shemaName;
      return err;
  }
};
