import React, { useCallback, useContext, useRef, memo } from 'react';
import {
  Flex,
  Spacer,
  HStack,
  FormControl,
  FormLabel,
  Switch,
  IconButton,
  Tooltip,
  Button,
  useDisclosure,
} from '@chakra-ui/react';
import { MdDelete } from 'react-icons/md';
import {
  QuestionContext,
  SetQuestionContext,
} from '../../contexts/QuestionContext';
import {
  CHANGE_QUESTION_REQUIRED,
  REMOVE_QUESTION,
  CHANGE_QUESTION_SORT_NUMBER,
} from '../../actions/questionAction';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { defaultQuestion } from '../../reducers/questionReducer';
import { ConditionMenu } from './ConditionMenu';

type Props = {
  questionId: string;
};

export const QuestionFooter: React.VFC<Props> = memo(({ questionId }) => {
  const { state } = useContext(QuestionContext);
  const { dispatch } = useContext(SetQuestionContext);
  const { required } =
    state.find((q) => q.questionId === questionId) ?? defaultQuestion;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const handleDelete = useCallback(() => {
    dispatch({ type: REMOVE_QUESTION, questionId: questionId });
    const changeSortNumber = () => {
      state
        .filter((q) => q.questionId !== questionId)
        .slice()
        .sort((preview, next) => preview.sortNumber - next.sortNumber)
        .map((sort, idx) =>
          dispatch({
            type: CHANGE_QUESTION_SORT_NUMBER,
            questionId: sort.questionId,
            sortNumber: idx + 1,
          }),
        );
    };
    changeSortNumber();
    onClose();
  }, [dispatch, questionId, onClose, state]);
  return (
    <>
      <Flex mt="3">
        <Spacer />
        <HStack>
          <ConditionMenu questionId={questionId} />
          <Tooltip label="削除">
            <IconButton
              aria-label="question delete"
              icon={<MdDelete />}
              isRound={true}
              variant="outline"
              border="none"
              color="gray.600"
              mr="3"
              onClick={onOpen}
            />
          </Tooltip>
          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="question-required" mb="0" color="gray.600">
              必須
            </FormLabel>
            <Switch
              id="question-required"
              isChecked={required}
              onChange={() =>
                dispatch({
                  type: CHANGE_QUESTION_REQUIRED,
                  questionId: questionId,
                })
              }
              colorScheme="webInterview"
            />
          </FormControl>
        </HStack>
      </Flex>
      <ConfirmDialog
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
        headerText={'質問の削除'}
        bodyText={'本当に削除しますか？この操作は元に戻せません。'}
        confirmOk={
          <Button colorScheme="red" onClick={handleDelete} mr={3}>
            削除
          </Button>
        }
        confirmCancel={
          <Button ref={cancelRef} onClick={onClose}>
            キャンセル
          </Button>
        }
      />
    </>
  );
});
