import React, { useReducer } from 'react';
import {
  QuestionContext,
  SetQuestionContext,
} from '../contexts/QuestionContext';
import { questionReducer, initialState } from '../reducers/questionReducer';

type Props = {
  children: React.ReactNode;
};

export const QuestionProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(questionReducer, initialState);
  return (
    <QuestionContext.Provider value={{ state }}>
      <SetQuestionContext.Provider value={{ dispatch }}>
        {children}
      </SetQuestionContext.Provider>
    </QuestionContext.Provider>
  );
};
