import React, { useContext } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { InterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import { InterviewResultContext } from '../../contexts/InterviewResultContext';
import { defaultInterviewResult } from '../../reducers/interviewResultReducer';
import { ageCalculation } from '../../utils/ageCalculation';
import { dateConvert } from '../../utils/dateConvert';

type Props = {};

export const PersonalInformation: React.VFC<Props> = () => {
  const { state: sheetState } = useContext(InterviewSheetContext);
  const {
    conditionPid,
    conditionZipCode,
    conditionStreetAddress,
    conditionMailAddress,
  } = sheetState[0] ?? defaultInterviewSheet;
  const { state: resultState } = useContext(InterviewResultContext);
  const {
    isNew,
    pid,
    lastName,
    firstName,
    lastKana,
    firstKana,
    sex,
    birthday,
    zipCode,
    streetAddress,
    phoneNumber,
    mailAddress,
    reservationDate,
  } = resultState[0] ?? defaultInterviewResult;
  const age = ageCalculation(birthday);
  return (
    <>
      <Item
        title="患者番号"
        detail={isNew ? '初診' : pid}
        invisible={conditionPid === 'invisible'}
      />
      <Item title="氏名" detail={`${lastName} ${firstName}`} />
      <Item title="フリガナ" detail={`${lastKana} ${firstKana}`} />
      <Item title="性別" detail={sex === 'male' ? '男性' : '女性'} />
      <Item title="生年月日" detail={`${birthday}（${age}歳）`} />
      <Item
        title="郵便番号"
        detail={zipCode}
        invisible={conditionZipCode === 'invisible'}
      />
      <Item
        title="住所"
        detail={streetAddress}
        invisible={conditionStreetAddress === 'invisible'}
      />
      <Item title="電話番号" detail={phoneNumber} />
      <Item
        title="メールアドレス"
        detail={mailAddress}
        invisible={conditionMailAddress === 'invisible'}
      />
      <Item
        title="来院予定日時"
        detail={dateConvert(reservationDate, 'minutes')}
      />
    </>
  );
};

type ItemProps = {
  title: string;
  detail: string;
  invisible?: boolean;
};

const Item: React.VFC<ItemProps> = ({ title, detail, invisible = false }) => {
  if (invisible) {
    return <></>;
  }
  return (
    <Box width="full" align="start" mb="3">
      <Text fontWeight="bold">{title}</Text>
      <Text>{detail}</Text>
    </Box>
  );
};
