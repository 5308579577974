import React from 'react';
import {
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  InputGroupProps,
} from '@chakra-ui/react';

type Props = {
  prefix: string;
  suffix: string;
  disabled?: boolean;
  inputGroupProps?: InputGroupProps;
  value?: string;
  placeholder?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
};

export const InputWithAffix: React.VFC<Props> = ({
  prefix,
  suffix,
  disabled = false,
  inputGroupProps,
  value,
  placeholder,
  onChange,
  onBlur,
}) => {
  return (
    <InputGroup {...inputGroupProps}>
      {prefix.length ? <InputLeftAddon children={prefix} /> : <></>}
      <Input
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        color="gray.600"
      />
      {suffix.length ? <InputRightAddon children={suffix} /> : <></>}
    </InputGroup>
  );
};
