import { useCallback, useContext, useEffect, useState } from 'react';
import { TagContext, SetTagContext } from '../contexts/TagContext';
import { GET_TAGS } from '../actions/tagAction';
import { getTags } from '../api';
import { GetTags } from '../types/tagApiTypes';
import { toTags } from '../domains/toTags';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from './useGetMetadata';

export const useGetTags = () => {
  const { state } = useContext(TagContext);
  const { dispatch } = useContext(SetTagContext);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const { user, getAccessTokenSilently } = useAuth0();
  const getData = useCallback(async (): Promise<void> => {
    try {
      const userId = user?.sub ?? '';
      const userName = encodeURI(user?.name ?? '');
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'read:tags',
      });

      const result = await getTags(tenantId, userId, userName, accessToken);
      if (result.ok) {
        const data: GetTags = await result.json();
        dispatch({ type: GET_TAGS, data: toTags(data) });
        setLoading(false);
      }
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  }, [dispatch, tenantId, getAccessTokenSilently, user]);

  useEffect(() => {
    if (loading) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { tags: state, loading, error };
};
