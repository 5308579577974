import React, { useContext, memo } from 'react';
import { Box, Text, TextProps } from '@chakra-ui/react';
import { Row } from './Row';
import { ConditionContext } from '../../contexts/ConditionContext';
import { defaultCondition } from '../../reducers/conditionReducer';
import { ConditionAge } from './ConditionAge';
import { ConditionSex } from './ConditionSex';
import { ConditionQuestion } from './ConditionQuestion';
import { ConditionPrerequisiteItems } from './ConditionPrerequisiteItems';

type Props = {
  textProps: TextProps;
  questionId: string;
};

export const Condition: React.VFC<Props> = memo(({ textProps, questionId }) => {
  const { state } = useContext(ConditionContext);
  const conditions = state.filter((c) => c.questionId === questionId);
  const {
    ageRequired,
    sexRequired,
    questionRequired,
    conditionId,
    prerequisiteQuestionId,
  } = conditions.length ? conditions[0] : defaultCondition;
  return (
    <>
      {ageRequired || sexRequired || questionRequired ? (
        <>
          {' '}
          <Box mb="3">
            <Row mainTitle={<Text {...textProps}>条件</Text>} />
          </Box>
          {ageRequired ? (
            <Box mb="3">
              <ConditionAge conditionId={conditionId} />
            </Box>
          ) : (
            <></>
          )}
          {sexRequired ? (
            <Box mb="3">
              <ConditionSex conditionId={conditionId} />
            </Box>
          ) : (
            <></>
          )}
          {questionRequired ? (
            <>
              <Box mb="3">
                <ConditionQuestion
                  questionId={questionId}
                  conditionId={conditionId}
                  prerequisiteQuestionId={prerequisiteQuestionId}
                />
              </Box>
              <Box mb="3">
                <ConditionPrerequisiteItems
                  conditionId={conditionId}
                  prerequisiteQuestionId={prerequisiteQuestionId}
                />
              </Box>
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </>
  );
});
