import React, { memo } from 'react';
import { Box, BoxProps, IconProps, useDisclosure } from '@chakra-ui/react';
import { AnswerType } from '../../types/webInterview';
import { AnswerTypeIcon } from './AnswerTypeIcon';
import { Row } from './Row';
import { TagLabel } from './TagLabel';
import { AffixModalDialog } from './AffixModalDialog';
import { QuestionItemInputWithAffix } from './QuestionItemInputWithAffix';
import { ChoiceNote } from './ChoiceNote';
import { QuestionItemMenu } from './QuestionItemMenu';
import { TagSettingModalDialog } from './TagSettingModalDialog';

type Props = {
  answerType: AnswerType;
  questionItemId: string;
  boxProps: BoxProps;
  iconProps: IconProps;
};

export const QuestionItem: React.VFC<Props> = memo(
  ({ answerType, questionItemId, boxProps, iconProps }) => {
    const isDescription = answerType === 'textbox' || answerType === 'textline';
    const {
      isOpen: isOpenAffixModal,
      onOpen: onOpenAffixModal,
      onClose: onCloseAffixModal,
    } = useDisclosure();
    const {
      isOpen: isOpenTagModal,
      onOpen: onOpenTagModal,
      onClose: onCloseTagModal,
    } = useDisclosure();
    return (
      <>
        <Box py="2">
          <Row
            subTitle={
              <AnswerTypeIcon
                boxProps={boxProps}
                iconProps={iconProps}
                answerType={answerType}
              />
            }
            contents={
              <QuestionItemInputWithAffix
                questionItemId={questionItemId}
                answerType={answerType}
              />
            }
            controlButton={
              <QuestionItemMenu
                questionItemId={questionItemId}
                isDescription={isDescription}
                onOpenAffixModal={onOpenAffixModal}
                onOpenTagSettingModal={onOpenTagModal}
              />
            }
          />
          <AffixModalDialog
            isOpen={isOpenAffixModal}
            onClose={onCloseAffixModal}
            questionItemId={questionItemId}
            isDescription={isDescription}
          />
          <TagSettingModalDialog
            isOpen={isOpenTagModal}
            onClose={onCloseTagModal}
            questionItemId={questionItemId}
          />
        </Box>
        {!isDescription ? (
          <ChoiceNote questionItemId={questionItemId} />
        ) : (
          <></>
        )}
        <Box>
          <Row contents={<TagLabel questionItemId={questionItemId} />} />
        </Box>
      </>
    );
  },
);
