import React, { useContext } from 'react';
import { Box, Text, TextProps } from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import { AnswerTypeIcon } from './AnswerTypeIcon';
import { QuestionContext } from '../../contexts/QuestionContext';
import { defaultQuestion } from '../../reducers/questionReducer';

type Props = {
  questionId: string;
  isDragging: boolean;
  textProps: TextProps;
};

export const ClosedQuestion: React.VFC<Props> = ({
  questionId,
  isDragging,
  textProps,
}) => {
  const { state } = useContext(QuestionContext);
  const { text, answerType, sortNumber } =
    state.find((x) => x.questionId === questionId) ?? defaultQuestion;
  return (
    <Paper px="4" py="2" bg={isDragging ? '#EBF8FF' : 'white'}>
      <Box>
        <Row
          mainTitle={<Text {...textProps} mt="0">{`質問 ${sortNumber}`}</Text>}
          subTitle={
            <AnswerTypeIcon
              boxProps={{}}
              iconProps={{}}
              answerType={answerType}
            />
          }
          contents={
            <Text {...textProps} mt="0">
              {text}
            </Text>
          }
        />
      </Box>
    </Paper>
  );
};
