import {
  GetInterviewSheets,
  GetInterviewSheet,
} from '../types/interviewSheetApiTypes';
import { InterviewSheet } from '../types/interviewSheet';

const toDateString = (iSODateString: string) => {
  return iSODateString.substring(0, 16);
};

export const toInterviewSheets = (data: GetInterviewSheets) =>
  data.map<InterviewSheet>((s) => ({
    interviewSheetId: s.id,
    name: s.name,
    description: s.description,
    releaseDate: toDateString(s.release_date),
    conditionPid: s.condition_pid,
    conditionZipCode: s.condition_zip_code,
    conditionStreetAddress: s.condition_street_address,
    conditionMailAddress: s.condition_mail_address,
    createdAt: toDateString(s.created_at),
    updatedAt: toDateString(s.updated_at),
    createdBy: s.created_by,
    updatedBy: s.updated_by,
    displayPriority: s.display_priority,
    version: 1,
    isNew: false,
  }));

export const toInterviewSheet = (data: GetInterviewSheet) => {
  return {
    interviewSheetId: data.id,
    name: data.name,
    description: data.description,
    releaseDate: toDateString(data.release_date),
    conditionPid: data.condition_pid,
    conditionZipCode: data.condition_zip_code,
    conditionStreetAddress: data.condition_street_address,
    conditionMailAddress: data.condition_mail_address,
    createdAt: toDateString(data.created_at),
    updatedAt: toDateString(data.updated_at),
    createdBy: data.created_by,
    updatedBy: data.updated_by,
    displayPriority: data.display_priority,
    version: data.version,
    isNew: false,
  };
};
