import React, { useReducer } from 'react';
import { TenantContext, SetTenantContext } from '../contexts/TenantContext';
import { tenantReducer, initialState } from '../reducers/tenantReducer';

type Props = {
  children: React.ReactNode;
};

export const TenantProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(tenantReducer, initialState);
  return (
    <TenantContext.Provider value={{ state }}>
      <SetTenantContext.Provider value={{ dispatch }}>
        {children}
      </SetTenantContext.Provider>
    </TenantContext.Provider>
  );
};
