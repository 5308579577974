import React from 'react';
import { Grid, GridItem } from '@chakra-ui/react';

type Props = {
  mainTitle?: React.ReactElement;
  subTitle?: React.ReactElement;
  contents?: React.ReactElement;
  controlButton?: React.ReactElement;
};

export const Row: React.VFC<Props> = ({
  mainTitle = <></>,
  subTitle = <></>,
  contents = <></>,
  controlButton = <></>,
}) => {
  return (
    <Grid templateColumns="repeat(36, 1fr)" gap={0}>
      <GridItem colSpan={5} w="100%">
        {mainTitle}
      </GridItem>
      <GridItem colSpan={2} w="100%">
        {subTitle}
      </GridItem>
      <GridItem colSpan={26} w="100%">
        {contents}
      </GridItem>
      <GridItem colSpan={3} w="100%">
        {controlButton}
      </GridItem>
    </Grid>
  );
};
