import React, { useState, useContext } from 'react';
import {
  Button,
  Checkbox,
  Flex,
  Grid,
  GridItem,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Radio,
  RadioGroup,
  Select,
  Text,
} from '@chakra-ui/react';
import { useForm } from 'react-hook-form';
import {
  InterviewResultContext,
  SetInterviewResultContext,
} from '../../contexts/InterviewResultContext';
import { CHANGE_GUEST_INFO } from '../../actions/interviewResultAction';
import { dateConvert } from '../../utils/dateConvert';
import { ItemType } from '../../types/webInterview';
import { mailAddressValidator } from '../../validations/mailAddress';
import { streetAddressValidator } from '../../validations/streetAddress';
import { range } from '../../utils/range';

type Sex = 'male' | 'female';

type Inputs = {
  isNew: boolean;
  pId: string;
  nameSei: string;
  nameMei: string;
  kanaSei: string;
  kanaMei: string;
  sex: Sex;
  birthDay: string;
  birthDayYear: string;
  birthDayMonth: string;
  birthDayDate: string;
  zipCode: string;
  streetAddress: string;
  phoneNumber: string;
  email: string;
  reservationDate: string;
};

type Props = {
  interviewResultId: string;
  conditionPid: ItemType;
  conditionZipCode: ItemType;
  conditionStreetAddress: ItemType;
  conditionMailAddress: ItemType;
  handleNext: () => void;
  handlePrevious: () => void;
  span?: number | undefined;
  m?: string | undefined;
};

export const InterviewPersonalInformationForm: React.VFC<Props> = ({
  interviewResultId,
  conditionPid,
  conditionZipCode,
  conditionStreetAddress,
  conditionMailAddress,
  handleNext,
  handlePrevious,
  span = 2,
  m = 0,
}) => {
  const mb = '2';
  const isVisible = (condition: ItemType) => condition !== 'invisible';
  const isRequired = (condition: ItemType) => condition === 'required';
  const { state } = useContext(InterviewResultContext);
  const data = state[0];
  const {
    register,
    watch,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({
    mode: 'all',
    defaultValues: {
      isNew: data.isNew,
      pId: data.pid,
      nameMei: data.firstName,
      nameSei: data.lastName,
      kanaMei: data.firstKana,
      kanaSei: data.lastKana,
      sex: data.sex as Sex,
      birthDayYear: data.birthday.slice(0, 4),
      birthDayMonth: Number(data.birthday.slice(5, 7)).toString(),
      birthDayDate: Number(data.birthday.slice(8, 10)).toString(),
      zipCode: data.zipCode,
      streetAddress: data.streetAddress,
      phoneNumber: data.phoneNumber,
      email: data.mailAddress,
      reservationDate: dateConvert(data.reservationDate, 'minutes', 'T'),
    },
  });

  const isNew = watch('isNew');
  const currentYear = watch('birthDayYear');
  const currentMonth = watch('birthDayMonth');

  const [sexRadio, setSexRadio] = useState<Sex>(data.sex as Sex);

  const sexArray = [
    { value: 'male', text: '男性' },
    { value: 'female', text: '女性' },
  ];

  const yearArray = range(1910, new Date().getFullYear()).reverse();
  const monthAarray = range(1, 12);

  const RequiredText = () => (
    <Text color="red.400" fontWeight="bold">
      *
    </Text>
  );
  const { dispatch } = useContext(SetInterviewResultContext);
  const onSubmit = (data: Inputs) => {
    dispatch({
      type: CHANGE_GUEST_INFO,
      interviewResultId: interviewResultId,
      isNew: data.isNew,
      pId: data.pId,
      firstName: data.nameMei,
      lastName: data.nameSei,
      firstKana: data.kanaMei,
      lastKana: data.kanaSei,
      sex: data.sex,
      birthDay: `${data.birthDayYear}-${data.birthDayMonth.padStart(
        2,
        '0',
      )}-${data.birthDayDate.padStart(2, '0')}`,
      zipCode: data.zipCode,
      streetAddress: data.streetAddress,
      phoneNumber: data.phoneNumber,
      mailAddress: data.email,
      reservationDate: dateConvert(
        data.reservationDate,
        'seconds',
        'T',
        '+09:00',
      ),
    });

    handleNext();
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Grid templateColumns="repeat(2, 1fr)">
        {isVisible(conditionPid) && (
          <GridItem colSpan={2} mb="4">
            <Flex align="start" minH="40px">
              <Checkbox colorScheme="webInterview" {...register('isNew')}>
                <Text color="gray.600" fontWeight="bold">
                  初めての方はチェックしてください
                </Text>
              </Checkbox>
            </Flex>
            {!isNew && (
              <FormControl isInvalid={errors.pId ? true : false}>
                <Flex>
                  <FormLabel htmlFor="pId" color="gray.600" fontWeight="bold">
                    患者番号
                  </FormLabel>
                  {isRequired(conditionPid) && <RequiredText />}
                </Flex>
                <Input
                  id="pId"
                  placeholder="000000"
                  {...register('pId', {
                    required: isRequired(conditionPid)
                      ? '患者番号は必須入力です'
                      : '',
                    maxLength: {
                      value: 16,
                      message: '患者番号は16文字以内で入力してください',
                    },
                  })}
                  maxLength={16}
                />
                <FormErrorMessage>{errors?.pId?.message}</FormErrorMessage>
              </FormControl>
            )}
          </GridItem>
        )}
        <GridItem colSpan={span} mr={m} mb={mb}>
          <FormControl isInvalid={errors.nameSei ? true : false}>
            <Flex>
              <FormLabel htmlFor="nameSei" color="gray.600" fontWeight="bold">
                姓
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="nameSei"
              placeholder="姓"
              {...register('nameSei', {
                required: '姓は必須入力です',
                maxLength: {
                  value: 16,
                  message: '姓は16文字以内で入力してください',
                },
              })}
              maxLength={16}
            />
            <FormErrorMessage>{errors?.nameSei?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={span} ml={m} mb={mb}>
          <FormControl isInvalid={errors.nameMei ? true : false}>
            <Flex>
              <FormLabel htmlFor="nameMei" color="gray.600" fontWeight="bold">
                名
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="nameMei"
              placeholder="名"
              {...register('nameMei', {
                required: '名は必須入力です',
                maxLength: {
                  value: 16,
                  message: '名は16文字以内で入力してください',
                },
              })}
              maxLength={16}
            />
            <FormErrorMessage>{errors?.nameMei?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={span} mr={m} mb={mb}>
          <FormControl isInvalid={errors.kanaSei ? true : false}>
            <Flex>
              <FormLabel htmlFor="kanaSei" color="gray.600" fontWeight="bold">
                セイ
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="kanaSei"
              placeholder="セイ"
              {...register('kanaSei', {
                required: 'セイは必須入力です',
                maxLength: {
                  value: 16,
                  message: 'セイは16文字以内で入力してください',
                },
                pattern: {
                  value: /^[ァ-ーa-zA-Z0-9]+$/,
                  message: 'セイは全角カナまたは半角英数字で入力してください',
                },
              })}
              maxLength={16}
            />
            <FormErrorMessage>{errors?.kanaSei?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={span} ml={m} mb={mb}>
          <FormControl isInvalid={errors.kanaMei ? true : false}>
            <Flex>
              <FormLabel htmlFor="kanaMei" color="gray.600" fontWeight="bold">
                メイ
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="kanaMei"
              placeholder="メイ"
              {...register('kanaMei', {
                required: 'メイは必須入力です',
                maxLength: {
                  value: 16,
                  message: 'メイは16文字以内で入力してください',
                },
                pattern: {
                  value: /^[ァ-ーa-zA-Z0-9]+$/,
                  message: 'メイは全角カナまたは半角英数字で入力してください',
                },
              })}
              maxLength={16}
            />
            <FormErrorMessage>{errors?.kanaMei?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} mb={mb}>
          <FormControl>
            <Flex>
              <FormLabel htmlFor="sex" color="gray.600" fontWeight="bold">
                性別
              </FormLabel>
              <RequiredText />
            </Flex>
            <RadioGroup
              minH="30px"
              value={sexRadio}
              onChange={(value) => setSexRadio(value as Sex)}
            >
              <HStack spacing="10">
                {sexArray.map(({ value, text }, idx) => (
                  <Radio
                    colorScheme="webInterview"
                    value={value}
                    isChecked={value === sexRadio}
                    {...register('sex')}
                    key={idx}
                  >
                    {text}
                  </Radio>
                ))}
              </HStack>
            </RadioGroup>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2} mb={mb}>
          <FormControl isInvalid={errors.birthDayDate ? true : false}>
            <Flex>
              <FormLabel
                htmlFor="birthDayDate"
                color="gray.600"
                fontWeight="bold"
              >
                生年月日
              </FormLabel>
              <RequiredText />
            </Flex>
            <Flex>
              <Select mr={m} {...register('birthDayYear', {})}>
                {yearArray.map((year, idx) => (
                  <option key={idx} value={year}>
                    {year}年
                  </option>
                ))}
              </Select>
              <Select mx={m} {...register('birthDayMonth', {})}>
                {monthAarray.map((month, idx) => (
                  <option key={idx} value={month}>
                    {month}月
                  </option>
                ))}
              </Select>
              <Select
                isDisabled={currentYear === '' || currentMonth === ''}
                placeholder=" "
                ml={m}
                {...register('birthDayDate', {
                  validate: {
                    positiveDate: (day) => {
                      const currentDate = new Date(
                        `${currentYear}/${currentMonth}/${day}`,
                      );
                      if (currentDate.getDate() !== Number(day)) {
                        return false;
                      }
                      return (
                        currentDate > new Date('1910/01/01') &&
                        currentDate < new Date(Date.now())
                      );
                    },
                  },
                })}
              >
                {range(
                  1,
                  new Date(
                    Number(currentYear),
                    Number(currentMonth),
                    0,
                  ).getDate(),
                ).map((day) => (
                  <option key={day} value={day}>
                    {day}日
                  </option>
                ))}
              </Select>
            </Flex>
            <FormErrorMessage>
              {errors.birthDayDate?.type === 'positiveDate'
                ? '有効な日付を入力してください'
                : errors?.birthDayDate?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        {isVisible(conditionZipCode) && (
          <GridItem colSpan={2} mb={mb}>
            <FormControl isInvalid={errors.zipCode ? true : false}>
              <Flex>
                <FormLabel htmlFor="zipCode" color="gray.600" fontWeight="bold">
                  郵便番号
                </FormLabel>
                {isRequired(conditionZipCode) && <RequiredText />}
              </Flex>
              <Input
                id="zipCode"
                type="text"
                placeholder="1638001"
                {...register('zipCode', {
                  required: isRequired(conditionZipCode)
                    ? '郵便番号は必須入力です'
                    : '',
                  pattern: {
                    value: /^\d{7}$/,
                    message: '郵便番号はハイフン抜きの7桁で入力してください',
                  },
                })}
                maxLength={7}
              />
              <FormErrorMessage>{errors?.zipCode?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
        {isVisible(conditionStreetAddress) && (
          <GridItem colSpan={2} mb={mb}>
            <FormControl isInvalid={errors.streetAddress ? true : false}>
              <Flex>
                <FormLabel
                  htmlFor="streetAddress"
                  color="gray.600"
                  fontWeight="bold"
                >
                  住所
                </FormLabel>
                {isRequired(conditionStreetAddress) && <RequiredText />}
              </Flex>
              <Input
                id="streetAddress"
                placeholder="東京都新宿区西新宿2-8-1"
                {...register('streetAddress', {
                  ...streetAddressValidator,
                  required: isRequired(conditionStreetAddress)
                    ? '住所は必須入力です'
                    : '',
                })}
              />
              <FormErrorMessage>
                {errors?.streetAddress?.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
        <GridItem colSpan={2} mb={mb}>
          <FormControl isInvalid={errors.phoneNumber ? true : false}>
            <Flex>
              <FormLabel
                htmlFor="phoneNumber"
                color="gray.600"
                fontWeight="bold"
              >
                電話番号
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="phoneNumber"
              type="tel"
              placeholder="0123456789"
              {...register('phoneNumber', {
                required: '電話番号は必須入力です',
                maxLength: {
                  value: 15,
                  message: '電話番号は15文字以下で入力してください',
                },
                pattern: {
                  value: /^0\d{9,15}$/,
                  message: '電話番号は半角数字のみで入力してください',
                },
              })}
              maxLength={15}
            />
            <FormErrorMessage>{errors?.phoneNumber?.message}</FormErrorMessage>
          </FormControl>
        </GridItem>
        {isVisible(conditionMailAddress) && (
          <GridItem colSpan={2} mb={mb}>
            <FormControl isInvalid={errors.email ? true : false}>
              <Flex>
                <FormLabel htmlFor="email" color="gray.600" fontWeight="bold">
                  メールアドレス
                </FormLabel>
                {isRequired(conditionMailAddress) && <RequiredText />}
              </Flex>
              <Input
                id="email"
                type="email"
                placeholder="web-interview@sample.com"
                {...register('email', {
                  ...mailAddressValidator,
                  required: isRequired(conditionMailAddress)
                    ? 'メールアドレスは必須入力です'
                    : '',
                })}
              />
              <FormErrorMessage>{errors?.email?.message}</FormErrorMessage>
            </FormControl>
          </GridItem>
        )}
        <GridItem colSpan={2} mb={8}>
          <FormControl isInvalid={errors.reservationDate ? true : false}>
            <Flex>
              <FormLabel
                htmlFor="reservationDate"
                color="gray.600"
                fontWeight="bold"
              >
                来院予定日時
              </FormLabel>
              <RequiredText />
            </Flex>
            <Input
              id="reservationDate"
              type="datetime-local"
              max={'2100-01-01 00:00:00'}
              {...register('reservationDate', {
                required: '来院予定日は必須入力です',
                validate: {
                  positiveDate: (value) =>
                    new Date(value) > new Date('1910/01/01') &&
                    new Date(value) < new Date('2100/01/01'),
                },
              })}
            />
            <FormErrorMessage>
              {errors?.reservationDate?.type === 'positiveDate'
                ? '有効な日時を入力してください'
                : errors?.reservationDate?.message}
            </FormErrorMessage>
          </FormControl>
        </GridItem>
        <GridItem colSpan={2}>
          <Flex>
            <Button
              borderRadius="full"
              w="full"
              mr="4"
              onClick={handlePrevious}
            >
              戻る
            </Button>
            <Button
              borderRadius="full"
              w="full"
              colorScheme="webInterview"
              type="submit"
            >
              次に進む
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </form>
  );
};
