import React, { useReducer } from 'react';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../contexts/InterviewSheetContext';
import {
  interviewSheetReducer,
  initialState,
} from '../reducers/interviewSheetReducer';

type Props = {
  children: React.ReactNode;
};

export const InterviewSheetProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(interviewSheetReducer, initialState);
  return (
    <InterviewSheetContext.Provider value={{ state }}>
      <SetInterviewSheetContext.Provider value={{ dispatch }}>
        {children}
      </SetInterviewSheetContext.Provider>
    </InterviewSheetContext.Provider>
  );
};
