import { AnswerType } from '../types/webInterview';
import { Question } from '../types/question';
export const ADD_QUESTION = 'ADD_QUESTION';
export const REMOVE_QUESTION = 'REMOVE_QUESTION';
export const REMOVE_QUESTIONS = 'REMOVE_QUESTIONS';
export const CHANGE_QUESTION_TEXT = 'CHANGE_QUESTION_TEXT';
export const CHANGE_ANSWER_TYPE = 'CHANGE_ANSWER_TYPE';
export const CHANGE_QUESTION_REQUIRED = 'CHANGE_QUESTION_REQUIRED';
export const CHANGE_QUESTION_SORT_NUMBER = 'CHANGE_QUESTION_SORT_NUMBER';
export const CHANGE_HAS_CONDITION = 'CHANGE_HAS_CONDITION';
export const GET_INTERVIEWSHEET = 'GET_INTERVIEWSHEET';

export type QuestionAction =
  | {
      type: 'ADD_QUESTION';
      interviewSheetId: string;
    }
  | { type: 'REMOVE_QUESTION'; questionId: string }
  | { type: 'REMOVE_QUESTIONS' }
  | {
      type: 'CHANGE_QUESTION_TEXT';
      questionId: string;
      questionText: string;
    }
  | {
      type: 'CHANGE_ANSWER_TYPE';
      questionId: string;
      answerType: AnswerType;
    }
  | {
      type: 'CHANGE_QUESTION_REQUIRED';
      questionId: string;
    }
  | {
      type: 'CHANGE_QUESTION_SORT_NUMBER';
      questionId: string;
      sortNumber: number;
    }
  | {
      type: 'CHANGE_HAS_CONDITION';
      questionId: string;
    }
  | {
      type: 'GET_INTERVIEWSHEET';
      data: Question[];
    };
