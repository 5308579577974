import {
  CLEAR_INTERVIEW_RESULTS,
  ADD_INTERVIEW_RESULT,
  CHANGE_GUEST_INFO,
  CHANGE_ANSWER_ITEMS,
  InterviewResultAction,
} from '../actions/interviewResultAction';
import { InterviewResult } from '../types/InterviewResult';

export const defaultInterviewResult: InterviewResult = {
  interviewResultId: '',
  pnumber: 0,
  isNew: false,
  pid: '',
  identifiedDate: '',
  confirmedDate: '',
  reservationDate: '',
  lastName: '',
  firstName: '',
  lastKana: '',
  firstKana: '',
  sex: 'male',
  birthday: '',
  zipCode: '',
  streetAddress: '',
  phoneNumber: '',
  mailAddress: '',
  interviewSheetId: '',
  interviewSheetHistoryId: '',
  interviewSheetName: '',
  answers: [
    {
      answerId: '',
      questionId: '',
      answerItems: [
        {
          answerItemId: '',
          questionItemId: '',
          description: '',
          checked: false,
          choiceNotes: '',
        },
      ],
    },
  ],
};

export const initialState: InterviewResult[] = [];

export const interviewResultReducer = (
  state = initialState,
  action: InterviewResultAction,
) => {
  switch (action.type) {
    case CLEAR_INTERVIEW_RESULTS:
      return [];
    case ADD_INTERVIEW_RESULT:
      return state.length === 0
        ? [
            {
              ...defaultInterviewResult,
              interviewResultId: action.interviewResultId,
              interviewSheetId: action.interviewSheetId,
              interviewSheetHistoryId: action.interviewSheetHistoryId,
              interviewSheetName: action.interviewSheetName,
            },
          ]
        : state.map((result) => ({
            ...result,
            interviewResultId: action.interviewResultId,
            interviewSheetId: action.interviewSheetId,
            interviewSheetHistoryId: action.interviewSheetHistoryId,
            interviewSheetName: action.interviewSheetName,
            answers:
              result.interviewSheetId === action.interviewSheetId
                ? result.answers
                : defaultInterviewResult.answers,
          }));
    case CHANGE_GUEST_INFO:
      return state.map((result) =>
        result.interviewResultId === action.interviewResultId
          ? {
              ...result,
              isNew: action.isNew,
              pid: action.pId,
              firstName: action.firstName,
              lastName: action.lastName,
              firstKana: action.firstKana,
              lastKana: action.lastKana,
              sex: action.sex,
              birthday: action.birthDay,
              zipCode: action.zipCode,
              streetAddress: action.streetAddress,
              phoneNumber: action.phoneNumber,
              mailAddress: action.mailAddress,
              reservationDate: action.reservationDate,
            }
          : result,
      );
    case CHANGE_ANSWER_ITEMS:
      return state.map((result) =>
        result.interviewResultId === action.interviewResultId
          ? {
              ...result,
              answers: action.answers,
            }
          : result,
      );
    default:
      return state;
  }
};
