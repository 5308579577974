import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { IconButtonWithText } from '../../components/molecules/IconButtonWithText';
import {
  MdSave,
  MdUnfoldMore,
  MdUnfoldLess,
  MdAdd,
  MdSell,
  MdPreview,
} from 'react-icons/md';
import { SetQuestionContext } from '../../contexts/QuestionContext';
import { SetInterviewResultContext } from '../../contexts/InterviewResultContext';
import { ADD_QUESTION } from '../../actions/questionAction';
import {
  ADD_INTERVIEW_RESULT,
  CLEAR_INTERVIEW_RESULTS,
  CHANGE_GUEST_INFO,
} from '../../actions/interviewResultAction';
import { newId } from '../../domains/newId';

type Props = {
  questionOpened: boolean;
  setQuestionOpened: React.Dispatch<React.SetStateAction<boolean>>;
  tagEditOnOpen: () => void;
  previewOnOpen: () => void;
  saveInterviewSheet: () => void;
};

export const Menu: React.VFC<Props> = ({
  questionOpened,
  setQuestionOpened,
  tagEditOnOpen,
  previewOnOpen,
  saveInterviewSheet,
}) => {
  const { dispatch } = useContext(SetQuestionContext);
  const { dispatch: resultDispatch } = useContext(SetInterviewResultContext);
  const { id } = useParams<{ id: string }>();
  const fontSize = useBreakpointValue({ base: 'sm', sm: 'xs', md: 'md' });

  const buttonData = [
    {
      ariaLabel: 'save',
      icon: <MdSave />,
      text: '保存',
      onClick: () => {
        saveInterviewSheet();
      },
      show: true,
    },
    {
      ariaLabel: 'compress questions',
      icon: <MdUnfoldLess />,
      text: '質問を圧縮',
      onClick: () => setQuestionOpened(false),
      show: questionOpened,
    },
    {
      ariaLabel: 'expand questions',
      icon: <MdUnfoldMore />,
      text: '質問を展開',
      onClick: () => setQuestionOpened(true),
      show: !questionOpened,
    },
    {
      ariaLabel: 'add question',
      icon: <MdAdd />,
      text: '質問を追加',
      onClick: () => dispatch({ type: ADD_QUESTION, interviewSheetId: id }),
      show: true,
    },
    {
      ariaLabel: 'edit tags',
      icon: <MdSell />,
      text: 'タグを編集',
      onClick: tagEditOnOpen,
      show: true,
    },
    {
      ariaLabel: 'preview',
      icon: <MdPreview />,
      text: 'プレビュー',
      onClick: () => {
        const newResultId = newId('InterviewSheetResult');
        resultDispatch({
          type: CLEAR_INTERVIEW_RESULTS,
        });
        resultDispatch({
          type: ADD_INTERVIEW_RESULT,
          interviewResultId: newResultId,
          interviewSheetId: id,
          interviewSheetHistoryId: '',
          interviewSheetName: '',
        });
        resultDispatch({
          type: CHANGE_GUEST_INFO,
          interviewResultId: newResultId,
          isNew: false,
          pId: '000000',
          firstName: '一郎',
          lastName: '鈴木',
          firstKana: 'イチロウ',
          lastKana: 'スズキ',
          sex: 'male',
          birthDay: '1976-09-01',
          zipCode: '1638001',
          streetAddress: '東京都新宿区西新宿2-8-1',
          phoneNumber: '0123456789',
          mailAddress: 'web-interview@sample.com',
          reservationDate: new Date().toISOString(),
        });

        previewOnOpen();
      },
      show: true,
    },
  ];
  return (
    <>
      {buttonData
        .filter((b) => b.show)
        .map(({ ariaLabel, icon, text, onClick }, idx) => {
          return (
            <Box w="full" key={idx}>
              <IconButtonWithText
                iconButtonProps={{ 'aria-label': ariaLabel, icon }}
                textProps={{ fontSize }}
                text={text}
                onClick={onClick}
                key={idx}
              />
            </Box>
          );
        })}
    </>
  );
};
