import React, { createContext } from 'react';
import { QuestionAction } from '../actions/questionAction';
import { Question } from '../types/question';

export const QuestionContext = createContext(
  {} as {
    state: Question[];
  },
);

export const SetQuestionContext = createContext(
  {} as {
    dispatch: React.Dispatch<QuestionAction>;
  },
);
