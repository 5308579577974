import React from 'react';
import { Text } from '@chakra-ui/react';
import { Paper } from './atoms/Paper';
import { useTenant } from '../hooks/useTenant';

type Props = {
  tenantId: string;
};

export const ClinicInformation: React.VFC<Props> = ({ tenantId }) => {
  const { state: tenant } = useTenant(tenantId);

  if (!tenant.tenantId) {
    return <></>;
  }

  return (
    <Paper p="2">
      <Text
        align="left"
        fontSize="md"
        color="gray.600"
        fontWeight="bold"
        pb="1"
      >
        医院情報
      </Text>
      <hr />
      <Text
        pt="1"
        align="left"
        fontSize="lg"
        color="gray.600"
        fontWeight="bold"
      >
        {tenant.name}
      </Text>
      {['〒' + tenant.zipCode, tenant.streetAddress, tenant.phoneNumber].map(
        (info, idx) => (
          <Text align="left" fontSize="sm" color="gray.600" key={idx}>
            {info}
          </Text>
        ),
      )}
    </Paper>
  );
};
