import React, { useState, useContext, useCallback, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Text } from '@chakra-ui/react';
import { ModalDialog } from '../../components/ModalDialog';
import { InterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { InterviewResultContext } from '../../contexts/InterviewResultContext';
import { InterviewPersonalInformationForm } from '../InterviewPersonalInformation/InterviewPersonalInformationForm';
import { InterviewQuestionForm } from '../InterviewQuestion/InterviewQuestionForm';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import { QuestionContext } from '../../contexts/QuestionContext';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const PreviewModalDialog: React.VFC<Props> = ({ isOpen, onClose }) => {
  const { state: sheetState } = useContext(InterviewSheetContext);
  const { state: questionState } = useContext(QuestionContext);
  const { state: resultState } = useContext(InterviewResultContext);
  const [pageNumber, setPageNumber] = useState(1);
  const ref = React.createRef<HTMLDivElement>();
  const scrollTo = useCallback(() => {
    ref.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, [ref]);
  const { id } = useParams<{ id: string }>();
  const data =
    sheetState.find(({ interviewSheetId }) => interviewSheetId === id) ??
    defaultInterviewSheet;
  const questionCount = questionState.filter(
    (q) => q.interviewSheetId === id,
  ).length;

  useEffect(() => {
    scrollTo();
  }, [scrollTo, pageNumber]);

  const handleClose = () => {
    setPageNumber(1);
    onClose();
  };

  return (
    <ModalDialog
      header={<></>}
      body={
        <div ref={ref}>
          {pageNumber === 1 ? (
            <InterviewPersonalInformationForm
              interviewResultId={resultState[0]?.interviewResultId}
              conditionMailAddress={data.conditionMailAddress}
              conditionPid={data.conditionPid}
              conditionStreetAddress={data.conditionStreetAddress}
              conditionZipCode={data.conditionZipCode}
              handleNext={() => setPageNumber(Math.min(pageNumber + 1, 2))}
              handlePrevious={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            />
          ) : pageNumber === 2 && questionCount > 0 ? (
            <InterviewQuestionForm
              handleNext={() => setPageNumber(Math.min(pageNumber + 1, 2))}
              handlePrevious={() => setPageNumber(Math.max(pageNumber - 1, 1))}
            />
          ) : (
            <Text fontWeight="bold" color="red.400">
              質問が作成されていません。
            </Text>
          )}
        </div>
      }
      isOpen={isOpen}
      onClose={handleClose}
      size="sm"
    />
  );
};
