import React, { useReducer } from 'react';
import {
  InterviewResultContext,
  SetInterviewResultContext,
} from '../contexts/InterviewResultContext';
import {
  interviewResultReducer,
  initialState,
} from '../reducers/interviewResultReducer';

type Props = {
  children: React.ReactNode;
};

export const InterviewResultProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(interviewResultReducer, initialState);
  return (
    <InterviewResultContext.Provider value={{ state }}>
      <SetInterviewResultContext.Provider value={{ dispatch }}>
        {children}
      </SetInterviewResultContext.Provider>
    </InterviewResultContext.Provider>
  );
};
