export const FILESIZE_LIMIT = {
  title: '画像ファイルの容量が大きすぎます',
  description: '15MB以下の画像を選択してください。',
};

export const TOAST_SUCCESS_OPTION: {
  status: 'success';
  duration: number;
  isClosable: boolean;
} = {
  status: 'success',
  duration: 5000,
  isClosable: true,
};

export const TOAST_ERROR_OPTION: {
  status: 'error';
  duration: number;
  isClosable: boolean;
} = {
  status: 'error',
  duration: 8000,
  isClosable: true,
};
