import React, { useContext } from 'react';
import { Box } from '@chakra-ui/react';
import { QuestionContext } from '../../contexts/QuestionContext';
import { SetQuestionItemContext } from '../../contexts/QuestionItemContext';
import { defaultQuestion } from '../../reducers/questionReducer';
import { ADD_QUESTION_ITEM } from '../../actions/questionItemAction';
import { Row } from './Row';
import { BUTTON } from '../../components/atoms/Button';

type Props = {
  questionId: string;
};

export const AddChoice: React.VFC<Props> = ({ questionId }) => {
  const { state } = useContext(QuestionContext);
  const { dispatch } = useContext(SetQuestionItemContext);

  const { answerType } =
    state.find((x) => x.questionId === questionId) ?? defaultQuestion;
  const isChoice = answerType !== 'textbox' && answerType !== 'textline';

  return (
    <>
      {isChoice ? (
        <Box mt="1.5" mb="3">
          <Row
            contents={
              <Box align="right">
                <BUTTON
                  text="選択肢を追加"
                  onClick={() =>
                    dispatch({
                      type: ADD_QUESTION_ITEM,
                      questionId: questionId,
                    })
                  }
                />
              </Box>
            }
          />
        </Box>
      ) : (
        <></>
      )}
    </>
  );
};
