import React from 'react';
import { useTenant } from '../hooks/useTenant';
import { NotFound } from '../pages/NotFound';
import { Loading } from './Loading';

type Props = {
  tenantId: string;
  children: React.ReactNode;
};

export const ErrorCheck: React.VFC<Props> = ({ tenantId, children }) => {
  const { state: tenant, resultStatus } = useTenant(tenantId);
  if (!tenantId) {
    return <NotFound />;
  }
  if (!tenant.tenantId) {
    if (resultStatus === '') {
      return <Loading />;
    }
    return <NotFound />;
  }
  if (!tenant.subscriptions[0].enable) {
    return <NotFound />;
  }
  return <>{children}</>;
};
