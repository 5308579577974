import React, { useContext, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Text, TextProps, Input } from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import { CHANGE_DESCRIPTION } from '../../actions/interviewSheetAction';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';

export const Description: React.VFC<TextProps> = memo((props) => {
  const { state } = useContext(InterviewSheetContext);
  const { dispatch } = useContext(SetInterviewSheetContext);
  const urlParams = useParams<{ id: string }>();
  const { interviewSheetId, description } =
    state.find((s) => s.interviewSheetId === urlParams.id) ??
    defaultInterviewSheet;

  const [sheetDescription, setSheetDescription] = useState(description);
  const handleChangeDescription = (description: string) => {
    dispatch({
      type: CHANGE_DESCRIPTION,
      interviewSheetId,
      description,
    });
  };

  return (
    <Paper px="4" py="3">
      <Row
        mainTitle={<Text {...props}>{'説明'}</Text>}
        contents={
          <Input
            value={sheetDescription}
            onChange={(e) => setSheetDescription(e.target.value)}
            onBlur={() => handleChangeDescription(sheetDescription)}
            color={props.color}
          />
        }
      />
    </Paper>
  );
});
