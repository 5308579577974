import React from 'react';
import { Box, Heading } from '@chakra-ui/react';
import { Answer, Question } from '../../types/interviewResultConfirm';
import { InterviewResultQuestion } from './InterviewResultQuestion';
import { Paper } from '../../components/atoms/Paper';

type Props = {
  answers: Answer[];
  questions: Question[];
};

export const InterviewResultQuestions: React.VFC<Props> = ({
  answers,
  questions,
}) => {
  return (
    <Box mx="4">
      <Paper py="4" px="5">
        <Heading size="md" color="gray.600" textAlign="left" mt="1">
          質問と回答
        </Heading>
        <Box mt="4" py="3" borderTopWidth="1px">
          {questions ? (
            questions.map((q, idx) => (
              <InterviewResultQuestion
                key={idx}
                question={q}
                answers={answers}
              />
            ))
          ) : (
            <></>
          )}
        </Box>
      </Paper>
    </Box>
  );
};
