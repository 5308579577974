import { InterviewSheet } from '../types/interviewSheet';
import { ItemType } from '../types/webInterview';
export const GET_INTERVIEWSHEETS = 'GET_INTERVIEWSHEETS';
export const GET_INTERVIEWSHEET = 'GET_INTERVIEWSHEET';
export const ADD_INTERVIEW_SHEET = 'ADD_INTERVIEW_SHEET';
export const REMOVE_INTERVIEWSHEETS = 'REMOVE_INTERVIEWSHEETS';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_DESCRIPTION = 'CHANGE_DESCRIPTION';
export const CHANGE_RELEASE_DATE = 'CHANGE_RELEASE_DATE';
export const CHANGE_CONDITION_PID = 'CHANGE_CONDITION_PID';
export const CHANGE_CONDITION_ZIP_CODE = 'CHANGE_CONDITION_ZIP_CODE';
export const CHANGE_CONDITION_STREET_ADDRESS =
  'CHANGE_CONDITION_STREET_ADDRESS';
export const CHANGE_CONDITION_MAIL_ADDRESS = 'CHANGE_CONDITION_MAIL_ADDRESS';

export type InterviewSheetAction =
  | {
      type: 'GET_INTERVIEWSHEETS';
      data: InterviewSheet[];
    }
  | {
      type: 'GET_INTERVIEWSHEET';
      data: InterviewSheet;
    }
  | {
      type: 'REMOVE_INTERVIEWSHEETS';
    }
  | {
      type: 'ADD_INTERVIEW_SHEET';
      interviewSheetId: string;
    }
  | {
      type: 'CHANGE_NAME';
      interviewSheetId: string;
      name: string;
    }
  | {
      type: 'CHANGE_DESCRIPTION';
      interviewSheetId: string;
      description: string;
    }
  | {
      type: 'CHANGE_RELEASE_DATE';
      interviewSheetId: string;
      releaseDate: string;
    }
  | {
      type: 'CHANGE_CONDITION_PID';
      interviewSheetId: string;
      conditionPid: ItemType;
    }
  | {
      type: 'CHANGE_CONDITION_ZIP_CODE';
      interviewSheetId: string;
      conditionZipCode: ItemType;
    }
  | {
      type: 'CHANGE_CONDITION_STREET_ADDRESS';
      interviewSheetId: string;
      conditionStreetAddress: ItemType;
    }
  | {
      type: 'CHANGE_CONDITION_MAIL_ADDRESS';
      interviewSheetId: string;
      conditionMailAddress: ItemType;
    };
