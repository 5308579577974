import { PostTenant } from '../types/tenantApiTypes';

const BASE_URL = process.env.REACT_APP_API_ENDPOINT_MIC_BASE;

export const getTenant = async (tenantId: string) => {
  return await fetch(`${BASE_URL}/tenants/${tenantId}`, {
    headers: {
      'Content-Type': 'application/json',
    },
    mode: 'cors',
  });
};

export const postTenant = (
  userId: string,
  userName: string,
  accessToken: string,
  data: PostTenant,
) => {
  return fetch(`${BASE_URL}/tenants`, {
    method: 'POST',
    headers: {
      accept: 'application/json',
      authorization: `Bearer ${accessToken}`,
      'x-mic-user-id': userId,
      'x-mic-user-name': userName,
    },
    mode: 'cors',
    body: JSON.stringify(data),
  });
};
