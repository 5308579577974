import React from 'react';
import { FormControl, FormLabel, Text, Flex } from '@chakra-ui/react';

type Props = {
  updatedAt: string;
  upudatedBy: string;
};

export const Updated: React.VFC<Props> = ({ updatedAt, upudatedBy }) => {
  return (
    <FormControl h="10">
      <Flex align="center" h="full">
        <FormLabel
          htmlFor="updated"
          color="gray.600"
          fontWeight="bold"
          flex="1"
          my="0"
        >
          更新者
        </FormLabel>
        <Text color="gray.600" flex="3">
          {upudatedBy} {updatedAt}
        </Text>
      </Flex>
    </FormControl>
  );
};
