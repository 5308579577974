import React, { useState, useEffect } from 'react';
import { Box, CircularProgress, Flex, Text } from '@chakra-ui/react';
import { Paper } from './atoms/Paper';
import { getClinicNotice } from '../api/index';
import { ClinicNotice } from '../types/clinicNotice';

type Props = {
  tenantId: string;
};

export const Notice: React.VFC<Props> = ({ tenantId }) => {
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState<ClinicNotice>({
    tenantId: '',
    contents: [],
  });

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getClinicNotice(tenantId);
        if (result.ok) {
          const data: ClinicNotice = await result.json();
          if (data) {
            setNotice(data);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [tenantId]);

  if (loading) {
    return <CircularProgress isIndeterminate color="blue.300" />;
  }

  return (
    <Paper>
      <Flex align="center">
        <Text p="2" w="120px" color="gray.600" fontSize="xl" fontWeight="bold">
          お知らせ
        </Text>
        <Box w="full" p="2" m="2" borderLeft="1px" borderColor="gray.200">
          {notice.contents.map((content, idx) => (
            <Box key={idx}>
              {content?.split(/\n/).map((line, idx) => (
                <Text color="gray.600" align="start" key={idx}>
                  {line}
                </Text>
              ))}
              {idx !== notice.contents.length - 1 ? <hr /> : <></>}
            </Box>
          ))}
        </Box>
      </Flex>
    </Paper>
  );
};
