import React, { useContext } from 'react';
import { Flex, Tag } from '@chakra-ui/react';
import { QuestionItemContext } from '../../contexts/QuestionItemContext';
import { TagContext } from '../../contexts/TagContext';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  questionItemId: string;
};

export const TagLabel: React.VFC<Props> = ({ questionItemId }) => {
  const { state: questionItemState } = useContext(QuestionItemContext);
  const { state: tagState } = useContext(TagContext);
  const { tags: tagIds } =
    questionItemState.find((qi) => qi.questionItemId === questionItemId) ??
    defaultQuestionItem;
  const tags = tagIds.map((id) => tagState.find((t) => t.tagId === id));
  const systemTags = tags.filter((t) => t?.isSystemOnly);
  const userTags = tags.filter((t) => !t?.isSystemOnly && t?.enable);
  const deletedTags = tags.filter((t) => !t?.isSystemOnly && !t?.enable);

  return (
    <>
      {tags.length ? (
        <Flex align="start" mb="3">
          {systemTags.length ? (
            systemTags.map((t, idx) => (
              <Tag colorScheme="blue" key={idx} mr="1">
                {t?.name}
              </Tag>
            ))
          ) : (
            <></>
          )}
          {userTags.length ? (
            userTags.map((t, idx) => (
              <Tag colorScheme="gray" key={idx} mr="1">
                {t?.name}
              </Tag>
            ))
          ) : (
            <></>
          )}
          {deletedTags.length ? (
            deletedTags.map((t, idx) => (
              <Tag colorScheme="red" key={idx} mr="1">
                {t?.name}
              </Tag>
            ))
          ) : (
            <></>
          )}
        </Flex>
      ) : (
        <></>
      )}
    </>
  );
};
