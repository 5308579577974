import React from 'react';
import { Box, Flex, Text, Textarea, Spacer } from '@chakra-ui/react';

type Props = {
  notice: string;
  setNotice: (notice: string) => void;
};

export const NoticeEditor: React.FC<Props> = ({ notice, setNotice }) => {
  return (
    <>
      <Flex>
        <Box flex="1" textAlign="start" mr="3">
          <Text>お知らせ</Text>
        </Box>
        <Box flex="5">
          <Textarea
            minH="35vH"
            value={notice}
            onChange={(e) => setNotice(e.target.value)}
            isInvalid={notice.length > 1000}
          />
          <Flex justifyContent="end" my="2">
            {notice.length > 1000 ? (
              <>
                <Text color="red">1000文字以内で入力してください</Text>
                <Spacer />
              </>
            ) : (
              <></>
            )}
            <Text>{notice.length}/1000</Text>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};
