import React, { useContext, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Box,
  Checkbox,
  Flex,
  HStack,
  Text,
  Button,
  useBreakpointValue,
  useToast,
} from '@chakra-ui/react';
import { MdCheck, MdVerifiedUser } from 'react-icons/md';
import { EntryLayout } from '../../components/Layout/EntryLayout';
import { Paper } from '../../components/atoms/Paper';
import { Stepper } from '../../components/Stepper';
import { StepperMobile } from '../../components/StepperMobile';
import { Header } from './Header';
import { PrivacyPolicy } from './PrivacyPolicy';
import { ClinicInformation } from '../../components/ClinicInformation';
import { postInterviewResult } from '../../api/index';
import { PostInterviewResult } from '../../types/interviewResultApiTypes';
import { InterviewResultContext } from '../../contexts/InterviewResultContext';
import { toApiInterviewResult } from '../../domains/toInterviewResult';
import { PersonalInformation } from './PersonalInformation';
import { Questions } from './Questions';
import { QuestionContext } from '../../contexts/QuestionContext';
import { QuestionItemContext } from '../../contexts/QuestionItemContext';
import {
  Tag,
  QuestionItem,
  Answer,
  AnswerItem,
} from '../../types/interviewResultConfirm';
import { isInvalidQuestion } from '../../domains/isInvalidQuestion';
import { ConditionContext } from '../../contexts/ConditionContext';
import { PrerequisiteContext } from '../../contexts/PrerequisiteContext';
import { ageCalculation } from '../../utils/ageCalculation';
import { ReEnter } from '../ReEnter';
import { ErrorCheck } from '../../components/ErrorCheck';

type Props = {};

export const InterviewConfirm: React.VFC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const tenantId: string = (() => {
    try {
      return atob(id.replace(/\r?\n/g, ''));
    } catch (ignored) {
      console.log(ignored);
      return '';
    }
  })();
  const px = useBreakpointValue({ base: 2, lg: 0 });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();
  const [isAgree, setIsAgree] = useState(false);
  const processing = useRef(false);
  const toast = useToast();

  const { state } = useContext(InterviewResultContext);
  const { state: questionState } = useContext(QuestionContext);
  const { state: questionItemState } = useContext(QuestionItemContext);
  const { state: conditionState } = useContext(ConditionContext);
  const { state: prerequisiteState } = useContext(PrerequisiteContext);
  const answers = state?.[0]?.answers.map<Answer>((a) => ({
    id: a.answerId,
    questionId: a.questionId,
    answerItems: a.answerItems.map<AnswerItem>((ai) => ({
      id: ai.answerItemId,
      questionItemId: ai.questionItemId,
      description: ai.description,
      checked: ai.checked,
      choiceNotes: ai.choiceNotes,
    })),
  }));
  const questions = questionState
    .filter(
      (q) =>
        !isInvalidQuestion({
          condition: conditionState.find((c) => c.questionId === q.questionId),
          prerequisites: prerequisiteState.filter((p) => p.conditionId),
          ageValue: ageCalculation(state[0].birthday),
          sexValue: state[0].sex,
          inputs: {
            questions: answers.map((a) => ({
              questionId: a.questionId,
              focused: true,
              answers: a.answerItems.map(
                ({ questionItemId, checked, description, choiceNotes }) => ({
                  questionItemId,
                  checked,
                  description,
                  choiceNotes,
                }),
              ),
            })),
          },
        }),
    )
    .slice()
    .sort((a, b) => a.sortNumber - b.sortNumber)
    .map((q, idx) => ({ ...q, sortNumber: idx + 1 }))
    .map((q) => ({
      id: q.questionId,
      type: q.answerType,
      text: q.text,
      required: q.required,
      sortNumber: q.sortNumber,
      questionItems: questionItemState
        .slice()
        .sort((a, b) => a.sortNumber - b.sortNumber)
        .filter((qi) => qi.questionId === q.questionId)
        .map<QuestionItem>((qi) => ({
          id: qi.questionItemId,
          choiceText: qi.choiceText,
          hasChoiceNotes: qi.hasChoiceNotes,
          choiceNotesPrefix: qi.choiceNotesPrefix,
          choiceNotesSuffix: qi.choiceNotesSuffix,
          choiceNotesMaxLength: 1,
          choicePlaceholder: qi.choicePlaceholder,
          descriptionMaxLines: 1,
          descriptionMaxLength: 50,
          descriptionNotesPrefix: qi.descriptionPrefix,
          descriptionNotesSuffix: qi.descriptionSuffix,
          descriptionPlaceholder: qi.descriptionPlaceholder,
          sortNumber: qi.sortNumber,
          tags: [] as Tag[],
        })),
    }));
  const data = state.length ? toApiInterviewResult(state[0]) : [];
  const handleNextClick = () => {
    if (!isAgree) return;
    if (processing.current) return;
    processing.current = true;

    const saveData = async (): Promise<void> => {
      try {
        const result = await postInterviewResult(
          tenantId,
          data as PostInterviewResult,
        );
        if (result.ok) {
          history.push(`/tenants/${id}/interviewComplete`);
        } else {
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、最初から入力をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.log(error);
      } finally {
      }
    };
    setTimeout(() => {
      processing.current = false;
    }, 3000);
    saveData();
  };

  const Confirm = () => {
    return (
      <Paper w="full" p="4" mb="4">
        <Header
          text="入力内容を確認してください"
          iconElement={<MdCheck color="white" />}
        />
        <Box mb="6">
          <PersonalInformation />
          <Questions questions={questions} answers={answers} />
          <hr />
        </Box>
        <Header
          text="個人情報の取り扱いについて"
          iconElement={<MdVerifiedUser color="white" />}
        />
        <PrivacyPolicy />
        <Checkbox
          colorScheme="webInterview"
          my="6"
          size="lg"
          isChecked={isAgree}
          onChange={(e) => setIsAgree(e.target.checked)}
        >
          <Text color="gray.600" fontWeight="bold">
            同意する
          </Text>
        </Checkbox>
        <Flex>
          <Button
            borderRadius="full"
            w="full"
            mr="4"
            onClick={() => history.goBack()}
          >
            戻る
          </Button>
          <Button
            borderRadius="full"
            w="full"
            colorScheme="webInterview"
            onClick={handleNextClick}
            isDisabled={!isAgree}
          >
            登録する
          </Button>
        </Flex>
      </Paper>
    );
  };

  return (
    <ErrorCheck tenantId={tenantId}>
      {!state.length ? (
        <ReEnter />
      ) : (
        <EntryLayout>
          {isMobile ? (
            <Box px={px}>
              <StepperMobile pageNumber={4} />
              <Confirm />
              <ClinicInformation tenantId={tenantId} />
            </Box>
          ) : (
            <HStack spacing="3" align="start">
              <Box w="315px">
                <Stepper pageNumber={4} />
                <ClinicInformation tenantId={tenantId} />
              </Box>
              <Box w="full">
                <Confirm />
              </Box>
            </HStack>
          )}
        </EntryLayout>
      )}
    </ErrorCheck>
  );
};
