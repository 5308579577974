import React from 'react';
import {
  Box,
  Flex,
  Stack,
  Text,
  Link,
  Image,
  useMediaQuery,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { Copyright } from './Copyright';

type Props = {
  isEntry?: boolean;
};

export const Footer: React.VFC<Props> = ({ isEntry = false }) => {
  const [isMobile] = useMediaQuery('(max-width: 420px)');
  return (
    <Box as="footer" role="contentinfo" maxW="1000px" mx="auto">
      <FooterLayout isMobile={isMobile}>
        <Stack>
          <Stack direction="row" spacing="7" align="center">
            <Image
              src="https://bws.mic.jp/wp-content/uploads/2021/05/logo.png"
              alt="mic logo"
            />
            <Link
              as={ReactRouterLink}
              to={'/TermsOfService'}
              _hover={{ color: 'blue.400', textDecoration: 'none' }}
              isExternal
            >
              利用規約
            </Link>
          </Stack>
          <Flex>
            <Copyright alignSelf={{ base: 'center', sm: 'start' }} />
            <Text color="gray.600" fontSize="sm" ml="4">
              Ver. {process.env.REACT_APP_PACKAGE_VERSION}
            </Text>
          </Flex>
        </Stack>
        {isEntry ? (
          <></>
        ) : (
          <Flex mt={isMobile ? '4' : '0'} alignItems="center" color="gray.600">
            操作終了後はログアウトを推奨しています
          </Flex>
        )}
      </FooterLayout>
    </Box>
  );
};

type FooterLayoutProps = {
  isMobile: boolean;
  children: React.ReactNode;
};

const FooterLayout: React.VFC<FooterLayoutProps> = ({ isMobile, children }) => {
  return isMobile ? (
    <Box maxW="full" py="12" px="4">
      {children}
    </Box>
  ) : (
    <Flex maxW="full" py="12" px="4" justifyContent="space-between">
      {children}
    </Flex>
  );
};
