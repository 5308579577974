import React from 'react';
import { Progress, Box, Flex } from '@chakra-ui/react';

export const Loading: React.VFC = () => {
  return (
    <Flex w="full" h="100vH" justifyContent="center" align="center">
      <Box w="70%">
        <Progress size="xs" colorScheme="blue" isIndeterminate />
      </Box>
    </Flex>
  );
};
