import React, { useState } from 'react';
import Cropper from 'react-easy-crop';
import { Area } from 'react-easy-crop/types';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
} from '@chakra-ui/react';
import { ASPECT_RATIO } from '../../constant/constant';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  imgSource: string | undefined;
  setImgSource: (imgSource: string) => void;
  onCropedComplete: (croppedArea: Area, croppedAreaPixels: Area) => void;
  showCroppedImage: () => void;
  zoom: number;
  setZoom: (zoom: number) => void;
};

export const CropperModal: React.FC<Props> = ({
  isOpen,
  onClose,
  imgSource,
  setImgSource,
  onCropedComplete,
  showCroppedImage,
  zoom,
  setZoom,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });

  const handleOk = () => {
    showCroppedImage();
    onClose();
  };

  const handleCancel = () => {
    setImgSource('');
    onClose();
  };

  // react-easy-cropの既知のバグに対応（issue-428）
  const motionPreset = 'none';

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      size="xl"
      motionPreset={motionPreset}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody pt="5">
          <Box position="relative" h="450px">
            <Cropper
              image={imgSource}
              crop={crop}
              zoom={zoom}
              aspect={ASPECT_RATIO}
              onCropChange={setCrop}
              onCropComplete={onCropedComplete}
              onZoomChange={setZoom}
              showGrid={false}
            />
          </Box>
          <Slider
            aria-label="slider"
            defaultValue={zoom}
            onChange={(value) => setZoom(value)}
            min={1}
            max={3}
            step={0.1}
            value={zoom}
            mt="10"
          >
            <SliderMark
              value={zoom}
              textAlign="center"
              bg="blue.500"
              color="white"
              mt="-10"
              ml="-5"
              w="12"
            >
              {Math.round(zoom * 100)}%
            </SliderMark>{' '}
            <SliderTrack>
              <SliderFilledTrack />
            </SliderTrack>
            <SliderThumb />
          </Slider>
        </ModalBody>
        <ModalFooter>
          <Button mr="3" colorScheme="webInterview" onClick={handleOk}>
            OK
          </Button>
          <Button onClick={handleCancel}>キャンセル</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
