import { GetTenant } from '../types/tenantApiTypes';
import { Tenant } from '../types/tenant';

export const toTenant: (data: GetTenant) => Tenant = ({
  id,
  name,
  zip_code,
  street_address,
  phone_number,
  mail_address,
  homepage,
  created_at,
  updated_at,
  subscriptions,
}) => {
  return {
    tenantId: id,
    name: name,
    zipCode: zip_code,
    streetAddress: street_address,
    phoneNumber: phone_number,
    mailAddress: mail_address,
    homepage: homepage,
    createdAt: created_at,
    updatedAt: updated_at,
    subscriptions: subscriptions.map(
      ({ service_id, service_name, enable }) => ({
        serviceId: service_id,
        serviceName: service_name,
        enable,
      }),
    ),
  };
};
