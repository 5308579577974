import React, { useState, useContext, useEffect } from 'react';
import {
  Box,
  SimpleGrid,
  Heading,
  Flex,
  Spacer,
  CircularProgress,
  useBoolean,
} from '@chakra-ui/react';
import { Layout } from '../../components/Layout/Layout';
import { InterviewSheetCard } from './InterviewSheetCard';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import { GET_INTERVIEWSHEETS } from '../../actions/interviewSheetAction';
import { GetInterviewSheets } from '../../types/interviewSheetApiTypes';
import { toInterviewSheets } from '../../domains/toInterviewSheets';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewSheets } from '../../api';
import { Authorization } from '../../components/Authorization';
import { PATHNAME } from '../../constant/constant';
import { NewSheetButton } from './NewSheetButton';
import { SortButton } from './SortButton';
import { useGetTags } from '../../hooks/useGetTags';

export const InterviewSheets = () => {
  const { state } = useContext(InterviewSheetContext);
  const { dispatch } = useContext(SetInterviewSheetContext);
  const [sheetsLoading, setSheetsLoading] = useState(false);
  const [reload, setReload] = useBoolean(false);
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const { tags, loading: tagLoading } = useGetTags();

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setSheetsLoading(true);
        const result = await getInterviewSheets(tenantId);
        if (result.ok) {
          const data: GetInterviewSheets = await result.json();
          dispatch({
            type: GET_INTERVIEWSHEETS,
            data: toInterviewSheets(data),
          });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setSheetsLoading(false);
      }
    };
    getData();
  }, [tenantId, dispatch, reload]);

  return (
    <Layout>
      <Authorization pathName={PATHNAME.INTERVIEW_SHEETS}>
        <Flex m="4">
          <Heading size="lg" color="gray.600">
            問診票カスタマイズ
          </Heading>
          <Spacer />
          <Box mr="3">
            <SortButton
              setReload={setReload}
              isDisabled={sheetsLoading || tagLoading}
            />
          </Box>
          <NewSheetButton
            tags={tags}
            isDisabled={sheetsLoading || tagLoading}
          />
        </Flex>
        {sheetsLoading ? (
          <CircularProgress isIndeterminate color="blue.300" />
        ) : (
          <Box m="3">
            <SimpleGrid columns={{ sm: 1, md: 3 }} spacing="3">
              {state.map(
                (
                  {
                    interviewSheetId,
                    name,
                    description,
                    updatedAt,
                    releaseDate,
                  },
                  idx,
                ) => (
                  <InterviewSheetCard
                    id={interviewSheetId}
                    title={name}
                    summary={description}
                    updatedAt={updatedAt}
                    releaseDate={releaseDate}
                    setLoading={setSheetsLoading}
                    setReload={setReload}
                    key={idx}
                  ></InterviewSheetCard>
                ),
              )}
            </SimpleGrid>
          </Box>
        )}
      </Authorization>
    </Layout>
  );
};
