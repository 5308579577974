import configJson from './auth_config.json';

export function getConfig() {
  const audience =
    configJson.audience && configJson.audience !== 'YOUR_API_IDENTIFIER'
      ? configJson.audience
      : null;

  return {
    domain: process.env.REACT_APP_AUTH0_DOMAIN ?? '',
    clientId: process.env.REACT_APP_AUTH0_CLIENT_ID ?? '',
    connection: configJson.connection,
    ...(audience ? { audience } : null),
    scope: configJson.scope,
    prompt: configJson.prompt,
  };
}
