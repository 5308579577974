import React from 'react';
import {
  Box,
  Heading,
  Grid,
  GridItem,
  Flex,
  Spacer,
  IconButton,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { MdEdit } from 'react-icons/md';
import { PersonalInformationEdit } from './PersonalInformationEdit';
import { PersonalInformationCard } from './PersonalInformationCard';
import { PersonalInformation as PersonalInformationType } from '../../types/personalInformation';
import { dateConvert } from '../../utils/dateConvert';
import { ageCalculation } from '../../utils/ageCalculation';

type Props = {
  personalInformation: PersonalInformationType;
  save: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const PersonalInformation: React.VFC<Props> = ({
  personalInformation,
  save,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const personalDisplayData = [
    {
      name: '患者番号',
      data: personalInformation.pid,
      columns: 2,
    },
    {
      name: '氏名',
      data: `${personalInformation.lastName} ${personalInformation.firstName} ( ${personalInformation.lastKana} ${personalInformation.firstKana} )`,
      columns: 4,
    },
    {
      name: '性別',
      data:
        personalInformation.sex === 'male'
          ? '男'
          : personalInformation.sex === 'female'
          ? '女'
          : '不明',
      columns: 1,
    },
    {
      name: '生年月日',
      data: `${dateConvert(
        personalInformation.birthday,
        'date',
      )} ( ${ageCalculation(personalInformation.birthday)}歳 )`,
      columns: 3,
    },
    {
      name: '郵便番号',
      data: personalInformation.zipCode,
      columns: 2,
    },
    {
      name: '住所',
      data: personalInformation.streetAddress,
      columns: 8,
    },
    {
      name: '電話番号',
      data: personalInformation.phoneNumber,
      columns: 2,
    },

    {
      name: 'メールアドレス',
      data: personalInformation.mailAddress,
      columns: 8,
    },
    {
      name: '予約日時',
      data: dateConvert(personalInformation.reservationDate, 'minutes'),
      columns: 10,
    },
  ];
  const confirmData = [
    {
      name: '状態',
      data:
        personalInformation.status === 'identified'
          ? '本人確認済み'
          : personalInformation.status === 'unidentified'
          ? '本人確認未済'
          : '無効',
    },
    {
      name: '作成日時',
      data: dateConvert(personalInformation.createdAt, 'seconds'),
    },
    { name: '確認者', data: personalInformation.identifiedName },
    {
      name: '確認日時',
      data: personalInformation.identifiedDate
        ? dateConvert(personalInformation.identifiedDate, 'seconds')
        : '',
    },
  ];
  return (
    <Box mx="4">
      <Paper py="4" px="5">
        <Flex mb="3" alignItems="center">
          <Heading size="md" color="gray.600" textAlign="left">
            患者情報
          </Heading>
          <Spacer />
          <Tooltip label="編集">
            <IconButton
              aria-label="edit"
              icon={<MdEdit />}
              size="sm"
              fontSize="xl"
              borderRadius="full"
              borderWidth="1px"
              bg="white"
              onClick={onOpen}
              isDisabled={personalInformation.status === 'deleted'}
            ></IconButton>
          </Tooltip>
        </Flex>
        <Grid
          templateColumns="repeat(10, 1fr)"
          gap="5"
          py="3"
          borderTopWidth="1px"
          borderBottomWidth="1px"
        >
          {personalDisplayData.map(({ name, data, columns }, idx) => (
            <GridItem w="100%" key={idx} colSpan={columns}>
              <PersonalInformationCard name={name} data={data} />
            </GridItem>
          ))}
        </Grid>
        <Grid templateColumns="repeat(4, 1fr)" gap="6" my="3">
          {confirmData.map(({ name, data }, idx) => (
            <GridItem w="100%" key={idx}>
              <PersonalInformationCard name={name} data={data} />
            </GridItem>
          ))}
        </Grid>
      </Paper>
      <PersonalInformationEdit
        isOpen={isOpen}
        onClose={onClose}
        personalInformation={personalInformation}
        save={save}
      />
    </Box>
  );
};
