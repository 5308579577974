import { Status } from '../types/webInterview';

export const toStatusName = (status: Status) => {
  switch (status) {
    case 'unidentified':
      return '未確認';
    case 'identified':
      return '確認済';
    case 'deleted':
      return '無効';
    default:
      return '';
  }
};
