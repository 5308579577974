import React from 'react';
import { VStack, Text } from '@chakra-ui/react';

type Props = {
  name: string;
  data: string;
};

export const PersonalInformationCard: React.VFC<Props> = ({ name, data }) => {
  return (
    <VStack align="start">
      <Text fontWeight="bold" color="gray.600" textAlign="left" w="full">
        {name}
      </Text>
      <Text color="gray.600" textAlign="left" w="full">
        {data}
      </Text>
    </VStack>
  );
};
