import { useState, useEffect } from 'react';

type BrowserName = 'Chrome' | 'Edge' | 'Firefox' | 'Safari' | 'Others';

export const useBrowserDetect = () => {
  const [browserName, setBrowserName] = useState<BrowserName>();

  useEffect(() => {
    const brands = navigator.userAgentData?.brands;
    const userAgent =
      typeof navigator === 'undefined' ? '' : navigator.userAgent;

    // Mobile Safari は favicon を取得するために使用されるものなので考慮しない
    const browser = brands?.find((b) => b.brand === 'Google Chrome')
      ? 'Chrome'
      : brands?.find((b) => b.brand === 'Microsoft Edge')
      ? 'Edge'
      : userAgent.match(/Chrome/i)
      ? 'Chrome'
      : userAgent.match(/Edge/i)
      ? 'Edge'
      : userAgent.match(/FireFox/i)
      ? 'Firefox'
      : userAgent.match(/Safari/i)
      ? 'Safari'
      : 'Others';
    setBrowserName(browser);
  }, []);

  return { browserName };
};
