import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import {
  CircularProgress,
  Flex,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  Tfoot,
} from '@chakra-ui/react';
import ReactPaginate from 'react-paginate';
import { Paper } from './atoms/Paper';
import { MdChevronRight, MdChevronLeft } from 'react-icons/md';

type Props = {
  interviewResults: DataType[];
  loading: boolean;
  showPid?: boolean;
  showStatus?: boolean;
  showFooter?: boolean;
  showCreatedAt?: boolean;
  filterChange?: boolean;
  setFilterChange?: React.Dispatch<React.SetStateAction<boolean>>;
};

export type DataType = {
  interviewResultId: string;
  reservationDate: string;
  pid: string;
  patientName: string;
  status: string;
  createdAt: string;
};

export const InterviewResultsTable: React.VFC<Props> = ({
  interviewResults,
  loading,
  showPid = true,
  showStatus = true,
  showFooter = true,
  showCreatedAt = true,
  filterChange = false,
  setFilterChange,
}) => {
  const history = useHistory();

  const itemsPerPage = 8;
  const [pageCount, setPageCount] = useState(0);
  // ここでは、アイテムオフセットを使用します。ページオフセットを使用することもできます
  // 使用しているAPIまたはデータに従います
  const [itemOffset, setItemOffset] = useState(0);
  const [currentItems, setCurrentItems] = useState<DataType[]>();

  const handlePageClick = useCallback(
    (event: { selected: number }) => {
      const newOffset =
        (event.selected * itemsPerPage) % interviewResults.length;
      setItemOffset(newOffset);
      setForcePage(event.selected);
    },
    [interviewResults.length],
  );

  const columns = [
    { columnName: '予約日時', show: true },
    { columnName: '患者番号', show: showPid },
    { columnName: '氏名', show: true },
    { columnName: 'ステータス', show: showStatus },
    { columnName: '作成日時', show: showCreatedAt },
  ];
  const columnCount = columns.filter(({ show }) => show === true).length;

  const pidMaxWidth = { base: '100px', md: 'none' };
  const [forcePage, setForcePage] = useState(-1);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(interviewResults.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(interviewResults.length / itemsPerPage));
    if (filterChange) {
      handlePageClick({ selected: 0 });
      if (setFilterChange) setFilterChange(false);
    }
  }, [
    itemOffset,
    itemsPerPage,
    interviewResults,
    handlePageClick,
    filterChange,
    setFilterChange,
  ]);

  return (
    <Paper>
      <Table>
        <Thead>
          <Tr>
            <Th>予約日時</Th>
            {showPid ? <Th maxW={pidMaxWidth}>患者番号</Th> : <></>}
            <Th>氏名</Th>
            {showStatus ? <Th>ステータス</Th> : <></>}
            {showCreatedAt ? <Th>作成日時</Th> : <></>}
          </Tr>
        </Thead>
        <Tbody>
          {loading ? (
            <Tr>
              <Td colSpan={columnCount}>
                <Flex justifyContent="center">
                  <CircularProgress isIndeterminate color="blue.300" />
                </Flex>
              </Td>
            </Tr>
          ) : (
            currentItems?.map((data, idx) => (
              <Tr
                role="group"
                key={idx}
                onClick={() =>
                  history.push(`/interviewResults/${data.interviewResultId}`)
                }
                _hover={{ cursor: 'pointer' }}
              >
                <Td transition="all .2s ease" _groupHover={{ bg: 'blue.50' }}>
                  {data.reservationDate}
                </Td>
                {showPid ? (
                  <Td
                    maxW={pidMaxWidth}
                    transition="all .2s ease"
                    _groupHover={{ bg: 'blue.50' }}
                  >
                    {data.pid}
                  </Td>
                ) : (
                  <></>
                )}
                <Td transition="all .2s ease" _groupHover={{ bg: 'blue.50' }}>
                  {data.patientName}
                </Td>
                {showStatus ? (
                  <Td transition="all .2s ease" _groupHover={{ bg: 'blue.50' }}>
                    {data.status}
                  </Td>
                ) : (
                  <></>
                )}
                {showCreatedAt ? (
                  <Td transition="all .2s ease" _groupHover={{ bg: 'blue.50' }}>
                    {data.createdAt}
                  </Td>
                ) : (
                  <></>
                )}
              </Tr>
            ))
          )}
        </Tbody>
        {showFooter ? (
          <Tfoot>
            <Tr>
              <Td colSpan={5}>
                <Flex justify="center">
                  <ReactPaginate
                    breakLabel="..."
                    nextLabel={<MdChevronRight />}
                    previousLabel={<MdChevronLeft />}
                    pageCount={pageCount}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination'}
                    activeClassName={'active'}
                    previousClassName={'pagination__previous'}
                    nextClassName={'pagination__next'}
                    disabledClassName={'pagination__disabled'}
                    previousLinkClassName={'pagination__previous__link'}
                    nextLinkClassName={'pagination__next__link'}
                    forcePage={forcePage}
                  />
                </Flex>
              </Td>
            </Tr>
          </Tfoot>
        ) : (
          <></>
        )}
      </Table>
    </Paper>
  );
};
