import React from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, Link, Text, VStack } from '@chakra-ui/react';
import { EntryLayout } from '../../components/Layout/EntryLayout';
import { Paper } from '../../components/atoms/Paper';
import { ClinicInformation } from '../../components/ClinicInformation';
import { useTenant } from '../../hooks/useTenant';
import { ErrorCheck } from '../../components/ErrorCheck';

type Props = {};

export const InterviewComplete: React.VFC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const tenantId: string = (() => {
    try {
      return atob(id.replace(/\r?\n/g, ''));
    } catch (ignored) {
      console.log(ignored);
      return '';
    }
  })();
  const { state: tenant } = useTenant(tenantId);
  return (
    <ErrorCheck tenantId={tenantId}>
      <EntryLayout>
        <Paper w="full" p="4" mb="4">
          <VStack spacing="4" align="stretch" justify="space-between">
            <Box bgColor="gray.500" p="3" borderRadius="3xl">
              <Text color="white" fontWeight="bold" fontSize="xl">
                問診票の登録が完了しました
              </Text>
            </Box>
            <Text color="gray.600" fontWeight="bold" fontSize="lg">
              受付の際に問診票が登録済みであることをお伝えください。
            </Text>
          </VStack>
        </Paper>
        {tenant.homepage ? (
          <Button colorScheme="webInterview" borderRadius="full" mb="4">
            <Link href={tenant.homepage}>医院のサイトに戻る</Link>
          </Button>
        ) : (
          <></>
        )}
        <ClinicInformation tenantId={tenantId} />
      </EntryLayout>
    </ErrorCheck>
  );
};
