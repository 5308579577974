import { GetInterviewSheet } from '../types/interviewSheetApiTypes';
import { Question } from '../types/question';
import { AnswerType } from '../types/webInterview';

export const toQuestions = (data: GetInterviewSheet) => {
  return data.questions.map<Question>((q) => ({
    questionId: q.id,
    interviewSheetId: data.id,
    answerType: q.type as AnswerType,
    text: q.text,
    required: q.required,
    sortNumber: q.sort_number,
    hasCondition: false,
  }));
};
