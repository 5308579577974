import React, { useState, useContext, useEffect } from 'react';
import { Input, Button, Text, Checkbox, HStack, Box } from '@chakra-ui/react';
import { ModalDialog } from '../../components/ModalDialog';
import { BUTTON } from '../../components/atoms/Button';
import {
  QuestionItemContext,
  SetQuestionItemContext,
} from '../../contexts/QuestionItemContext';
import {
  CHANGE_DESCRIPTION_PREFIX,
  CHANGE_DESCRIPTION_SUFFIX,
  CHANGE_DESCRIPTION_PLACEHOLDER,
  CHANGE_HAS_CHOICE_NOTES,
  CHANGE_CHOICE_NOTES_PREFIX,
  CHANGE_CHOICE_NOTES_SUFFIX,
  CHANGE_CHOICE_NOTES_PLACEHOLDER,
} from '../../actions/questionItemAction';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  questionItemId: string;
  isDescription: boolean;
};

export const AffixModalDialog: React.VFC<Props> = ({
  isOpen,
  onClose,
  questionItemId,
  isDescription,
}) => {
  const { state } = useContext(QuestionItemContext);
  const { dispatch } = useContext(SetQuestionItemContext);
  const {
    descriptionPrefix,
    descriptionSuffix,
    descriptionPlaceholder,
    hasChoiceNotes,
    choiceNotesPrefix,
    choiceNotesSuffix,
    choicePlaceholder,
  } =
    state.find((c) => c.questionItemId === questionItemId) ??
    defaultQuestionItem;
  const [hasNotes, setHasNotes] = useState(hasChoiceNotes);
  const [prefix, setPrefix] = useState(
    isDescription ? descriptionPrefix : choiceNotesPrefix,
  );
  const [suffix, setSuffix] = useState(
    isDescription ? descriptionSuffix : choiceNotesSuffix,
  );
  const [placeholder, setPlaceholder] = useState(
    isDescription ? descriptionPlaceholder : choicePlaceholder,
  );

  useEffect(() => {
    if (isOpen) {
      setHasNotes(hasChoiceNotes);
      setPrefix(isDescription ? descriptionPrefix : choiceNotesPrefix);
      setSuffix(isDescription ? descriptionSuffix : choiceNotesSuffix);
      setPlaceholder(
        isDescription ? descriptionPlaceholder : choicePlaceholder,
      );
    }
  }, [
    isOpen,
    hasChoiceNotes,
    isDescription,
    descriptionPrefix,
    descriptionSuffix,
    choiceNotesPrefix,
    choiceNotesSuffix,
    descriptionPlaceholder,
    choicePlaceholder,
  ]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    if (!isDescription) {
      dispatch({
        type: CHANGE_HAS_CHOICE_NOTES,
        hasNotes: hasNotes,
        questionItemId: questionItemId,
      });
    }
    dispatch({
      type: isDescription
        ? CHANGE_DESCRIPTION_PREFIX
        : CHANGE_CHOICE_NOTES_PREFIX,
      prefix: prefix,
      questionItemId: questionItemId,
    });
    dispatch({
      type: isDescription
        ? CHANGE_DESCRIPTION_SUFFIX
        : CHANGE_CHOICE_NOTES_SUFFIX,
      suffix: suffix,
      questionItemId: questionItemId,
    });
    dispatch({
      type: isDescription
        ? CHANGE_DESCRIPTION_PLACEHOLDER
        : CHANGE_CHOICE_NOTES_PLACEHOLDER,
      placeholder: placeholder,
      questionItemId: questionItemId,
    });
    handleClose();
  };

  return (
    <>
      <ModalDialog
        body={
          <>
            {!isDescription ? (
              <HStack mb="3">
                <Checkbox
                  isChecked={hasNotes}
                  onChange={(e) => setHasNotes(e.target.checked)}
                  colorScheme="webInterview"
                >
                  <Text color="gray.600">入力欄を有効にする</Text>
                </Checkbox>
              </HStack>
            ) : (
              <></>
            )}
            <Box mb="3">
              <Text color="gray.600">先頭</Text>
              <Input
                value={prefix}
                onChange={(e) => setPrefix(e.target.value)}
                disabled={isDescription ? !isDescription : !hasNotes}
                color="gray.600"
              />
            </Box>
            <Box mb="3">
              <Text color="gray.600">末尾</Text>
              <Input
                value={suffix}
                onChange={(e) => setSuffix(e.target.value)}
                disabled={isDescription ? !isDescription : !hasNotes}
                color="gray.600"
              />
            </Box>
            <Box>
              <Text color="gray.600">プレースホルダー</Text>
              <Input
                value={placeholder}
                onChange={(e) => setPlaceholder(e.target.value)}
                disabled={isDescription ? !isDescription : !hasNotes}
                color="gray.600"
              />
            </Box>
          </>
        }
        footer={
          <HStack spacing="3">
            <BUTTON text="保存" onClick={handleSave} />
            <Button onClick={handleClose}>キャンセル</Button>
          </HStack>
        }
        isOpen={isOpen}
        onClose={handleClose}
      />
    </>
  );
};
