import { GET_TENANT, TenantAction } from '../actions/tenantAction';
import { Tenant } from '../types/tenant';

export const defaultTenant: Tenant = {
  tenantId: '',
  name: '',
  zipCode: '',
  streetAddress: '',
  phoneNumber: '',
  mailAddress: '',
  homepage: '',
  createdAt: '',
  updatedAt: '',
  subscriptions: [],
};

export const initialState: Tenant = defaultTenant;

export const tenantReducer = (state = initialState, action: TenantAction) => {
  switch (action.type) {
    case GET_TENANT:
      const { data } = action;
      return {
        ...data,
        subscriptions: data.subscriptions.filter(
          (s) => s.serviceName === 'Web問診',
        ),
      };
    default:
      return state;
  }
};
