import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';
import { MdSort } from 'react-icons/md';
import { SortModal } from './SortModal';

type Props = {
  setReload: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  isDisabled: boolean;
};

export const SortButton: React.FC<Props> = ({ setReload, isDisabled }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  return (
    <>
      <Button
        leftIcon={<MdSort size="24px" />}
        colorScheme="webInterview"
        onClick={onOpen}
        isDisabled={isDisabled}
      >
        並び替え
      </Button>
      <SortModal isOpen={isOpen} onClose={onClose} setReload={setReload} />
    </>
  );
};
