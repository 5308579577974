import React, { createContext } from 'react';
import { PrerequisiteAction } from '../actions/prerequisiteAction';
import { Prerequisite } from '../types/prerequisite';

export const PrerequisiteContext = createContext(
  {} as {
    state: Prerequisite[];
  },
);

export const SetPrerequisiteContext = createContext(
  {} as {
    dispatch: React.Dispatch<PrerequisiteAction>;
  },
);
