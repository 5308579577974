import { Prerequisite } from '../types/prerequisite';

export const ADD_PREREQUISITE_ITEM = 'ADD_PREREQUISITE_ITEM';
export const REMOVE_PREREQUISITE_ITEM = 'REMOVE_PREREQUISITE_ITEM';
export const REMOVE_PREREQUISITE_ITEMS = 'REMOVE_PREREQUISITE_ITEMS';
export const CHANGE_PREREQUISITE_QUESTION = 'CHANGE_PREREQUISITE_QUESTION';
export const GET_INTERVIEWSHEET = 'GET_INTERVIEWSHEET';

export type PrerequisiteAction =
  | {
      type: 'ADD_PREREQUISITE_ITEM';
      conditionId: string;
      questionId: string;
      questionItemId: string;
    }
  | {
      type: 'REMOVE_PREREQUISITE_ITEM';
      prerequisiteId: string;
    }
  | {
      type: 'REMOVE_PREREQUISITE_ITEMS';
    }
  | {
      type: 'CHANGE_PREREQUISITE_QUESTION';
      conditionId: string;
      questionId: string;
    }
  | {
      type: 'GET_INTERVIEWSHEET';
      data: Prerequisite[];
    };
