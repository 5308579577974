import React, { useCallback, useContext, useEffect, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Text, TextProps, Input } from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import { CHANGE_RELEASE_DATE } from '../../actions/interviewSheetAction';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';

export const ReleaseDate: React.VFC<TextProps> = memo((props) => {
  const { state } = useContext(InterviewSheetContext);
  const { dispatch } = useContext(SetInterviewSheetContext);
  const urlParams = useParams<{ id: string }>();
  const { interviewSheetId, releaseDate } =
    state.find((s) => s.interviewSheetId === urlParams.id) ??
    defaultInterviewSheet;
  const date = releaseDate;
  const handleChangeReleaseDate = useCallback(
    (releaseDate: string) => {
      dispatch({
        type: CHANGE_RELEASE_DATE,
        interviewSheetId,
        releaseDate,
      });
    },
    [dispatch, interviewSheetId],
  );
  useEffect(() => {
    if (!date) {
      handleChangeReleaseDate(releaseDate);
    }
  }, [date, releaseDate, handleChangeReleaseDate]);

  return (
    <Paper px="4" py="3">
      <Row
        mainTitle={<Text {...props}>{'公開日時'}</Text>}
        contents={
          <Input
            type="datetime-local"
            value={date}
            onChange={(e) => handleChangeReleaseDate(e.target.value)}
            color={props.color}
          />
        }
      />
    </Paper>
  );
});
