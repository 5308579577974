import React from 'react';
import {
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from '@chakra-ui/react';

type Props = {
  title: React.ReactNode;
  children: React.ReactNode;
};

export const ExpandedAccordion: React.VFC<Props> = ({ title, children }) => {
  return (
    <Accordion defaultIndex={[0]} allowMultiple w="full" borderColor="white">
      <AccordionItem>
        <AccordionButton h="40px">
          <Box flex="1">{title}</Box>
          <AccordionIcon />
        </AccordionButton>
        <AccordionPanel px="0">{children}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
};
