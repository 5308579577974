import React from 'react';
import {
  QuestionItem,
  Answer,
  AnswerItem,
} from '../../types/interviewResultConfirm';
import { AnswerType } from '../../types/webInterview';
import { InterviewResultAnswerChoice } from './InterviewResultAnswerChoice';
import { InterviewResultAnswerText } from './InterviewResultAnswerText';

type Props = {
  questionItem: QuestionItem;
  answer: Answer;
  answerType: AnswerType;
};

export const InterviewResultAnswer: React.VFC<Props> = ({
  questionItem,
  answer,
  answerType,
}) => {
  const {
    choiceText,
    hasChoiceNotes,
    choiceNotesPrefix,
    choiceNotesSuffix,
    descriptionNotesPrefix,
    descriptionNotesSuffix,
    tags,
  } = questionItem;
  const { checked, description, choiceNotes } =
    answer.answerItems.find((ai) => ai.questionItemId === questionItem.id) ??
    ({
      id: '',
      questionItemId: '',
      description: '',
      checked: false,
      choiceNotes: '',
    } as AnswerItem);

  return (
    <>
      {answerType === 'textline' || answerType === 'textbox' ? (
        <InterviewResultAnswerText
          answerType={answerType}
          description={description}
          descriptionNotesPrefix={descriptionNotesPrefix}
          descriptionNotesSuffix={descriptionNotesSuffix}
          tags={tags}
        />
      ) : (
        <InterviewResultAnswerChoice
          choiceNotes={choiceNotes}
          hasChoiceNotes={hasChoiceNotes}
          choiceNotesPrefix={choiceNotesPrefix}
          choiceNotesSuffix={choiceNotesSuffix}
          choiceText={choiceText}
          tags={tags}
          answerType={answerType}
          checked={checked}
        />
      )}
    </>
  );
};
