import { GetInterviewSheet } from '../types/interviewSheetApiTypes';
import { Condition } from '../types/condition';

export const toConditions = (data: GetInterviewSheet) => {
  return data.questions.map<Condition>(({ id, condition }) => ({
    conditionId: condition.id,
    ageRequired: condition.age_required,
    age: condition.age,
    ageOperator: condition.age_operator,
    sexRequired: condition.sex_required,
    sex: condition.female ? 'female' : 'male',
    questionRequired: condition.question_required,
    prerequisiteQuestionId: condition.prerequisite_question_id,
    questionId: id,
  }));
};
