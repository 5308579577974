import React, { useState, useContext, useEffect, useMemo, memo } from 'react';
import {
  Menu,
  MenuList,
  MenuButton,
  MenuItemOption,
  MenuOptionGroup,
  IconButton,
  Tooltip,
} from '@chakra-ui/react';
import { MdExtension } from 'react-icons/md';
import {
  ConditionContext,
  SetConditionContext,
} from '../../contexts/ConditionContext';
import {
  CHANGE_AGE_REQUIRED,
  CHANGE_SEX_REQUIRED,
  CHANGE_QUESTION_REQUIRED,
} from '../../actions/conditionAction';
import { defaultCondition } from '../../reducers/conditionReducer';

type Props = {
  questionId: string;
};

export const ConditionMenu: React.VFC<Props> = memo(({ questionId }) => {
  const { state } = useContext(ConditionContext);
  const { dispatch } = useContext(SetConditionContext);
  const { conditionId, ageRequired, sexRequired, questionRequired } =
    state.find((c) => c.questionId === questionId) ?? defaultCondition;
  const optionFilter: { text: string; checked: boolean }[] = useMemo(
    () => [
      { text: '年齢', checked: ageRequired },
      { text: '性別', checked: sexRequired },
      {
        text: '質問',
        checked: questionRequired,
      },
    ],
    [ageRequired, sexRequired, questionRequired],
  );
  const [options, setOptions] = useState<string[]>([]);
  useEffect(() => {
    setOptions(
      optionFilter.filter(({ checked }) => checked).map(({ text }) => text),
    );
  }, [setOptions, optionFilter]);

  return (
    <Menu closeOnSelect={true}>
      <Tooltip label="条件の設定">
        <MenuButton
          as={IconButton}
          icon={<MdExtension />}
          isRound={true}
          variant="outline"
          border="none"
          color="gray.600"
        ></MenuButton>
      </Tooltip>
      <MenuList minWidth="240px" align="start">
        <MenuOptionGroup
          title="条件の設定"
          type="checkbox"
          value={options}
          onChange={(value) => {
            setOptions(value as string[]);
          }}
        >
          {optionFilter.map(({ text }, idx) => (
            <MenuItemOption
              value={text}
              key={idx}
              onClick={() => {
                switch (text) {
                  case '年齢':
                    dispatch({ type: CHANGE_AGE_REQUIRED, conditionId });
                    break;
                  case '性別':
                    dispatch({ type: CHANGE_SEX_REQUIRED, conditionId });
                    break;
                  case '質問':
                    dispatch({ type: CHANGE_QUESTION_REQUIRED, conditionId });
                    break;
                  default:
                    console.log('error');
                }
              }}
            >
              {text}
            </MenuItemOption>
          ))}
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
});
