import React from 'react';
import { Box, HStack, Text, Tag } from '@chakra-ui/react';
import { Tag as TagType } from '../../types/interviewResultConfirm';
import { AnswerType } from '../../types/webInterview';
import { AnswerTypeIcon } from './AnswerTypeIcon';

type Props = {
  choiceNotes: string;
  hasChoiceNotes: boolean;
  choiceNotesPrefix: string;
  choiceNotesSuffix: string;
  choiceText: string;
  tags: TagType[];
  answerType: AnswerType;
  checked: boolean;
};

export const InterviewResultAnswerChoice: React.VFC<Props> = ({
  choiceNotes,
  hasChoiceNotes,
  choiceNotesPrefix,
  choiceNotesSuffix,
  choiceText,
  answerType,
  checked,
  tags,
}) => {
  return (
    <Box>
      <HStack textAlign="left" mb="2">
        <AnswerTypeIcon
          boxProps={{ h: '7', w: '7' }}
          iconProps={{ color: 'gray.600', fontSize: 'xl' }}
          answerType={answerType}
          checked={checked}
        />
        <Text color="gray.600">{choiceText}</Text>
        {tags.map((t, idx) => (
          <Tag
            key={idx}
            size="sm"
            colorScheme={t.isSystemOnly ? 'blue' : !t.enable ? 'red' : 'gray'}
          >
            {t.name}
          </Tag>
        ))}
      </HStack>
      {checked && hasChoiceNotes ? (
        choiceNotes ? (
          <HStack ml="9" spacing="0" mb="2">
            {choiceNotesPrefix ? (
              <Text color="gray.600" pr="2">
                {choiceNotesPrefix}
              </Text>
            ) : (
              <></>
            )}
            <Text
              color="gray.600"
              bg="gray.100"
              px="2"
              borderWidth="1px"
              textAlign="left"
            >
              {choiceNotes}
            </Text>
            {choiceNotesSuffix ? (
              <Text color="gray.600" pl="2">
                {choiceNotesSuffix}
              </Text>
            ) : (
              <></>
            )}
          </HStack>
        ) : (
          <Box textAlign="left">
            <Text color="gray.600" ml="9" mb="2">
              入力なし
            </Text>
          </Box>
        )
      ) : (
        <></>
      )}
    </Box>
  );
};
