import React, { useContext, memo } from 'react';
import { useParams } from 'react-router-dom';
import { Flex, Select, Stack, Text, TextProps } from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import {
  CHANGE_CONDITION_PID,
  CHANGE_CONDITION_ZIP_CODE,
  CHANGE_CONDITION_STREET_ADDRESS,
  CHANGE_CONDITION_MAIL_ADDRESS,
} from '../../actions/interviewSheetAction';
import { ExpandedAccordion } from '../../components/ExpandedAccordion';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import { ItemType } from '../../types/webInterview';

export const PersonalInformation: React.VFC<TextProps> = memo((props) => {
  const { state } = useContext(InterviewSheetContext);
  const { dispatch } = useContext(SetInterviewSheetContext);
  const urlParams = useParams<{ id: string }>();
  const {
    interviewSheetId,
    conditionPid,
    conditionZipCode,
    conditionStreetAddress,
    conditionMailAddress,
  } =
    state.find((s) => s.interviewSheetId === urlParams.id) ??
    defaultInterviewSheet;

  const items: {
    text: string;
    value: string;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  }[] = [
    {
      text: '患者番号',
      value: String(conditionPid),
      onChange: (e) =>
        dispatch({
          type: CHANGE_CONDITION_PID,
          interviewSheetId,
          conditionPid: e.target.value as ItemType,
        }),
    },
    {
      text: '郵便番号',
      value: String(conditionZipCode),
      onChange: (e) =>
        dispatch({
          type: CHANGE_CONDITION_ZIP_CODE,
          interviewSheetId,
          conditionZipCode: e.target.value as ItemType,
        }),
    },
    {
      text: '住所',
      value: String(conditionStreetAddress),
      onChange: (e) =>
        dispatch({
          type: CHANGE_CONDITION_STREET_ADDRESS,
          interviewSheetId,
          conditionStreetAddress: e.target.value as ItemType,
        }),
    },
    {
      text: 'メールアドレス',
      value: String(conditionMailAddress),
      onChange: (e) =>
        dispatch({
          type: CHANGE_CONDITION_MAIL_ADDRESS,
          interviewSheetId,
          conditionMailAddress: e.target.value as ItemType,
        }),
    },
  ];

  const options: { value: ItemType; text: string }[] = [
    {
      value: 'required',
      text: '必須にする',
    },
    {
      value: 'visible',
      text: '表示する',
    },
    {
      value: 'invisible',
      text: '表示しない',
    },
  ];

  return (
    <Paper px="4" py="3">
      <Row
        mainTitle={<Text {...props}>{'個人情報'}</Text>}
        contents={
          <>
            <ExpandedAccordion
              title={
                <Text {...props} fontWeight="bold" mb="2" fontSize="sm"></Text>
              }
            >
              <Stack direction={['column']}>
                {items.map((item, idx) => (
                  <Flex key={idx}>
                    <Text align="left" color="gray.600" ml="4" mt="2" w="50%">
                      {item.text}
                    </Text>
                    <Select defaultValue={item.value} onChange={item.onChange}>
                      {options.map(({ value, text }, idx) => (
                        <option value={value} key={idx}>
                          {text}
                        </option>
                      ))}
                    </Select>
                  </Flex>
                ))}
              </Stack>
            </ExpandedAccordion>
          </>
        }
      />
    </Paper>
  );
});
