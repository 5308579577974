import React, { createContext } from 'react';
import { QuestionItemAction } from '../actions/questionItemAction';
import { QuestionItem } from '../types/questionItem';

export const QuestionItemContext = createContext(
  {} as {
    state: QuestionItem[];
  },
);

export const SetQuestionItemContext = createContext(
  {} as {
    dispatch: React.Dispatch<QuestionItemAction>;
  },
);
