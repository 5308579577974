import React, { useContext, memo } from 'react';
import { Text, IconButton, HStack, RadioGroup, Radio } from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';
import { Row } from './Row';
import {
  ConditionContext,
  SetConditionContext,
} from '../../contexts/ConditionContext';
import { CHANGE_SEX_REQUIRED, CHANGE_SEX } from '../../actions/conditionAction';
import { defaultCondition } from '../../reducers/conditionReducer';
import { Sex } from '../../types/webInterview';

type Props = {
  conditionId: string;
};

export const ConditionSex: React.VFC<Props> = memo(({ conditionId }) => {
  const { state } = useContext(ConditionContext);
  const { dispatch } = useContext(SetConditionContext);
  const condition =
    state.find((c) => c.conditionId === conditionId) ?? defaultCondition;

  return (
    <Row
      mainTitle={
        <Text color="gray.600" mt="2" align="right">
          性別
        </Text>
      }
      contents={
        <RadioGroup
          colorScheme="webInterview"
          onChange={() => dispatch({ type: CHANGE_SEX, conditionId })}
          defaultValue={condition.sex === 'female' ? 'female' : 'male'}
        >
          <HStack spacing="5" mt="2">
            <Radio value={'male' as Sex} color="gray.600">
              男性
            </Radio>
            <Radio value={'female' as Sex} color="gray.600">
              女性
            </Radio>
          </HStack>
        </RadioGroup>
      }
      controlButton={
        <IconButton
          aria-label="delete condition"
          icon={<MdClear />}
          isRound={true}
          variant="outline"
          border="none"
          onClick={() =>
            dispatch({
              type: CHANGE_SEX_REQUIRED,
              conditionId: conditionId,
            })
          }
        />
      }
    />
  );
});
