import React from 'react';
import { Badge } from '@chakra-ui/react';

type Props = {
  releaseDate: Date;
};

export const ReleaseBadge: React.FC<Props> = ({ releaseDate }) => {
  return (
    <Badge
      fontSize="sm"
      colorScheme={releaseDate < new Date() ? 'blue' : 'red'}
      align="left"
    >
      {releaseDate < new Date() ? '公開' : '非公開'}
    </Badge>
  );
};
