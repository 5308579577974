import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Image,
  Link,
  useBreakpointValue,
  useMediaQuery,
  Text,
  IconButton,
  Skeleton,
} from '@chakra-ui/react';
import { MdCall } from 'react-icons/md';
import { TenantContext } from '../../contexts/TenantContext';
import { getHeaderImage } from '../../api';

type Props = {};

export const EntryHeader: React.VFC<Props> = () => {
  const { state: tenantState } = useContext(TenantContext);
  const headerHeight = useBreakpointValue({ lg: '76px' });
  const headerPadding = useBreakpointValue({ base: '2', lg: '4' });
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [isTablet] = useMediaQuery('(max-width: 960px)');
  const [isSmartPhone] = useMediaQuery('(max-width: 420px)');

  const S3_URL = process.env.REACT_APP_S3_ENDPOINT_WEB_INTERVIEW;
  const uploadedUrl = `${S3_URL}/${tenantState.tenantId}/header.jpg`;
  const [isImgInS3, setIsImgInS3] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getHeaderImage(tenantState.tenantId);
        if (result.ok) {
          setIsImgInS3(true);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [tenantState.tenantId]);

  return (
    <Box bg="white" maxW="1000px" mx="auto">
      <Flex
        h={headerHeight}
        p={headerPadding}
        justify="space-between"
        alignItems="center"
      >
        <Text
          color="gray.600"
          fontSize={
            tenantState.name.length <= 15 && (isSmartPhone || isTablet)
              ? '1.5rem'
              : tenantState.name.length <= 15
              ? '1.75rem'
              : tenantState.name.length <= 30 && isSmartPhone
              ? '1rem'
              : tenantState.name.length <= 30 && isMobile
              ? '1.25rem'
              : tenantState.name.length <= 30 && isTablet
              ? '1.5rem'
              : tenantState.name.length <= 30
              ? '1.75rem'
              : tenantState.name.length > 30 && (isSmartPhone || isMobile)
              ? '1rem'
              : tenantState.name.length > 30
              ? '1.25rem'
              : '1.25rem'
          }
          fontWeight="bold"
        >
          {tenantState.homepage ? (
            <Link href={tenantState.homepage}>{tenantState.name}</Link>
          ) : (
            tenantState.name
          )}
        </Text>
        {isSmartPhone ? (
          <IconButton
            aria-label="call"
            icon={<MdCall />}
            as="a"
            href={`tel:${tenantState.phoneNumber}`}
            color="gray.600"
            variant="outline"
          />
        ) : isMobile ? (
          <Button
            color="gray.600"
            variant="outline"
            leftIcon={<MdCall />}
            minW="100px"
          >
            <Link href={`tel:${tenantState.phoneNumber}`}>電話する </Link>
          </Button>
        ) : (
          <Text
            color="gray.600"
            fontSize={
              isTablet && tenantState.name.length > 20
                ? '1.25rem'
                : isTablet
                ? '1.5rem'
                : '1.75rem'
            }
            fontWeight="bold"
          >
            {'Tel:' + tenantState.phoneNumber}
          </Text>
        )}
      </Flex>
      {loading ? (
        <Skeleton h="243px" w="100%" />
      ) : (
        <Image
          src={isImgInS3 ? uploadedUrl : process.env.PUBLIC_URL + '/header.png'}
          alt="header"
          objectFit="cover"
          mx="auto"
          w="100%"
        />
      )}
    </Box>
  );
};
