import React, { memo, useCallback, useContext, useState } from 'react';
import { Box, Select, Text, TextProps, Input } from '@chakra-ui/react';
import { AnswerType } from '../../types/webInterview';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import { Condition } from './Condition';
import { QuestionFooter } from './QuestionFooter';
import {
  QuestionContext,
  SetQuestionContext,
} from '../../contexts/QuestionContext';
import {
  CHANGE_ANSWER_TYPE,
  CHANGE_QUESTION_TEXT,
} from '../../actions/questionAction';
import { AddChoice } from './AddChoice';
import { QuestionItems } from './QuestionItems';
import { defaultQuestion } from '../../reducers/questionReducer';
import { useAddCondition } from '../../hooks/useAddCondition';

type Props = {
  questionId: string;
  textProps: TextProps;
};

export const Question: React.VFC<Props> = memo(({ questionId, textProps }) => {
  const answerTypes: { value: AnswerType; text: string }[] = [
    {
      value: 'textbox',
      text: '記述式（テキスト）',
    },
    {
      value: 'textline',
      text: '記述式（段落）',
    },
    {
      value: 'radiobutton',
      text: '選択式（ラジオボタン）',
    },
    {
      value: 'checkbox',
      text: '選択式（チェックボックス）',
    },
    {
      value: 'combobox',
      text: '選択式（プルダウン）',
    },
  ];
  const { state: questionState } = useContext(QuestionContext);

  const { dispatch } = useContext(SetQuestionContext);
  const { text, answerType, sortNumber } =
    questionState.find((x) => x.questionId === questionId) ?? defaultQuestion;
  useAddCondition(questionId);

  const [questionTitle, setQuestionTitle] = useState(text);
  const handleChangeQuestionTitle = useCallback(
    (value: string) => {
      dispatch({
        type: CHANGE_QUESTION_TEXT,
        questionId: questionId,
        questionText: value,
      });
    },
    [dispatch, questionId],
  );
  const handleAnswerTypeChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch({
        type: CHANGE_ANSWER_TYPE,
        questionId: questionId,
        answerType: e.target.value as AnswerType,
      });
    },
    [dispatch, questionId],
  );

  return (
    <Paper px="4" py="3">
      <Box mb="3">
        <Row
          mainTitle={<Text {...textProps}>{`質問 ${sortNumber}`}</Text>}
          contents={
            <Input
              value={questionTitle}
              onChange={(e) => setQuestionTitle(e.target.value)}
              onBlur={() => handleChangeQuestionTitle(questionTitle)}
              color={textProps.color}
            />
          }
        ></Row>
      </Box>
      <Box mb="3">
        <Row
          mainTitle={<Text {...textProps}>{'形式'}</Text>}
          contents={
            <Select
              value={answerType}
              onChange={(e) => handleAnswerTypeChange(e)}
              color={textProps.color}
            >
              {answerTypes.map(({ value, text }, idx) => (
                <option value={value} key={idx}>
                  {text}
                </option>
              ))}
            </Select>
          }
        />
      </Box>
      <QuestionItems
        questionId={questionId}
        answerType={answerType}
        boxProps={{ w: textProps.w }}
        iconProps={{
          color: textProps.color,
          fontSize: textProps.fontSize,
          mt: textProps.mt,
        }}
      />
      <AddChoice questionId={questionId} />
      <Condition questionId={questionId} textProps={textProps} />
      <hr />
      <QuestionFooter questionId={questionId} />
    </Paper>
  );
});
