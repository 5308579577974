import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { Dashboard } from './pages/Dashboard/Dashboard';
import { Login } from './pages/Login';
import { InterviewSheets } from './pages/InterviewSheets/InterviewSheets';
import { InterviewSheet } from './pages/InterviewSheet/InterviewSheet';
import { InterviewResults } from './pages/InterviewResults/InterviewResults';
import { InterviewResult } from './pages/InterviewResult/InterviewResult';
import { NotFound } from './pages/NotFound';
import { ProtectedRoute } from './auth0/ProtectedRoute';
import { Loading } from './components/Loading';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';
import { InterviewSheetProvider } from './wrapper/InterviewSheetProvider';
import { QuestionProvider } from './wrapper/QuestionProvider';
import { QuestionItemProvider } from './wrapper/QuestionItemProvider';
import { TagProvider } from './wrapper/TagProvider';
import { ConditionProvider } from './wrapper/ConditionProvider';
import { PrerequisiteProvider } from './wrapper/PrerequisiteProvider';
import { InterviewResultProvider } from './wrapper/InterviewResultProvider';
import { TenantProvider } from './wrapper/TenantProvider';
import { InterviewSelect } from './pages/InterviewSelect/InterviewSelect';
import { InterviewPersonalInformation } from './pages/InterviewPersonalInformation/InterviewPersonalInformation';
import { InterviewQuestion } from './pages/InterviewQuestion/InterviewQuestion';
import { InterviewConfirm } from './pages/InterviewConfirm/InterviewConrifm';
import { InterviewComplete } from './pages/InterviewComplete/InterviewComplete';
//import { ClinicNotice } from './pages/PatientSite/ClinicNotice';
import { PatientSite } from './pages/PatientSite/PatientSite';
import { TermsOfService } from './pages/TermsOfService/TermsOfService';

export const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <ChakraProvider theme={theme}>
        <Loading />
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Switch>
        <Route exact path="/" component={Login} />
        <ProtectedRoute path="/Dashboard" component={Dashboard} />
        <ProtectedRoute
          path="/InterviewResults/:id"
          component={InterviewResult}
        />
        <ProtectedRoute path="/InterviewResults" component={InterviewResults} />
        {/* <ProtectedRoute path="/ClinicNotice" component={ClinicNotice} /> */}
        <ProtectedRoute path="/PatientSite" component={PatientSite} />
        <Route path="/TermsOfService" component={TermsOfService} exact />
        <InterviewSheetProvider>
          <QuestionProvider>
            <QuestionItemProvider>
              <TagProvider>
                <ConditionProvider>
                  <PrerequisiteProvider>
                    <InterviewResultProvider>
                      <TenantProvider>
                        <Switch>
                          <ProtectedRoute
                            path="/InterviewSheets/:id"
                            component={InterviewSheet}
                          />
                          <ProtectedRoute
                            path="/InterviewSheets"
                            component={InterviewSheets}
                          />
                          <Route
                            path="/tenants/:id/interviewSelect"
                            component={InterviewSelect}
                          />
                          <Route
                            path="/tenants/:id/interviewPersonalInformation"
                            component={InterviewPersonalInformation}
                          />
                          <Route
                            path="/tenants/:id/interviewQuestion"
                            component={InterviewQuestion}
                          />
                          <Route
                            path="/tenants/:id/interviewConfirm"
                            component={InterviewConfirm}
                          />
                          <Route
                            path="/tenants/:id/interviewComplete"
                            component={InterviewComplete}
                          />
                          <Route component={NotFound} />
                        </Switch>
                      </TenantProvider>
                    </InterviewResultProvider>
                  </PrerequisiteProvider>
                </ConditionProvider>
              </TagProvider>
            </QuestionItemProvider>
          </QuestionProvider>
        </InterviewSheetProvider>
      </Switch>
    </ChakraProvider>
  );
};
