import React, { useState, useEffect, useContext } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
  Flex,
  Box,
  TextProps,
  VStack,
  HStack,
  Text,
  useBreakpointValue,
  useDisclosure,
  useToast,
  useBoolean,
  CircularProgress,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from '../../components/Layout/Layout';
import { Menu } from './Menu';
import { Title } from './Title';
import { Description } from './Description';
import { ReleaseDate } from './ReleaseDate';
import { Questions } from './Questions';
import { PersonalInformation } from './PersonalInformation';
import {
  GetInterviewSheet,
  PostInterviewSheet,
  PutInterviewSheet,
} from '../../types/interviewSheetApiTypes';
import { SetInterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { SetQuestionContext } from '../../contexts/QuestionContext';
import { SetQuestionItemContext } from '../../contexts/QuestionItemContext';
import { SetConditionContext } from '../../contexts/ConditionContext';
import { SetPrerequisiteContext } from '../../contexts/PrerequisiteContext';
import { GET_INTERVIEWSHEET } from '../../actions/interviewSheetAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_QUESTION } from '../../actions/questionAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_QUESTION_ITEM } from '../../actions/questionItemAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_CONDITION } from '../../actions/conditionAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_PREREQUISITE } from '../../actions/prerequisiteAction';
import { toInterviewSheet } from '../../domains/toInterviewSheets';
import { toQuestions } from '../../domains/toQuestions';
import { toQuestionItems } from '../../domains/toQuestionItems';
import { toConditions } from '../../domains/toConditions';
import { toPrerequisite } from '../../domains/toPrerequisite';
import { TagEditModalDialog } from './TagEditModalDialog';
import { PreviewModalDialog } from './PreviewModalDialog';
import { useInterviewSheet } from '../../hooks/useInterviewSheet';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import {
  getInterviewSheet,
  postInterviewSheet,
  putInterviewSheet,
} from '../../api';
import { Authorization } from '../../components/Authorization';
import { PATHNAME } from '../../constant/constant';

type Props = {};

export const InterviewSheet: React.VFC<Props> = () => {
  const isTablet = useBreakpointValue({ base: false, md: true });
  const textProps: TextProps = {
    color: 'gray.600',
    fontSize: 'lg',
    mt: '2',
    align: 'left',
  };
  const [questionOpened, setQuestionOpened] = useState(true);
  const { dispatch: interviewSheetDispatch } = useContext(
    SetInterviewSheetContext,
  );
  const { dispatch: questionDispatch } = useContext(SetQuestionContext);
  const { dispatch: questionItemDispatch } = useContext(SetQuestionItemContext);
  const { dispatch: conditionDispatch } = useContext(SetConditionContext);
  const { dispatch: prerequisiteDispatch } = useContext(SetPrerequisiteContext);
  const {
    isOpen: tagEditIsOpen,
    onOpen: tagEditOnOpen,
    onClose: tagEditOnClose,
  } = useDisclosure();
  const {
    isOpen: previewIsOpen,
    onOpen: previewOnOpen,
    onClose: previewOnClose,
  } = useDisclosure();
  const toast = useToast();
  const [save, setSave] = useBoolean(false);
  const history = useHistory();

  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const { id } = useParams<{ id: string }>();
  const [loading, setLoading] = useState(false);
  const sheetData = useInterviewSheet(id);
  const isNew = 'id' in sheetData;

  const handleSave = async () => {
    setLoading(true);
    const userId = user?.sub ?? '';
    const userName = encodeURI(user?.name ?? '');
    const accessToken = await getAccessTokenSilently({
      audience: 'https://www.webinterview.mic.jp/',
      scope: isNew ? 'create:interview-sheets' : 'update:interview-sheets',
    });
    const saveData = async (): Promise<void> => {
      try {
        const result = isNew
          ? await postInterviewSheet(
              tenantId,
              userId,
              userName,
              accessToken,
              sheetData as PostInterviewSheet,
            )
          : await putInterviewSheet(
              tenantId,
              id,
              userId,
              userName,
              accessToken,
              sheetData as PutInterviewSheet,
            );
        if (result.ok) {
          toast({
            title: '保存完了',
            description: isNew
              ? '新規作成した問診票を保存しました'
              : '問診票の変更内容を保存しました',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          if (isNew) {
            history.push('/interviewsheets');
          }
          setSave.toggle();
        } else {
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    saveData();
  };
  const menu = (
    <Menu
      questionOpened={questionOpened}
      setQuestionOpened={setQuestionOpened}
      tagEditOnOpen={tagEditOnOpen}
      previewOnOpen={previewOnOpen}
      saveInterviewSheet={handleSave}
    />
  );
  useEffect(() => {
    const getData = async (): Promise<void> => {
      if (isNew) return;
      try {
        setLoading(true);
        const result = await getInterviewSheet(tenantId, id);
        if (result.ok) {
          const data: GetInterviewSheet = await result.json();
          interviewSheetDispatch({
            type: GET_INTERVIEWSHEET,
            data: toInterviewSheet(data),
          });
          questionDispatch({
            type: GET_INTERVIEWSHEET_QUESTION,
            data: toQuestions(data),
          });
          questionItemDispatch({
            type: GET_INTERVIEWSHEET_QUESTION_ITEM,
            data: toQuestionItems(data),
          });
          conditionDispatch({
            type: GET_INTERVIEWSHEET_CONDITION,
            data: toConditions(data),
          });
          prerequisiteDispatch({
            type: GET_INTERVIEWSHEET_PREREQUISITE,
            data: toPrerequisite(data),
          });
        } else {
          history.push('/interviewsheets');
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [
    tenantId,
    id,
    interviewSheetDispatch,
    questionDispatch,
    questionItemDispatch,
    conditionDispatch,
    prerequisiteDispatch,
    save,
    isNew,
    history,
    toast,
  ]);

  return (
    <>
      <Layout>
        <Authorization pathName={PATHNAME.INTERVIEW_SHEET}>
          {loading ? (
            <CircularProgress isIndeterminate color="blue.300" />
          ) : (
            <>
              <Flex w="100%" justify="center" mx={isTablet ? '3' : '0'}>
                <Box flex={1}>
                  <Box minW="full" mb="3">
                    <Title {...textProps} />
                  </Box>
                  <Box minW="full" mb="3">
                    <Description {...textProps} />
                  </Box>
                  <Box minW="full" mb="3">
                    <ReleaseDate {...textProps} />
                  </Box>
                  <Box minW="full" mb="3">
                    <PersonalInformation {...textProps} />
                  </Box>
                  <Questions
                    questionOpened={questionOpened}
                    textProps={textProps}
                  />
                </Box>
                {isTablet ? (
                  <Box w="154px" position="relative">
                    <Box>
                      <VStack spacing={5} position="fixed" ml="10">
                        {menu}
                      </VStack>
                    </Box>
                  </Box>
                ) : (
                  <></>
                )}
              </Flex>
              {!isTablet ? (
                <Box
                  w="full"
                  h="85px"
                  position="fixed"
                  bottom="0"
                  bg="gray.50"
                  margin="auto"
                >
                  <hr />
                  <HStack justify="center" minH="24">
                    {menu}
                  </HStack>
                </Box>
              ) : (
                <></>
              )}
            </>
          )}
        </Authorization>
      </Layout>
      <TagEditModalDialog isOpen={tagEditIsOpen} onClose={tagEditOnClose} />
      <PreviewModalDialog isOpen={previewIsOpen} onClose={previewOnClose} />
    </>
  );
};
