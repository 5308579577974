import React from 'react';
import { IconButtonProps, Text, TextProps, VStack } from '@chakra-ui/react';
import { ICON_BUTTON } from '../atoms/IconButton';

type Props = {
  text: string;
  onClick: () => void;
  iconButtonProps: IconButtonProps;
  textProps: TextProps;
};

export const IconButtonWithText: React.VFC<Props> = ({
  text,
  onClick,
  iconButtonProps,
  textProps,
}) => {
  return (
    <VStack>
      <ICON_BUTTON isRound onClick={onClick} {...iconButtonProps}></ICON_BUTTON>
      <Text {...textProps}>{text}</Text>
    </VStack>
  );
};
