import React from 'react';
import {
  Box,
  Heading,
  BoxProps,
  List,
  ListItem,
  OrderedList,
  Text,
  Image,
  Button,
  Flex,
} from '@chakra-ui/react';
import { Copyright } from '../../components/Layout/Copyright';

type Props = {};

export const TermsOfService: React.VFC<Props> = () => {
  return (
    <Box bg="gray.50" minH="100vH" py="10">
      <Box maxW="1000px" mx={{ base: '3', md: 'auto' }}>
        <Box bg="white" borderRadius="lg" boxShadow="lg" py="5" px="7">
          <Heading
            color="gray.600"
            size="md"
            textAlign="center"
            my="5"
            pb="5"
            borderBottomWidth="5px"
            borderBottomColor="gray.50"
            borderBottomStyle="dotted"
          >
            問診票システム「Web 問診票」利用規約
          </Heading>
          <Box>
            <Section boxProps={{ mt: '0' }}>
              <Text>
                本規約は、株式会社ミック（以下、「当社」）が提供する、本サービス（第１条第１号に定義）に関わる、利用者（第１条第４号に定義）と当社との間の権利義務関係を定めるものです。
              </Text>
              <Text>
                本サービスを利用するためには、本規約の全てに同意していただく必要があり、本サービスを利用したときは、本規約に同意したものとみなされるものとします。
              </Text>
            </Section>
            <Section headLine="第１条（定義）">
              <Text>
                本規約上で使用する用語の定義は、次に掲げるとおりとします。
              </Text>
              <OrderedList pl="3">
                <ListItem>
                  本サービス
                  <List>
                    <ListItem>
                      当社が運営する問診票システム「Web
                      問診票」サービス及びこれに附帯するサービスを意味します
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  本サイト
                  <List>
                    <ListItem>
                      本サービスのコンテンツ（問診票システム）が掲載されたウェブサイト
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  提携先
                  <List>
                    <ListItem>
                      本サービスを利用している医療機関（歯科医院や病院等）や施設
                    </ListItem>
                  </List>
                </ListItem>
                <ListItem>
                  利用者
                  <List>
                    <ListItem>
                      本サービスを利用している提携先に問診票の提出を行う者
                    </ListItem>
                  </List>
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第２条（本規約への同意）">
              <OrderedList pl="3">
                <ListItem>
                  利用者は、本規約に同意頂いた上で、本サービスを利用できるものとします。
                </ListItem>
                <ListItem>
                  利用者が、本サイトを PC
                  のブラウザやスマートフォンその他の情報端末で表示し、本規約への同意手続を行った時点で、利用者と当社との間で、本規約の諸規定に従った利用契約が成立するものとします。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第３条（問診票サービス）">
              <OrderedList pl="3">
                <ListItem>
                  本サービスでは、本サイトを介して問診票を入力することができるサービスを有します。
                </ListItem>
                <ListItem>
                  本サービスによる問診票の入力後、提携先の確認その他当社所定の手続きを経ることが必要になります。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第４条（規約の変更）">
              <OrderedList pl="3">
                <ListItem>
                  当社は、民法第 548 条の 4
                  の規定により本規約の変更をすることができます。
                </ListItem>
                <ListItem>
                  当社は、本規約を変更する場合、変更の内容及び効力発生時期を明示し、その効力発生日の相当期間前までに、当社所定の方法により利用者に通知します。
                </ListItem>
                <ListItem>
                  第 1
                  項による規約の変更に同意しない本サービス利用者は、当社所定の方法に従い、効力発生日までに本契約を解除することができるものとします。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第５条（個人情報等の取り扱い）">
              <Text>
                個人情報及び利用者情報については、当社が別途定める『「問診票システム「Web
                問診票」サービス」プライバシーポリシー』に則り、適正に取り扱うこととします。
              </Text>
            </Section>
            <Section headLine="第６条（禁止行為）">
              <Text>
                本サービスの利用に際し、当社は、利用者に対し次に掲げる行為を禁止します。当社において、利用者が禁止事項に違反したと認めた場合、利用の一時停止やその他当社が必要と判断した措置を取ることができます。
              </Text>
              <OrderedList pl="3">
                <ListItem>当社または第三者の知的財産権を侵害する行為</ListItem>
                <ListItem>
                  当社または第三者の財産、プライバシー等を侵害する行為
                </ListItem>
                <ListItem>
                  当社または第三者の名誉・信用を毀損または不当に差別もしくは誹謗中傷する行為
                </ListItem>
                <ListItem>犯罪行為に結びつくような行為</ListItem>
                <ListItem>
                  利用する意思がないにもかかわらず、本サービスを利用する行為
                </ListItem>
                <ListItem>
                  当社のサーバー等のコンピュータに不正にアクセスしたり、有害なコンピュータプログラムや有害なメール等を送信または書き込む行為
                </ListItem>
                <ListItem>上記の他、当社が不適切と判断する行為</ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第７条（損害賠償）">
              <Text>
                利用者は、本契約の定めに違反して、当社または提携先に損害を与えた場合には、直接かつ現実に発生した損害を全額賠償しなければならないものとします。
              </Text>
            </Section>
            <Section headLine="第８条（免責）">
              <OrderedList pl="3">
                <ListItem>
                  当社は、本サービスの内容変更、中断、終了によって生じたいかなる損害についても、一切責任を負いません。
                </ListItem>
                <ListItem>
                  当社は、利用者と提携先の間で生じたいかなる損害についても、一切責任を負いません。
                </ListItem>
                <ListItem>
                  当社は、利用者の本サービスの利用環境について一切関与せず、また一切の責任を負いません。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第９条（準拠法、管轄裁判所）">
              <OrderedList pl="3">
                <ListItem>
                  本規約の有効性、解釈及び履行については、日本法に準拠し、日本法に従って解釈されるものとします。
                </ListItem>
                <ListItem>
                  当社と利用者等との間での論議・訴訟その他一切の紛争については、訴額に応じて、東京簡易裁判所又は東京地方裁判所を専属的合意管轄裁判所とします。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第１０条（サービスの終了）">
              <OrderedList pl="3">
                <ListItem>
                  利用者は、当社に対し、以下の各号の事項を確約します。
                </ListItem>
                <ListItem>
                  当社は、本サービスが原因を問わず終了した場合、これに起因して生じた利用者または第三者が被った損害について、一切責任を負わないものとします。
                </ListItem>
              </OrderedList>
            </Section>
            <Section headLine="第１１条（反社会的勢力の排除）">
              <OrderedList pl="3">
                <ListItem>
                  利用者は、当社に対し、以下の各号の事項を確約します。
                </ListItem>
                <OrderedList>
                  <ListItem>
                    自らが、暴力団、暴力団関係企業、総会屋もしくはこれらに準ずる者または構成員（以下「反社会的勢力」といいます。）でないこと。
                  </ListItem>
                  <ListItem>
                    反社会的勢力に自己の名義を利用させ、または反社会的勢力の利益に供するために本サービスを利用するものでないこと。
                  </ListItem>
                  <ListItem>
                    自らまたは第三者を利用して、当社に対して脅迫的な言動または暴力を用いる行為や偽計または威力を用いて当社の業務を妨害しまたは信用を毀損する行為を行わないこと。
                  </ListItem>
                </OrderedList>
                <ListItem>
                  当社は、利用者が前項に違反した場合、当該利用者に何らかの通知または催告なく、契約の解除その他本規約に定める措置を講じることができるものとします。
                </ListItem>
                <ListItem>
                  前項の措置により発生した一切の損害について、当社は損害賠償、補償その他の責任を負いません。また、利用者は、第１項に違反したことにより当社に生じた損害等を賠償するものとします。
                </ListItem>
              </OrderedList>
            </Section>
            <Box color="gray.600" textAlign="right" pt="10" pb="3">
              2023年10月01日 施行
            </Box>
          </Box>
        </Box>
        <Flex justifyContent="center" pt="10">
          <Button colorScheme="webInterview" onClick={window.close}>
            閉じる
          </Button>
        </Flex>
        <Box pt="10" pb="5">
          <Image
            src="https://bws.mic.jp/wp-content/uploads/2021/05/logo.png"
            alt="mic logo"
          />
          <Copyright alignSelf={{ base: 'center', sm: 'start' }} />
        </Box>
      </Box>
    </Box>
  );
};

type SectionProps = {
  boxProps?: BoxProps;
  detailProps?: BoxProps;
  headLine?: string;
  children: React.ReactNode;
};

const Section: React.VFC<SectionProps> = ({
  boxProps,
  detailProps,
  headLine,
  children,
}) => {
  return (
    <Box mt="10" {...boxProps}>
      <section>
        <Heading size="sm" color="gray.600" pb="2">
          {headLine}
        </Heading>
        <Box color="gray.600" lineHeight="2" {...detailProps}>
          {children}
        </Box>
      </section>
    </Box>
  );
};
