import { AgeOperator } from '../types/webInterview';
import { Condition } from '../types/condition';

export const ADD_CONDITION = 'ADD_CONDITION';
export const REMOVE_CONDITION = 'REMOVE_CONDITION';
export const REMOVE_CONDITIONS = 'REMOVE_CONDITIONS';
export const CHANGE_AGE_REQUIRED = 'CHANGE_AGE_REQUIRED';
export const CHANGE_AGE = 'CHANGE_AGE';
export const CHANGE_AGE_OPERATOR = 'CHANGE_AGE_OPERATOR';
export const CHANGE_SEX_REQUIRED = 'CHANGE_SEX_REQUIRED';
export const CHANGE_SEX = 'CHANGE_SEX';
export const CHANGE_QUESTION_REQUIRED = 'CHANGE_QUESTION_REQUIRED';
export const CHANGE_PREREQUISITE_QUESTION_ID =
  'CHANGE_PREREQUISITE_QUESTION_ID';
export const GET_INTERVIEWSHEET = 'GET_INTERVIEWSHEET';

export type ConditionAction =
  | {
      type: 'ADD_CONDITION';
      questionId: string;
    }
  | {
      type: 'REMOVE_CONDITION';
      conditionId: string;
    }
  | {
      type: 'REMOVE_CONDITIONS';
    }
  | { type: 'CHANGE_AGE_REQUIRED'; conditionId: string }
  | { type: 'CHANGE_AGE'; conditionId: string; age: number }
  | {
      type: 'CHANGE_AGE_OPERATOR';
      conditionId: string;
      ageOperator: AgeOperator;
    }
  | { type: 'CHANGE_SEX_REQUIRED'; conditionId: string }
  | { type: 'CHANGE_SEX'; conditionId: string }
  | { type: 'CHANGE_QUESTION_REQUIRED'; conditionId: string }
  | {
      type: 'CHANGE_PREREQUISITE_QUESTION_ID';
      conditionId: string;
      questionId: string;
    }
  | { type: 'GET_INTERVIEWSHEET'; data: Condition[] };
