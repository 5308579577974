import React, { useReducer } from 'react';
import {
  PrerequisiteContext,
  SetPrerequisiteContext,
} from '../contexts/PrerequisiteContext';
import {
  prerequisiteReducer,
  initialState,
} from '../reducers/prerequisiteReducer';

type Props = {
  children: React.ReactNode;
};

export const PrerequisiteProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(prerequisiteReducer, initialState);
  return (
    <PrerequisiteContext.Provider value={{ state }}>
      <SetPrerequisiteContext.Provider value={{ dispatch }}>
        {children}
      </SetPrerequisiteContext.Provider>
    </PrerequisiteContext.Provider>
  );
};
