import { GetInterviewSheet } from '../types/interviewSheetApiTypes';
import { Prerequisite } from '../types/prerequisite';

export const toPrerequisite = (data: GetInterviewSheet) => {
  return data.questions
    .map((q) =>
      q.condition.prerequisite_items.map<Prerequisite>((pi) => ({
        prerequisiteId: pi.id,
        conditionId: q.condition.id,
        questionItemId: pi.question_item_id,
        questionId: q.id,
      })),
    )
    .flat();
};
