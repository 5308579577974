import React, { useContext } from 'react';
import { Button } from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { InterviewResultContext } from '../contexts/InterviewResultContext';

type Props = {
  pageNumber: number;
};

export const StepperMobile: React.VFC<Props> = ({ pageNumber }) => {
  const history = useHistory();
  const { id: tenantId } = useParams<{ id: string }>();
  const { state } = useContext(InterviewResultContext);
  const steps: {
    pageNumber: number;
    title: string;
    handleClick: () => void;
  }[] = [
    {
      pageNumber: 1,
      title: state[0]?.interviewSheetName ?? '問診票選択',
      handleClick: () => history.push(`/tenants/${tenantId}/interviewSelect`),
    },
    {
      pageNumber: 2,
      title: '個人情報入力',
      handleClick: () =>
        history.push(
          `/tenants/${tenantId}/interviewPersonalInformation?interviewSheetId=${state[0]?.interviewSheetId}`,
        ),
    },
    {
      pageNumber: 3,
      title: '問診票入力',
      handleClick: () => history.push(`/tenants/${tenantId}/interviewQuestion`),
    },
  ];
  return (
    <>
      {steps
        .filter((x) => x.pageNumber < pageNumber)
        .map(({ title, handleClick }, idx) => (
          <Button
            w="full"
            colorScheme="webInterview"
            mb="3"
            onClick={handleClick}
            key={idx}
          >
            {title}
          </Button>
        ))}
    </>
  );
};
