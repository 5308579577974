import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { Box, HStack, useBreakpointValue } from '@chakra-ui/react';
import { EntryLayout } from '../../components/Layout/EntryLayout';
import { ClinicInformation } from '../../components/ClinicInformation';
import { Stepper } from '../../components/Stepper';
import { StepperMobile } from '../../components/StepperMobile';
import { Notice } from '../../components/Notice';
import { NoticeMobile } from '../../components/NoticeMobile';

import { getInterviewSheet } from '../../api/index';
import { GetInterviewSheet } from '../../types/interviewSheetApiTypes';

import { SetInterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { SetQuestionContext } from '../../contexts/QuestionContext';
import { SetQuestionItemContext } from '../../contexts/QuestionItemContext';
import { SetConditionContext } from '../../contexts/ConditionContext';
import { SetPrerequisiteContext } from '../../contexts/PrerequisiteContext';
import { SetInterviewResultContext } from '../../contexts/InterviewResultContext';

import { GET_INTERVIEWSHEET } from '../../actions/interviewSheetAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_QUESTION } from '../../actions/questionAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_QUESTION_ITEM } from '../../actions/questionItemAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_CONDITION } from '../../actions/conditionAction';
import { GET_INTERVIEWSHEET as GET_INTERVIEWSHEET_PREREQUISITE } from '../../actions/prerequisiteAction';
import { ADD_INTERVIEW_RESULT } from '../../actions/interviewResultAction';

import { newId } from '../../domains/newId';
import { toInterviewSheet } from '../../domains/toInterviewSheets';
import { toQuestions } from '../../domains/toQuestions';
import { toQuestionItems } from '../../domains/toQuestionItems';
import { toConditions } from '../../domains/toConditions';
import { toPrerequisite } from '../../domains/toPrerequisite';

import { PersonalInformation } from './PersonalInformation';
import { ErrorCheck } from '../../components/ErrorCheck';

export const InterviewPersonalInformation: React.VFC = () => {
  const { dispatch: interviewSheetDispatch } = useContext(
    SetInterviewSheetContext,
  );
  const { dispatch: questionDispatch } = useContext(SetQuestionContext);
  const { dispatch: questionItemDispatch } = useContext(SetQuestionItemContext);
  const { dispatch: conditionDispatch } = useContext(SetConditionContext);
  const { dispatch: prerequisiteDispatch } = useContext(SetPrerequisiteContext);
  const { dispatch: interviewResultDispatch } = useContext(
    SetInterviewResultContext,
  );

  const { id } = useParams<{ id: string }>();
  const tenantId: string = (() => {
    try {
      return atob(id.replace(/\r?\n/g, ''));
    } catch (ignored) {
      console.log(ignored);
      return '';
    }
  })();
  const { search } = useLocation();
  const interviewSheetId = (() => {
    const query = new URLSearchParams(search);
    return query.get('interviewSheetId') ?? '';
  })();

  const [loading, setLoading] = useState(true);
  const getSheetData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);

      const result = await getInterviewSheet(tenantId, interviewSheetId);
      if (result.ok) {
        const data: GetInterviewSheet = await result.json();
        interviewSheetDispatch({
          type: GET_INTERVIEWSHEET,
          data: toInterviewSheet(data),
        });
        questionDispatch({
          type: GET_INTERVIEWSHEET_QUESTION,
          data: toQuestions(data),
        });
        questionItemDispatch({
          type: GET_INTERVIEWSHEET_QUESTION_ITEM,
          data: toQuestionItems(data),
        });
        conditionDispatch({
          type: GET_INTERVIEWSHEET_CONDITION,
          data: toConditions(data),
        });
        prerequisiteDispatch({
          type: GET_INTERVIEWSHEET_PREREQUISITE,
          data: toPrerequisite(data),
        });
        interviewResultDispatch({
          type: ADD_INTERVIEW_RESULT,
          interviewResultId: newId('InterviewSheetResult'),
          interviewSheetId,
          interviewSheetHistoryId: data.latest_history_id,
          interviewSheetName: data.name,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [
    tenantId,
    interviewSheetId,
    interviewSheetDispatch,
    questionDispatch,
    questionItemDispatch,
    conditionDispatch,
    prerequisiteDispatch,
    interviewResultDispatch,
  ]);

  useEffect(() => {
    getSheetData();
  }, [getSheetData]);

  const px = useBreakpointValue({ base: 2, lg: 0 });
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <ErrorCheck tenantId={tenantId}>
      <EntryLayout>
        <Box mb="4">
          {isMobile ? (
            <NoticeMobile tenantId={tenantId} />
          ) : (
            <Notice tenantId={tenantId} />
          )}
        </Box>
        {isMobile ? (
          <Box px={px}>
            <StepperMobile pageNumber={2} />
            <PersonalInformation
              loading={loading}
              tenantId={tenantId}
              interviewSheetId={interviewSheetId}
            />
            <ClinicInformation tenantId={tenantId} />
          </Box>
        ) : (
          <HStack spacing="3" align="start">
            <Box w="315px">
              <Stepper pageNumber={2} />
              <ClinicInformation tenantId={tenantId} />
            </Box>
            <Box w="full">
              <PersonalInformation
                loading={loading}
                tenantId={tenantId}
                interviewSheetId={interviewSheetId}
              />
            </Box>
          </HStack>
        )}
      </EntryLayout>
    </ErrorCheck>
  );
};
