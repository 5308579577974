import React, { useContext } from 'react';
import { Box, Flex, Icon } from '@chakra-ui/react';
import { MdPostAdd } from 'react-icons/md';
import { Row } from './Row';
import { InputWithAffix } from './InputWithAffix';
import { QuestionItemContext } from '../../contexts/QuestionItemContext';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  questionItemId: string;
};

export const ChoiceNote: React.VFC<Props> = ({ questionItemId }) => {
  const { state } = useContext(QuestionItemContext);
  const {
    hasChoiceNotes,
    choiceNotesPrefix,
    choiceNotesSuffix,
    choicePlaceholder,
  } =
    state.find((qi) => qi.questionItemId === questionItemId) ??
    defaultQuestionItem;
  return hasChoiceNotes ? (
    <Box mb="3">
      <Row
        contents={
          <Flex>
            <Icon as={MdPostAdd} mt="1.5" mr="1" />
            <InputWithAffix
              prefix={choiceNotesPrefix}
              suffix={choiceNotesSuffix}
              disabled={true}
              inputGroupProps={{ size: 'sm' }}
              placeholder={choicePlaceholder}
            />
          </Flex>
        }
      />
    </Box>
  ) : (
    <></>
  );
};
