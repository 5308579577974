import React, { useContext } from 'react';
import {
  Box,
  Circle,
  Flex,
  Heading,
  HStack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdDescription } from 'react-icons/md';
import { useHistory, useParams } from 'react-router-dom';
import { EntryLayout } from '../../components/Layout/EntryLayout';
import { Paper } from '../../components/atoms/Paper';
import { InterviewResultContext } from '../../contexts/InterviewResultContext';
import { InterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { ClinicInformation } from '../../components/ClinicInformation';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import { InterviewQuestionForm } from './InterviewQuestionForm';
import { defaultInterviewResult } from '../../reducers/interviewResultReducer';
import { Stepper } from '../../components/Stepper';
import { StepperMobile } from '../../components/StepperMobile';
import { ReEnter } from '../ReEnter';
import { ErrorCheck } from '../../components/ErrorCheck';

type Props = {};

export const InterviewQuestion: React.VFC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const tenantId: string = (() => {
    try {
      return atob(id.replace(/\r?\n/g, ''));
    } catch (ignored) {
      console.log(ignored);
      return '';
    }
  })();
  const px = useBreakpointValue({ base: 2, lg: 0 });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const history = useHistory();
  const { state: resultState } = useContext(InterviewResultContext);
  const { interviewSheetId } = resultState[0] ?? defaultInterviewResult;
  const { state: sheetState } = useContext(InterviewSheetContext);
  const { name } =
    sheetState.find((r) => r.interviewSheetId === interviewSheetId) ??
    defaultInterviewSheet;

  const Interview = () => {
    return (
      <Paper w="full" p="4" mb="4">
        <Heading size="md" mb="4">
          <Flex align="center">
            <Circle color="gray.600" bg="gray.600" size="30px" mr="3">
              <MdDescription size="24px" color="white" />
            </Circle>
            <Text color="gray.600">{name}</Text>
          </Flex>
        </Heading>
        <InterviewQuestionForm
          handleNext={() => history.push(`/tenants/${id}/interviewConfirm`)}
          handlePrevious={() => history.goBack()}
        />
      </Paper>
    );
  };

  return (
    <ErrorCheck tenantId={tenantId}>
      {!interviewSheetId ? (
        <ReEnter />
      ) : (
        <EntryLayout>
          {isMobile ? (
            <Box px={px}>
              <StepperMobile pageNumber={3} />
              <Interview />
              <ClinicInformation tenantId={tenantId} />
            </Box>
          ) : (
            <HStack spacing="3" align="start">
              <Box w="315px">
                <Stepper pageNumber={3} />
                <ClinicInformation tenantId={tenantId} />
              </Box>
              <Box w="full">
                <Interview />
              </Box>
            </HStack>
          )}
        </EntryLayout>
      )}
    </ErrorCheck>
  );
};
