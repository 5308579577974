import React from 'react';
import { Box, Text, Image } from '@chakra-ui/react';
import { Copyright } from '../components/Layout/Copyright';
import { Paper } from '../components/atoms/Paper';

export const NotFound: React.VFC = () => {
  return (
    <Box bg="gray.50" w="full" h="100vH" pt="10">
      <Box maxW="1000px" mx={{ base: '4', xl: 'auto' }}>
        <NotFoundContent />
        <Box my="5">
          <Image
            src="https://bws.mic.jp/wp-content/uploads/2021/05/logo.png"
            alt="mic logo"
            mb="2"
          />
          <Copyright />
        </Box>
      </Box>
    </Box>
  );
};

export const NotFoundContent: React.VFC = () => {
  return (
    <Paper>
      <Box textAlign="center">
        <Box
          w="100px"
          h="100px"
          borderRadius="full"
          bg="white"
          borderWidth="10px"
          borderStyle="solid"
          borderColor="blue.200"
          display="inline-block"
          textAlign="center"
          m="10"
        >
          <Text color="gray.600" fontSize="53" fontWeight="bold">
            ？
          </Text>
        </Box>
        <Text color="gray.600" fontSize="2xl" mb="4">
          お探しのページは見つかりませんでした
        </Text>
        <Text color="gray.600" mb="10" fontSize="sm">
          リンクに問題があるか、ページが削除された可能性があります
        </Text>
      </Box>
    </Paper>
  );
};
