import React, { useContext, memo } from 'react';
import { Box, TextProps } from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { Question } from './Question';
import { ClosedQuestion } from './ClosedQuestion';
import {
  QuestionContext,
  SetQuestionContext,
} from '../../contexts/QuestionContext';
import { CHANGE_QUESTION_SORT_NUMBER } from '../../actions/questionAction';
import { reorder } from '../../utils/reorder';

type Props = {
  questionOpened: boolean;
  textProps: TextProps;
};

export const Questions: React.VFC<Props> = memo(
  ({ questionOpened, textProps }) => {
    const { state } = useContext(QuestionContext);
    const { dispatch } = useContext(SetQuestionContext);

    const questions = [...state].sort(
      (preview, next) => preview.sortNumber - next.sortNumber,
    );

    const handleOnDragEnd = ({ source, destination }: DropResult) => {
      if (!destination) {
        return;
      }
      reorder(questions, source.index, destination.index).forEach(
        (item, idx) => {
          const questionId = item.questionId;
          const sortNumber = idx + 1;

          dispatch({
            type: CHANGE_QUESTION_SORT_NUMBER,
            questionId,
            sortNumber,
          });
        },
      );
    };
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ) => ({
      background: isDragging ? '#EBF8FF' : 'white',
      ...draggableStyle,
    });

    return (
      <>
        {questionOpened ? (
          <>
            {questions.map(({ questionId }, idx) => (
              <Box minW="full" mb="3" key={idx}>
                <Question
                  questionId={questionId}
                  textProps={textProps}
                  key={questionId}
                />
              </Box>
            ))}
          </>
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questions">
              {(provided) => (
                <Box
                  className="questions"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {questions.map(({ questionId }, idx) => (
                    <Draggable
                      key={questionId}
                      draggableId={questionId}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <Box
                          key={questionId}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <Box minW="full" mb="3" key={idx}>
                            <ClosedQuestion
                              questionId={questionId}
                              isDragging={snapshot.isDragging}
                              textProps={textProps}
                              key={questionId}
                            />
                          </Box>
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    );
  },
);
