import { GetInterviewSheet } from '../types/interviewSheetApiTypes';
import { QuestionItem } from '../types/questionItem';

export const toQuestionItems = (data: GetInterviewSheet) => {
  return data.questions
    .map((q) =>
      q.question_items.map<QuestionItem>((qi) => ({
        questionItemId: qi.id,
        choiceText: qi.choice_text,
        hasChoiceNotes: qi.has_choice_notes,
        choiceNotesPrefix: qi.choice_notes_prefix,
        choiceNotesSuffix: qi.choice_notes_suffix,
        choicePlaceholder: qi.choice_placeholder,
        descriptionPrefix: qi.description_notes_prefix,
        descriptionSuffix: qi.description_notes_suffix,
        descriptionPlaceholder: qi.description_placeholder,
        sortNumber: qi.sort_number,
        tags: qi.tags.map((t) => t.id),
        questionId: q.id,
      })),
    )
    .flat();
};
