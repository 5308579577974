import React, { SetStateAction, useState, useEffect, useRef } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  Radio,
  RadioGroup,
  Text,
  Select,
  Stack,
  CircularProgress,
  FormControl,
  Spacer,
  IconButton,
  useMediaQuery,
  Tooltip,
} from '@chakra-ui/react';
import { ModalDialog } from '../../components/ModalDialog';
import { FilterValue, findByKey } from './InterviewResults';
import { dateConvert } from '../../utils/dateConvert';
import { getInterviewSheets } from '../../api/index';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { GetInterviewSheets } from '../../types/interviewSheetApiTypes';
import { MdClear, MdInfoOutline } from 'react-icons/md';
import { useBrowserDetect } from '../../hooks/useBrowserDetect';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  isToday: boolean;
  setIsToday: React.Dispatch<SetStateAction<boolean>>;
  filterValues: FilterValue[];
  setFilterValues: React.Dispatch<SetStateAction<FilterValue[]>>;
};

export const FilterModal: React.VFC<Props> = ({
  isOpen,
  onClose,
  isToday,
  setIsToday,
  filterValues,
  setFilterValues,
}) => {
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const [isSmallMonitor] = useMediaQuery('(max-width: 420px)');
  const dateWidth = isSmallMonitor
    ? { w: 'full' }
    : { minW: '120px', maxW: '160px' };

  const extractTime = (value: string) =>
    `${('00' + new Date(value).getHours()).slice(-2)}:${(
      '00' + new Date(value).getMinutes()
    ).slice(-2)}`;

  const today = dateConvert(new Date().toString(), 'date');
  const [isTodayLocal, setIsTodayLocal] = useState(isToday);
  const [beginReservationDate, setBeginReservationDate] = useState(
    findByKey(filterValues, 'begin-reservation-date')
      ? dateConvert(findByKey(filterValues, 'begin-reservation-date'), 'date')
      : '',
  );
  const [endReservationDate, setEndReservationDate] = useState(
    findByKey(filterValues, 'end-reservation-date')
      ? dateConvert(findByKey(filterValues, 'end-reservation-date'), 'date')
      : '',
  );
  const [beginReservationTime, setBeginReservationTime] = useState(
    findByKey(filterValues, 'begin-reservation-date')
      ? extractTime(findByKey(filterValues, 'begin-reservation-date'))
      : '00:00',
  );
  const [endReservationTime, setEndReservationTime] = useState(
    findByKey(filterValues, 'end-reservation-date')
      ? extractTime(findByKey(filterValues, 'end-reservation-date'))
      : '23:59',
  );
  const [cardId, setCardId] = useState(findByKey(filterValues, 'card-id'));
  const [name, setName] = useState(findByKey(filterValues, 'name'));
  const [kana, setKana] = useState(findByKey(filterValues, 'kana'));
  const [birthDay, setBirthDay] = useState(findByKey(filterValues, 'birthday'));
  const [phoneNumber, setPhoneNumber] = useState(
    findByKey(filterValues, 'phone-number'),
  );
  const [mailAddress, setMailAddress] = useState(
    findByKey(filterValues, 'mail-address'),
  );
  const [status, setStatus] = useState(findByKey(filterValues, 'status'));
  const [sheet, setSheet] = useState(findByKey(filterValues, 'sheet'));
  const [beginCreatedAt, setBeginCreatedAt] = useState(
    findByKey(filterValues, 'begin-created-at'),
  );
  const [endCreatedAt, setEndCreatedAt] = useState(
    findByKey(filterValues, 'end-created-at'),
  );
  const [beginUpdatedAt, setBeginUpdatedAt] = useState(
    findByKey(filterValues, 'begin-updated-at'),
  );
  const [endUpdatedAt, setEndUpdatedAt] = useState(
    findByKey(filterValues, 'end-updated-at'),
  );
  const [sex, setSex] = useState(findByKey(filterValues, 'sex'));
  const [deleted, setDeleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isProcessed, setIsProcessed] = useState(false);
  const [sheets, setSheets] = useState<{ id: string; title: string }[]>([
    { id: '', title: '---' },
  ]);

  const beginReservationInvalid =
    Boolean(beginReservationDate.length > 0) !==
    Boolean(beginReservationTime.length > 0);
  const endReservationInvalid =
    Boolean(endReservationDate.length > 0) !==
    Boolean(endReservationTime.length > 0);
  const beginReservation =
    beginReservationDate.length > 0 && beginReservationTime.length > 0;
  const endReservation =
    endReservationDate.length > 0 && endReservationTime.length > 0;

  const handleSearch = () => {
    if (beginReservationInvalid || endReservationInvalid) return;

    const beginReservationDateTime =
      !beginReservationInvalid && beginReservation
        ? `${beginReservationDate}T${beginReservationTime}:00`
        : '';
    const endReservationDateTime =
      !endReservationInvalid && endReservation
        ? `${endReservationDate}T${endReservationTime}:59`
        : '';
    setFilterValues([
      {
        key: 'begin-reservation-date',
        value: beginReservationDateTime,
        type: 'datetime',
      },
      {
        key: 'end-reservation-date',
        value: endReservationDateTime,
        type: 'datetime',
      },
      { key: 'card-id', value: cardId, type: '' },
      { key: 'name', value: name, type: '' },
      { key: 'kana', value: kana, type: '' },
      { key: 'birthday', value: birthDay, type: '' },
      { key: 'phone-number', value: phoneNumber, type: '' },
      { key: 'mail-address', value: mailAddress, type: '' },
      { key: 'status', value: status, type: '' },
      { key: 'sheet', value: sheet, type: '' },
      {
        key: 'begin-created-at',
        value: beginCreatedAt ? `${beginCreatedAt}T00:00:00` : '',
        type: 'datetime',
      },
      {
        key: 'end-created-at',
        value: endCreatedAt ? `${endCreatedAt}T23:59:59` : '',
        type: 'datetime',
      },
      {
        key: 'begin-updated-at',
        value: beginUpdatedAt ? `${beginUpdatedAt}T00:00:00` : '',
        type: 'datetime',
      },
      {
        key: 'end-updated-at',
        value: endUpdatedAt ? `${endUpdatedAt}T23:59:59` : '',
        type: 'datetime',
      },
      { key: 'sex', value: sex, type: '' },
    ]);
    setIsToday(isTodayLocal);

    onClose();
  };

  const handleClose = () => {
    if (findByKey(filterValues, 'begin-reservation-date').length > 0) {
      setBeginReservationDate(
        dateConvert(findByKey(filterValues, 'begin-reservation-date'), 'date'),
      );
      setBeginReservationTime(
        extractTime(findByKey(filterValues, 'begin-reservation-date')),
      );
    } else {
      setBeginReservationDate('');
      setBeginReservationTime('');
    }
    if (findByKey(filterValues, 'end-reservation-date').length > 0) {
      setEndReservationDate(
        dateConvert(findByKey(filterValues, 'end-reservation-date'), 'date'),
      );
      setEndReservationTime(
        extractTime(findByKey(filterValues, 'end-reservation-date')),
      );
    } else {
      setEndReservationDate('');
      setEndReservationTime('');
    }
    setCardId(findByKey(filterValues, 'card-id'));
    setName(findByKey(filterValues, 'name'));
    setKana(findByKey(filterValues, 'kana'));
    setBirthDay(findByKey(filterValues, 'birthday'));
    setPhoneNumber(findByKey(filterValues, 'phone-number'));
    setMailAddress(findByKey(filterValues, 'mail-address'));
    setStatus(findByKey(filterValues, 'status'));
    setSheet(findByKey(filterValues, 'sheet'));
    setBeginCreatedAt(
      findByKey(filterValues, 'begin-created-at')
        ? dateConvert(findByKey(filterValues, 'begin-created-at'), 'date')
        : '',
    );
    setEndCreatedAt(
      findByKey(filterValues, 'end-created-at')
        ? dateConvert(findByKey(filterValues, 'end-created-at'), 'date')
        : '',
    );
    setBeginUpdatedAt(
      findByKey(filterValues, 'begin-updated-at')
        ? dateConvert(findByKey(filterValues, 'begin-updated-at'), 'date')
        : '',
    );
    setEndUpdatedAt(
      findByKey(filterValues, 'end-updated-at')
        ? dateConvert(findByKey(filterValues, 'end-updated-at'), 'date')
        : '',
    );
    setIsTodayLocal(isToday);

    onClose();
  };

  const { browserName } = useBrowserDetect();
  const { isMobile } = useDeviceDetect();

  const handleGetSheets = async () => {
    if (isProcessed) return;
    try {
      setIsLoading(true);
      const result = await getInterviewSheets(tenantId);
      if (result.ok) {
        const data: GetInterviewSheets = await result.json();
        setSheets([
          ...sheets,
          ...data.map(({ id, name }) => ({
            id,
            title: name,
          })),
        ]);
      } else {
        console.log(result.status);
      }
      setIsProcessed(true);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const initialRef = useRef(null);

  useEffect(() => {
    const beginDate = findByKey(filterValues, 'begin-reservation-date');
    if (beginDate) {
      setBeginReservationDate(dateConvert(beginDate, 'date'));
      setBeginReservationTime(extractTime(beginDate));
    } else {
      setBeginReservationDate('');
      setBeginReservationTime('');
    }

    const endDate = findByKey(filterValues, 'end-reservation-date');
    if (endDate) {
      setEndReservationDate(dateConvert(endDate, 'date'));
      setEndReservationTime(extractTime(endDate));
    } else {
      setEndReservationDate('');
      setEndReservationTime('');
    }

    setIsTodayLocal(isToday);
  }, [filterValues, isToday]);

  return (
    <ModalDialog
      scrollBehavior="inside"
      initialFocus={initialRef}
      size="2xl"
      body={
        <>
          <Box>
            <RowLayout
              title="予約日時"
              content={
                <DateContainer isSmallMonitor={isSmallMonitor}>
                  <Flex flex="1.5">
                    <FormControl isInvalid={beginReservationInvalid}>
                      <Input
                        type="date"
                        value={beginReservationDate}
                        max={'2100-01-01'}
                        isDisabled={isTodayLocal}
                        onChange={(e) => {
                          setBeginReservationDate(e.target.value);
                          if (beginReservationTime.length < 1)
                            setBeginReservationTime('00:00');
                        }}
                        color={
                          beginReservationDate.length > 0
                            ? 'inherit'
                            : 'transparent'
                        }
                        _focus={{ color: 'inherit' }}
                        {...dateWidth}
                      />
                      <Input
                        type="time"
                        value={beginReservationTime}
                        mt="2"
                        isDisabled={isTodayLocal}
                        onChange={(e) =>
                          setBeginReservationTime(e.target.value)
                        }
                        color={
                          beginReservationTime.length > 0
                            ? 'inherit'
                            : 'transparent'
                        }
                        _focus={{ color: 'inherit' }}
                        {...dateWidth}
                      />
                    </FormControl>
                    <ClearButton
                      disabled={isTodayLocal}
                      onClick={() => {
                        setBeginReservationDate('');
                        setBeginReservationTime('');
                      }}
                    />
                  </Flex>
                  <Box
                    textAlign="center"
                    flex="0.5"
                    mr={isSmallMonitor ? '10' : '0'}
                  >
                    〜
                  </Box>
                  <Flex justifyContent="start" flex="1.5">
                    <FormControl isInvalid={endReservationInvalid}>
                      <Input
                        type="date"
                        value={endReservationDate}
                        max={'2100-01-01'}
                        isDisabled={isTodayLocal}
                        onChange={(e) => {
                          setEndReservationDate(e.target.value);
                          if (endReservationTime.length < 1)
                            setEndReservationTime('23:59');
                        }}
                        color={
                          endReservationDate.length > 0
                            ? 'inherit'
                            : 'transparent'
                        }
                        _focus={{ color: 'inherit' }}
                        {...dateWidth}
                      />
                      <Input
                        type="time"
                        value={endReservationTime}
                        mt="2"
                        isDisabled={isTodayLocal}
                        onChange={(e) => setEndReservationTime(e.target.value)}
                        color={
                          endReservationTime.length > 0
                            ? 'inherit'
                            : 'transparent'
                        }
                        _focus={{ color: 'inherit' }}
                        {...dateWidth}
                      />
                    </FormControl>
                    <ClearButton
                      disabled={isTodayLocal}
                      onClick={() => {
                        setEndReservationDate('');
                        setEndReservationTime('');
                      }}
                    />
                  </Flex>
                </DateContainer>
              }
              isTitleCenter={true}
            />
            <RowLayout
              title=""
              content={
                <Checkbox
                  isChecked={isTodayLocal}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setIsTodayLocal(checked);
                    setBeginReservationDate(checked ? today : '');
                    setEndReservationDate(checked ? today : '');
                    setBeginReservationTime(checked ? '00:00' : '');
                    setEndReservationTime(checked ? '23:59' : '');
                  }}
                  colorScheme="webInterview"
                  ref={initialRef}
                >
                  当日予約のみ表示
                </Checkbox>
              }
            />

            <RowLayout
              title="患者番号"
              content={
                <Input
                  value={cardId}
                  onChange={(e) => setCardId(e.target.value)}
                />
              }
            />
            <RowLayout
              title="氏名"
              content={
                <Input value={name} onChange={(e) => setName(e.target.value)} />
              }
            />
            <RowLayout
              title="カナ"
              content={
                <Input value={kana} onChange={(e) => setKana(e.target.value)} />
              }
            />
            <RowLayout
              title="性別"
              content={
                <RadioGroup onChange={setSex} value={sex}>
                  <Stack direction="row">
                    <Radio value="" colorScheme="webInterview">
                      すべて
                    </Radio>
                    <Radio value="male" colorScheme="webInterview">
                      男性
                    </Radio>
                    <Radio value="female" colorScheme="webInterview">
                      女性
                    </Radio>
                  </Stack>
                </RadioGroup>
              }
            />
            <RowLayout
              title="生年月日"
              content={
                <Flex>
                  <Input
                    type="date"
                    value={birthDay}
                    max={'2100-01-01'}
                    onChange={(e) => setBirthDay(e.target.value)}
                    color={birthDay.length > 0 ? 'inherit' : 'transparent'}
                    _focus={{ color: 'inherit' }}
                  />
                  <ClearButton
                    onClick={() => {
                      setBirthDay('');
                    }}
                  />
                </Flex>
              }
            />
            <RowLayout
              title="電話番号"
              content={
                <Input
                  value={phoneNumber}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              }
            />
            <RowLayout
              title="メールアドレス"
              content={
                <Input
                  value={mailAddress}
                  onChange={(e) => setMailAddress(e.target.value)}
                />
              }
            />
            <RowLayout
              title="問診票"
              content={
                isLoading ? (
                  <CircularProgress
                    isIndeterminate
                    color="blue.300"
                    size="10"
                  />
                ) : (
                  <Select
                    onClick={handleGetSheets}
                    // PC版の Safari では onClickが動作しないため、個別対応
                    onMouseOver={
                      !isMobile && browserName === 'Safari'
                        ? handleGetSheets
                        : undefined
                    }
                    onChange={(e) => setSheet(e.target.value)}
                    defaultValue={sheet}
                  >
                    {sheets.map(({ id, title }, idx) => (
                      <option key={idx} value={id}>
                        {title}
                      </option>
                    ))}
                  </Select>
                )
              }
            />
            <RowLayout
              title="ステータス"
              content={
                <RadioGroup
                  value={status}
                  onChange={setStatus}
                  isDisabled={deleted}
                >
                  <Radio colorScheme="webInterview" mr="3" value="">
                    すべて
                  </Radio>
                  <Radio colorScheme="webInterview" mr="3" value="unidentified">
                    未確認
                  </Radio>
                  <Radio colorScheme="webInterview" mr="3" value="identified">
                    確認済
                  </Radio>
                </RadioGroup>
              }
            />
            <RowLayout
              title=""
              content={
                <Checkbox
                  colorScheme="webInterview"
                  onChange={({ target }) => {
                    setDeleted(target.checked);
                    setStatus(target.checked ? 'deleted' : '');
                  }}
                  isChecked={deleted}
                >
                  無効のみ表示
                </Checkbox>
              }
            />
            <RowLayout
              title="作成日"
              content={
                <DateContainer isSmallMonitor={isSmallMonitor}>
                  <Flex flex="1.5">
                    <Input
                      type="date"
                      value={beginCreatedAt}
                      max={'2100-01-01'}
                      onChange={(e) => setBeginCreatedAt(e.target.value)}
                      color={
                        beginCreatedAt.length > 0 ? 'inherit' : 'transparent'
                      }
                      _focus={{ color: 'inherit' }}
                      {...dateWidth}
                    />
                    <ClearButton
                      onClick={() => {
                        setBeginCreatedAt('');
                      }}
                    />
                  </Flex>
                  <Box
                    textAlign="center"
                    flex="0.5"
                    mr={isSmallMonitor ? '10' : '0'}
                  >
                    〜
                  </Box>
                  <Flex justifyContent="start" flex="1.5">
                    <Input
                      type="date"
                      value={endCreatedAt}
                      max={'2100-01-01'}
                      onChange={(e) => setEndCreatedAt(e.target.value)}
                      color={
                        endCreatedAt.length > 0 ? 'inherit' : 'transparent'
                      }
                      _focus={{ color: 'inherit' }}
                      {...dateWidth}
                    />
                    <ClearButton
                      onClick={() => {
                        setEndCreatedAt('');
                      }}
                    />
                  </Flex>
                </DateContainer>
              }
              isTitleCenter={isSmallMonitor}
            />
            <RowLayout
              title="更新日"
              content={
                <DateContainer isSmallMonitor={isSmallMonitor}>
                  <Flex flex="1.5">
                    <Input
                      type="date"
                      value={beginUpdatedAt}
                      max={'2100-01-01'}
                      onChange={(e) => setBeginUpdatedAt(e.target.value)}
                      color={
                        beginUpdatedAt.length > 0 ? 'inherit' : 'transparent'
                      }
                      _focus={{ color: 'inherit' }}
                      {...dateWidth}
                    />
                    <ClearButton
                      onClick={() => {
                        setBeginUpdatedAt('');
                      }}
                    />
                  </Flex>
                  <Box
                    flex="0.5"
                    textAlign="center"
                    mr={isSmallMonitor ? '10' : '0'}
                  >
                    〜
                  </Box>
                  <Flex justifyContent="start" flex="1.5">
                    <Input
                      type="date"
                      value={endUpdatedAt}
                      max={'2100-01-01'}
                      onChange={(e) => setEndUpdatedAt(e.target.value)}
                      color={
                        endUpdatedAt.length > 0 ? 'inherit' : 'transparent'
                      }
                      _focus={{ color: 'inherit' }}
                      {...dateWidth}
                    />
                    <ClearButton
                      onClick={() => {
                        setEndUpdatedAt('');
                      }}
                    />
                  </Flex>
                </DateContainer>
              }
              isTitleCenter={isSmallMonitor}
            />
          </Box>
        </>
      }
      footer={
        <>
          {beginReservationInvalid || endReservationInvalid ? (
            <Text color="red" fontWeight="bold">
              日付と時間はどちらも入力してください
            </Text>
          ) : (
            <></>
          )}
          <Spacer />
          <Button colorScheme="webInterview" mr="3" onClick={handleSearch}>
            検索
          </Button>
          <Button onClick={handleClose}>キャンセル</Button>
        </>
      }
      isOpen={isOpen}
      onClose={handleClose}
    />
  );
};

const RowLayout: React.VFC<{
  title: string;
  content: React.ReactElement;
  isTitleCenter?: boolean;
}> = ({ title, content, isTitleCenter = false }) => (
  <Flex my="2" alignItems={isTitleCenter ? 'start' : 'center'}>
    <Box flex="1">
      {title === '患者番号' ? (
        <Tooltip label="入力された文字と完全に一致する問診結果を検索します">
          <Flex alignItems="center">
            <Text mr="2">{title}</Text>
            <MdInfoOutline />
          </Flex>
        </Tooltip>
      ) : (
        <Text>{title}</Text>
      )}
    </Box>
    <Box flex="3">{content}</Box>
  </Flex>
);

const ClearButton: React.VFC<{
  onClick: () => void;
  disabled?: boolean;
}> = ({ onClick, disabled = false }) => {
  return (
    <>
      <IconButton
        aria-label="clear"
        icon={<MdClear color="gray" />}
        size="sm"
        isRound={true}
        bg="white"
        mt="1"
        isDisabled={disabled}
        onClick={onClick}
        ml="1"
      />
    </>
  );
};

const DateContainer: React.VFC<{
  isSmallMonitor?: boolean;
  children: React.ReactNode;
}> = ({ isSmallMonitor = false, children }) => {
  return (
    <>
      {isSmallMonitor ? (
        <Box alignItems="start" mb="1">
          {children}
        </Box>
      ) : (
        <Flex alignItems="center">{children}</Flex>
      )}
    </>
  );
};
