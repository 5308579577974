import React from 'react';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

type Props = {
  header?: React.ReactElement;
  body: React.ReactElement;
  footer?: React.ReactElement;
  isOpen: boolean;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
  scrollBehavior?: 'inside' | 'outside';
  enableCloseButton?: boolean;
  initialFocus?: React.MutableRefObject<null>;
};

export const ModalDialog: React.VFC<Props> = ({
  header,
  body,
  footer,
  isOpen,
  onClose,
  size = 'md',
  scrollBehavior = 'outside',
  enableCloseButton = false,
  initialFocus = undefined,
}) => {
  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size={size}
        scrollBehavior={scrollBehavior}
        initialFocusRef={initialFocus}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{header}</ModalHeader>
          {enableCloseButton ? <ModalCloseButton /> : <></>}
          <ModalBody pb={6}>{body}</ModalBody>
          <ModalFooter>{footer} </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
