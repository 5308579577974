import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, HStack, useBreakpointValue } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';
import { EntryLayout } from '../../components/Layout/EntryLayout';
import { ClinicInformation } from '../../components/ClinicInformation';
import { getInterviewSheets } from '../../api/index';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import { GET_INTERVIEWSHEETS } from '../../actions/interviewSheetAction';
import { toInterviewSheets } from '../../domains/toInterviewSheets';
import { GetInterviewSheets } from '../../types/interviewSheetApiTypes';
import { NoticeMobile } from '../../components/NoticeMobile';
import { Notice } from '../../components/Notice';
import { Stepper } from '../../components/Stepper';
import { SheetSelector } from './SheetSelector';
import { ErrorCheck } from '../../components/ErrorCheck';

type Props = {};

export const InterviewSelect: React.VFC<Props> = () => {
  const px = useBreakpointValue({ base: 2, lg: 0 });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const [loading, setLoading] = useState(false);
  const { state: interviewSheetState } = useContext(InterviewSheetContext);
  const { dispatch: interviewSheetDispatch } = useContext(
    SetInterviewSheetContext,
  );

  const { id } = useParams<{ id: string }>();
  const tenantId: string = (() => {
    try {
      return atob(id.replace(/\r?\n/g, ''));
    } catch (ignored) {
      console.log(ignored);
      return '';
    }
  })();

  const enableSheets = interviewSheetState
    .filter(({ releaseDate }) => new Date(releaseDate) < new Date())
    .map(({ interviewSheetId: id, name }) => ({ id, name }));
  const getSheetsData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const result = await getInterviewSheets(tenantId);
      if (result.ok) {
        const data: GetInterviewSheets = await result.json();
        interviewSheetDispatch({
          type: GET_INTERVIEWSHEETS,
          data: toInterviewSheets(data),
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [interviewSheetDispatch, tenantId]);

  useEffect(() => {
    getSheetsData();
  }, [getSheetsData]);

  return (
    <ErrorCheck tenantId={tenantId}>
      <EntryLayout>
        <Box mb="4">
          {isMobile ? (
            <NoticeMobile tenantId={tenantId} />
          ) : (
            <Notice tenantId={tenantId} />
          )}
        </Box>
        {isMobile ? (
          <Box px={px}>
            <SheetSelector loading={loading} sheets={enableSheets} />
            <ClinicInformation tenantId={tenantId} />
          </Box>
        ) : (
          <HStack spacing="3" align="start">
            <Box w="315px">
              <Stepper pageNumber={1} />
              <ClinicInformation tenantId={tenantId} />
            </Box>
            <Box w="full">
              <SheetSelector loading={loading} sheets={enableSheets} />
            </Box>
          </HStack>
        )}
      </EntryLayout>
    </ErrorCheck>
  );
};
