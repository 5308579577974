import React from 'react';
import { Layout } from '../../components/Layout/Layout';
import { NewArrivalInterviewResults } from './NewArrivalInterviewResults';

export const Dashboard: React.VFC = () => {
  return (
    <Layout>
      <NewArrivalInterviewResults />
    </Layout>
  );
};
