import React, { useContext, useEffect, memo } from 'react';
import { Box, BoxProps, IconProps } from '@chakra-ui/react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggingStyle,
  NotDraggingStyle,
} from 'react-beautiful-dnd';
import { QuestionItem } from './QuestionItem';
import { AnswerType } from '../../types/webInterview';
import {
  QuestionItemContext,
  SetQuestionItemContext,
} from '../../contexts/QuestionItemContext';
import {
  ADD_QUESTION_ITEM,
  CHANGE_CHOICE_SORT_NUMBER,
} from '../../actions/questionItemAction';
import { reorder } from '../../utils/reorder';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  questionId: string;
  answerType: AnswerType;
  boxProps: BoxProps;
  iconProps: IconProps;
};

export const QuestionItems: React.VFC<Props> = memo(
  ({ questionId, answerType, boxProps, iconProps }) => {
    const { state } = useContext(QuestionItemContext);
    const { dispatch } = useContext(SetQuestionItemContext);
    const isChoice = answerType !== 'textbox' && answerType !== 'textline';
    const questionItems = [
      ...state.filter((c) => c.questionId === questionId),
    ].sort((preview, next) => preview.sortNumber - next.sortNumber);

    const handleOnDragEnd = ({ source, destination }: DropResult) => {
      if (!destination) {
        return;
      }
      reorder(questionItems, source.index, destination.index).forEach(
        ({ questionItemId }, idx) => {
          const sortNumber = idx + 1;

          dispatch({
            type: CHANGE_CHOICE_SORT_NUMBER,
            questionItemId: questionItemId,
            sortNumber,
          });
        },
      );
    };
    const getItemStyle = (
      isDragging: boolean,
      draggableStyle: DraggingStyle | NotDraggingStyle | undefined,
    ) => ({
      background: isDragging ? '#EBF8FF' : 'white',
      ...draggableStyle,
    });

    useEffect(() => {
      if (questionItems.length === 0) {
        dispatch({ type: ADD_QUESTION_ITEM, questionId: questionId });
      }
    }, [dispatch, questionItems.length, questionId]);

    return (
      <>
        {!isChoice || questionItems.length <= 1 ? (
          [questionItems[0] ?? defaultQuestionItem].map(
            ({ questionItemId }, idx) => (
              <QuestionItem
                answerType={answerType}
                questionItemId={questionItemId}
                boxProps={boxProps}
                iconProps={iconProps}
                key={idx}
              />
            ),
          )
        ) : (
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="questionItems">
              {(provided) => (
                <Box
                  className="questionItems"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {questionItems.map(({ questionItemId }, idx) => (
                    <Draggable
                      key={questionItemId}
                      draggableId={questionItemId}
                      index={idx}
                    >
                      {(provided, snapshot) => (
                        <Box
                          key={questionItemId}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style,
                          )}
                        >
                          <QuestionItem
                            answerType={answerType}
                            questionItemId={questionItemId}
                            boxProps={boxProps}
                            iconProps={iconProps}
                            key={idx}
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Box>
              )}
            </Droppable>
          </DragDropContext>
        )}
      </>
    );
  },
);
