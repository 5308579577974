import { newId } from '../../domains/newId';
import {
  GetInterviewSheet,
  PostInterviewSheet,
} from '../../types/interviewSheetApiTypes';

export const createPostData: (json: GetInterviewSheet) => PostInterviewSheet = (
  json,
) => {
  const { tenant_id, histories, version, ...data } = json;
  const temp = {
    ...data,
    id: newId('InterviewSheet'),
    release_date: '2100-01-01T00:00:00+09:00',
    name: `${data.name.slice(0, 14)} - コピー`,
    questions: data.questions.map((q) => ({
      ...q,
      id: newId(),
      oldId: q.id,
      question_items: q.question_items.map((qi) => ({
        ...qi,
        id: newId(),
        oldId: qi.id,
      })),
    })),
  };

  const questionDic = temp.questions.map((q) => ({
    newId: q.id,
    oldId: q.oldId,
  }));

  const questionItemDic = temp.questions
    .map((q) =>
      q.question_items.map((qi) => ({
        newId: qi.id,
        oldId: qi.oldId,
      })),
    )
    .flat();

  const postData = {
    ...temp,
    questions: temp.questions.map((q) => ({
      ...q,
      condition: {
        ...q.condition,
        id: newId(),
        prerequisite_question_id: questionDic.find(
          (d) => d.oldId === q.condition.prerequisite_question_id,
        )?.newId,
        prerequisite_items: q.condition.prerequisite_items.map((pi) => ({
          ...pi,
          id: newId(),
          question_item_id: questionItemDic.find(
            (d) => d.oldId === pi.question_item_id,
          )?.newId,
        })),
      },
    })),
  };

  return {
    ...postData,
    questions: postData.questions
      .map((q) => {
        const { oldId, ...rest } = q;
        return rest;
      })
      .map((q) => ({
        ...q,
        question_items: q.question_items.map((qi) => {
          const { oldId, ...rest } = qi;
          return { ...rest, tags: rest.tags.map((t) => t.id) };
        }),
      })),
  };
};
