import React, { useContext, memo, useCallback } from 'react';
import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
} from '@chakra-ui/react';
import { MdMoreVert } from 'react-icons/md';
import { SetQuestionItemContext } from '../../contexts/QuestionItemContext';
import { REMOVE_QUESTION_ITEM } from '../../actions/questionItemAction';

type Props = {
  questionItemId: string;
  isDescription: boolean;
  onOpenAffixModal: () => void;
  onOpenTagSettingModal: () => void;
};

export const QuestionItemMenu: React.VFC<Props> = memo(
  ({
    questionItemId,
    isDescription,
    onOpenAffixModal,
    onOpenTagSettingModal,
  }) => {
    const { dispatch } = useContext(SetQuestionItemContext);
    const handleDelete = useCallback(() => {
      dispatch({
        type: REMOVE_QUESTION_ITEM,
        questionItemId: questionItemId,
      });
    }, [dispatch, questionItemId]);
    return (
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          variant="outline"
          icon={<MdMoreVert />}
          isRound={true}
          border="none"
        />
        <MenuList>
          {!isDescription ? (
            <MenuItem onClick={handleDelete}>削除</MenuItem>
          ) : (
            <></>
          )}
          <MenuItem onClick={onOpenAffixModal}>入力欄の編集</MenuItem>
          <MenuItem onClick={onOpenTagSettingModal}>タグの設定</MenuItem>
        </MenuList>
      </Menu>
    );
  },
);
