import React, { useState, useEffect, useRef } from 'react';
import {
  CircularProgress,
  Flex,
  Heading,
  useBoolean,
  HStack,
  Button,
  Box,
  VStack,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { Layout } from '../../components/Layout/Layout';
import { Paper } from '../../components/atoms/Paper';
import { Authorization } from '../../components/Authorization';
import { PATHNAME } from '../../constant/constant';
import { NoticeEditor } from './NoticeEditor';
import { ImageEditor } from './ImageEditor';
import {
  getClinicNotice,
  putClinicNotice,
  putHeaderImage,
  getHeaderImage,
  deleteHeaderImage,
} from '../../api/index';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import {
  GetClinicNotice,
  PutClinicNotice,
} from '../../types/clinicNoticeApiTypes';
import { PutHeaderImage } from '../../types/headerImageApiTypes';
import { dateConvert } from '../../utils/dateConvert';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import {
  TOAST_SUCCESS_OPTION,
  TOAST_ERROR_OPTION,
} from '../../constant/systemMessage';

type Updated = {
  updatedAt: string;
  updatedByName: string;
};

export const PatientSite: React.VFC = () => {
  const [imgLoading, setImgLoading] = useState<boolean>(false);
  const [noticeLoading, setNoticeLoading] = useState<boolean>(false);
  const [imgSource, setImgSource] = useState<string>('');
  const [croppedImgSource, setCroppedImgSource] = useState<string>('');
  const [base64CroppedImg, setBase64CroppedImg] = useState<string>('');
  const [notice, setNotice] = useState<string>('');
  const [isDiscard, setIsDiscard] = useBoolean(true);
  const [isImgInS3, setIsImgInS3] = useState<boolean>(false);
  const [deleteButtonClicked, setDeleteButtonClicked] =
    useState<boolean>(false);
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const [updated, setUpdated] = useState<Updated>({
    updatedAt: '',
    updatedByName: '',
  });

  const S3_URL = process.env.REACT_APP_S3_ENDPOINT_WEB_INTERVIEW;
  const uploadedUrl = `${S3_URL}/${tenantId}/header.jpg`;

  const toast = useToast();
  const {
    isOpen: saveIsOpen,
    onOpen: saveOnOpen,
    onClose: saveOnClose,
  } = useDisclosure();
  const {
    isOpen: cancelIsOpen,
    onOpen: cancelOnOpen,
    onClose: cancelOnClose,
  } = useDisclosure();
  const saveCancelRef = useRef<HTMLButtonElement>(null);
  const cancelRef = useRef<HTMLButtonElement>(null);

  const line = '<line>';

  const handleDiscard = () => {
    setIsDiscard.toggle();
    cancelOnClose();
    setCroppedImgSource('');
    setImgSource('');
    setDeleteButtonClicked(false);
  };

  const handleSetImgSource = (imgSource: string) => {
    setImgSource(imgSource);
  };

  const handleSetCropedImgSource = (croppedImgSource: string) => {
    setCroppedImgSource(croppedImgSource);
  };

  const handleSetBase64CropedImg = (base64CroppedImg: string) => {
    setBase64CroppedImg(base64CroppedImg);
  };

  const handleSetNotice = (notice: string) => {
    setNotice(notice);
  };

  const handleSetDeleteButtonClicked = (deleteButtonClicked: boolean) => {
    setDeleteButtonClicked(deleteButtonClicked);
  };

  useEffect(() => {
    const getNoticeData = async (): Promise<void> => {
      try {
        setNoticeLoading(true);
        const result = await getClinicNotice(tenantId);
        if (result.ok) {
          const data: GetClinicNotice = await result.json();
          if (data) {
            setNotice(data.contents.join(line));
            setUpdated({
              updatedAt: dateConvert(data.updated_at, 'seconds'),
              updatedByName: data.updated_by_name,
            });
          }
        } else {
          console.log(result.status);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setNoticeLoading(false);
      }
    };
    getNoticeData();
  }, [tenantId, isDiscard]);

  useEffect(() => {
    const getImgData = async (): Promise<void> => {
      try {
        setImgLoading(true);
        const result = await getHeaderImage(tenantId);
        if (result.ok) {
          setIsImgInS3(true);
        } else {
          setIsImgInS3(false);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setImgLoading(false);
      }
    };
    getImgData();
  }, [tenantId, isDiscard]);

  const handleSave = async () => {
    setNoticeLoading(true);
    saveOnClose();

    const imgAccessToken = await getAccessTokenSilently({
      audience: 'https://www.webinterview.mic.jp/',
      scope: croppedImgSource ? 'update:header-image' : 'delete:header-image',
    });

    const noticeAccessToken = await getAccessTokenSilently({
      audience: 'https://www.webinterview.mic.jp/',
      scope: 'update:clinic-notice',
    });

    const saveData = async (): Promise<void> => {
      try {
        const putNoticeData = notice.split(line);
        const noticeResult = await putClinicNotice(
          tenantId,
          userId,
          userName,
          noticeAccessToken,
          { contents: putNoticeData } as PutClinicNotice,
        );

        const putImgData = base64CroppedImg;
        const imgResult = croppedImgSource
          ? await putHeaderImage(tenantId, imgAccessToken, {
              image_data: putImgData,
            } as PutHeaderImage)
          : isImgInS3 && deleteButtonClicked
          ? await deleteHeaderImage(tenantId, imgAccessToken)
          : undefined;

        if (noticeResult.ok && (imgResult?.ok || imgResult === undefined)) {
          toast({
            title: '保存完了',
            description: '編集内容を保存しました',
            ...TOAST_SUCCESS_OPTION,
          });
          setIsDiscard.toggle();
        } else {
          if (noticeResult.status !== 204)
            throw new Error(noticeResult.status.toString());
          if (imgResult?.status !== 204)
            throw new Error(imgResult?.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          ...TOAST_ERROR_OPTION,
        });
        console.log(error);
      } finally {
        setNoticeLoading(false);
      }
    };
    saveData();
  };

  return (
    <>
      <Layout>
        <Authorization pathName={PATHNAME.CLINIC_NOTICE}>
          <Flex alignItems="center" m="4">
            <Heading size="lg" color="gray.600">
              患者サイト編集
            </Heading>
          </Flex>
          <Paper pt="10" pb="5" px="6">
            {imgLoading || noticeLoading ? (
              <CircularProgress isIndeterminate color="blue.300" />
            ) : (
              <>
                <ImageEditor
                  uploadedUrl={uploadedUrl}
                  isImgInS3={isImgInS3}
                  imgSource={imgSource}
                  setImgSource={handleSetImgSource}
                  croppedImgSource={croppedImgSource}
                  setCroppedImgSource={handleSetCropedImgSource}
                  deleteButtonClicked={deleteButtonClicked}
                  setDeleteButtonClicked={handleSetDeleteButtonClicked}
                  setBase64CroppedImg={handleSetBase64CropedImg}
                />
                <NoticeEditor notice={notice} setNotice={handleSetNotice} />
                <HStack my="3" justifyContent="right" spacing="5">
                  <Button
                    colorScheme="webInterview"
                    onClick={saveOnOpen}
                    isDisabled={notice.length > 1000}
                  >
                    保存
                  </Button>
                  <Button onClick={cancelOnOpen}>キャンセル</Button>
                </HStack>
                <Box borderTopWidth="1px">
                  <HStack spacing="10" mt="3">
                    <VStack align="start" minW="150px">
                      <Text
                        fontWeight="bold"
                        color="gray.600"
                        textAlign="left"
                        w="full"
                      >
                        更新者
                      </Text>
                      <Text color="gray.600" textAlign="left" w="full">
                        {updated.updatedByName}
                      </Text>
                    </VStack>
                    <VStack align="start">
                      <Text
                        fontWeight="bold"
                        color="gray.600"
                        textAlign="left"
                        w="full"
                      >
                        更新日時
                      </Text>
                      <Text color="gray.600" textAlign="left" w="full">
                        {updated.updatedAt}
                      </Text>
                    </VStack>
                  </HStack>
                </Box>
                <ConfirmDialog
                  isOpen={saveIsOpen}
                  cancelRef={saveCancelRef}
                  onClose={saveOnClose}
                  headerText={'編集内容の保存'}
                  bodyText={'編集した内容を保存します。よろしいですか？'}
                  confirmOk={
                    <Button
                      colorScheme="webInterview"
                      onClick={handleSave}
                      mr={3}
                    >
                      保存
                    </Button>
                  }
                  confirmCancel={
                    <Button ref={saveCancelRef} onClick={saveOnClose}>
                      キャンセル
                    </Button>
                  }
                />
                <ConfirmDialog
                  isOpen={cancelIsOpen}
                  cancelRef={cancelRef}
                  onClose={cancelOnClose}
                  headerText={'編集内容の破棄'}
                  bodyText={'編集した内容を破棄します。よろしいですか？'}
                  confirmOk={
                    <Button colorScheme="red" onClick={handleDiscard} mr={3}>
                      破棄
                    </Button>
                  }
                  confirmCancel={
                    <Button ref={cancelRef} onClick={cancelOnClose}>
                      キャンセル
                    </Button>
                  }
                />
              </>
            )}
          </Paper>
        </Authorization>
      </Layout>
    </>
  );
};
