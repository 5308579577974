import React, { useState, useContext, useEffect } from 'react';
import {
  HStack,
  Button,
  Box,
  Input,
  InputLeftElement,
  InputGroup,
  Checkbox,
} from '@chakra-ui/react';
import { MdSearch } from 'react-icons/md';
import { ModalDialog } from '../../components/ModalDialog';
import { BUTTON } from '../../components/atoms/Button';
import { TagRow } from './TagRow';
import { TagContext } from '../../contexts/TagContext';
import {
  QuestionItemContext,
  SetQuestionItemContext,
} from '../../contexts/QuestionItemContext';
import { CHANGE_TAGS } from '../../actions/questionItemAction';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  questionItemId: string;
  isOpen: boolean;
  onClose: () => void;
};

export const TagSettingModalDialog: React.VFC<Props> = ({
  questionItemId,
  isOpen,
  onClose,
}) => {
  const { state: tagState } = useContext(TagContext);
  const { state: questionItemState } = useContext(QuestionItemContext);
  const { dispatch } = useContext(SetQuestionItemContext);
  const { tags } =
    questionItemState.find((qi) => qi.questionItemId === questionItemId) ??
    defaultQuestionItem;
  const [filteredTags, setFilteredTags] = useState(tagState);
  const [tagIds, setTagIds] = useState<string[]>(tags);

  useEffect(() => {
    if (isOpen) {
      setFilteredTags(tagState);
      setTagIds(
        questionItemState.find((c) => c.questionItemId === questionItemId)
          ?.tags ?? [],
      );
    }
  }, [isOpen, tagState, questionItemState, questionItemId]);

  const handleSave = () => {
    dispatch({
      type: CHANGE_TAGS,
      questionItemId: questionItemId,
      tags: tagIds,
    });
    handleClose();
  };
  const handleClose = () => {
    onClose();
  };

  return (
    <ModalDialog
      header={
        <InputGroup mb="2">
          <InputLeftElement
            pointerEvents="none"
            children={<MdSearch size="24px" />}
            color="gray.400"
          />
          <Input
            placeholder="タグを検索"
            color="gray.600"
            onChange={(e) => {
              setFilteredTags(
                tagState.filter((t) => t.name.indexOf(e.target.value) > -1),
              );
            }}
          />
        </InputGroup>
      }
      body={
        <>
          {filteredTags.map((tag, idx) =>
            tag.enable ? (
              <TagRow
                tag={tag}
                key={idx}
                buttonArea={
                  <Checkbox
                    mr="2"
                    minH="32px"
                    isChecked={tagIds.some((id) => id === tag.tagId)}
                    onChange={(e) =>
                      e.target.checked
                        ? setTagIds([...tagIds, tag.tagId])
                        : setTagIds(tagIds.filter((id) => id !== tag.tagId))
                    }
                    colorScheme="webInterview"
                  />
                }
              />
            ) : (
              <Box key={idx}></Box>
            ),
          )}
        </>
      }
      footer={
        <HStack spacing="3">
          <BUTTON text="保存" onClick={handleSave} />
          <Button onClick={handleClose}>キャンセル</Button>
        </HStack>
      }
      isOpen={isOpen}
      onClose={onClose}
      scrollBehavior="inside"
    />
  );
};
