import React from 'react';
import { Box, Flex, Tag, Spacer } from '@chakra-ui/react';
import { Tag as TagType } from '../../types/tag';

type Props = {
  tag: TagType;
  buttonArea?: React.ReactElement;
  editArea?: React.ReactElement;
};

export const TagRow: React.VFC<Props> = ({ tag, buttonArea, editArea }) => {
  return (
    <Box borderBottom="1px" borderColor="gray.300" mb="2">
      <Flex>
        <Box>
          <Tag
            colorScheme={
              tag.isSystemOnly ? 'blue' : !tag.enable ? 'red' : 'gray'
            }
          >
            {tag.name}
          </Tag>
        </Box>
        <Spacer />
        {buttonArea}
      </Flex>
      {editArea}
    </Box>
  );
};
