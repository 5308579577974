import React, { createContext } from 'react';
import { TenantAction } from '../actions/tenantAction';
import { Tenant } from '../types/tenant';

export const TenantContext = createContext(
  {} as {
    state: Tenant;
  },
);

export const SetTenantContext = createContext(
  {} as {
    dispatch: React.Dispatch<TenantAction>;
  },
);
