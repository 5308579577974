import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Flex,
  Heading,
  Spacer,
  Text,
  useDisclosure,
  useBreakpointValue,
  useMediaQuery,
  IconButton,
} from '@chakra-ui/react';
import { RiFilterFill, RiFilterOffFill } from 'react-icons/ri';
import { useAuth0 } from '@auth0/auth0-react';
import { Layout } from '../../components/Layout/Layout';
import { getInterviewResults } from '../../api/index';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { GetInterviewResults } from '../../types/interviewResultApiTypes';
import { dateConvert } from '../../utils/dateConvert';
import './style.css';
import { FilterModal } from './FilterModal';
import { toStatusName } from '../../domains/status';
import { InterviewResultsTable } from '../../components/InterviewResultsTable';
import { Authorization } from '../../components/Authorization';
import { PATHNAME } from '../../constant/constant';

type Props = {};
type DataType = {
  interviewResultId: string;
  reservationDate: string;
  pid: string;
  patientName: string;
  status: string;
  createdAt: string;
};

export type FilterValue = {
  key: string;
  value: string;
  type: 'datetime' | string;
};

export const findByKey = (values: FilterValue[], key: string) =>
  values.find((v) => key === v.key)?.value ?? '';

export const InterviewResults: React.VFC<Props> = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const [loading, setLoading] = useState(false);
  const today = dateConvert(new Date().toString(), 'date');
  const [interviewResults, setInterviewResults] = useState<DataType[]>([]);
  const [isToday, setIsToday] = useState(true);
  const [filterValues, setFilterValues] = useState<FilterValue[]>([
    {
      key: 'begin-reservation-date',
      value: `${today}T00:00`,
      type: 'datetime',
    },
    {
      key: 'end-reservation-date',
      value: `${today}T23:59`,
      type: 'datetime',
    },
  ]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const showPid = useBreakpointValue({ base: false, md: true }) ?? false;
  const [isMobile] = useMediaQuery('(min-width:375px)');
  const [filterChange, setFilterChange] = useState(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:interview-results',
        });
        const result = await getInterviewResults(
          tenantId,
          userId,
          userName,
          accessToken,
          filterValues
            .filter(({ value }) => value.length)
            .map((x) =>
              x.type === 'datetime'
                ? {
                    key: x.key,
                    value:
                      x.value.length > 0
                        ? dateConvert(x.value, 'seconds', 'T', '%2B09:00')
                        : '',
                    type: x.type,
                  }
                : x,
            )
            .map((x) =>
              x.key === 'begin-reservation-date' && isToday
                ? {
                    key: x.key,
                    value: `${today}T00:00:00%2B09:00`,
                  }
                : x,
            )
            .map((x) =>
              x.key === 'end-reservation-date' && isToday
                ? {
                    key: x.key,
                    value: `${today}T23:59:59%2B09:00`,
                  }
                : x,
            ),
        );
        if (result.ok) {
          const data: GetInterviewResults = await result.json();
          const sexFilter = findByKey(filterValues, 'sex');
          const phoneNumberFilter = findByKey(filterValues, 'phone-number');
          const mailAddressfilter = findByKey(filterValues, 'mail-address');
          setInterviewResults(
            data
              .filter((d) => (sexFilter ? d.sex === sexFilter : true))
              .filter((d) =>
                phoneNumberFilter
                  ? d.phone_number.indexOf(phoneNumberFilter) > -1
                  : true,
              )
              .filter((d) =>
                mailAddressfilter
                  ? d.mail_address.indexOf(mailAddressfilter) > -1
                  : true,
              )
              .sort(
                (a, b) =>
                  new Date(a.reservation_date).getTime() -
                  new Date(b.reservation_date).getTime(),
              )
              .map<DataType>((x) => ({
                interviewResultId: x.id,
                reservationDate: dateConvert(x.reservation_date, 'minutes'),
                pid: x.pid,
                patientName: `${x.last_name} ${x.first_name}`,
                status: toStatusName(x.status),
                createdAt: dateConvert(x.created_at, 'seconds'),
              })),
          );
          setFilterChange(true);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [
    getAccessTokenSilently,
    tenantId,
    userId,
    userName,
    filterValues,
    today,
    isToday,
  ]);

  return (
    <>
      <Layout>
        <Authorization pathName={PATHNAME.INTERVIEW_RESULTS}>
          <Flex m="4">
            <Heading size="lg" color="gray.600">
              問診結果一覧
            </Heading>
            <Spacer />
            <Checkbox
              colorScheme="webInterview"
              mr="3"
              isChecked={isToday}
              onChange={(e) => {
                const { checked } = e.target;
                setIsToday(checked);
                setFilterValues(
                  filterValues.map(({ key, type, value }) => {
                    if (key === 'begin-reservation-date') {
                      return {
                        key,
                        type,
                        value: checked ? `${today}T00:00` : '',
                      };
                    }
                    if (key === 'end-reservation-date') {
                      return {
                        key,
                        type,
                        value: checked ? `${today}T23:59` : '',
                      };
                    }
                    return { key, type, value };
                  }),
                );
              }}
            >
              <Text color="gray.600" fontWeight="bold">
                当日予約のみ表示
              </Text>
            </Checkbox>
            {isMobile ? (
              <Button
                aria-label="new"
                leftIcon={
                  filterValues
                    .filter((x) =>
                      isToday ? x.key !== 'begin-reservation-date' : true,
                    )
                    .filter((x) =>
                      isToday ? x.key !== 'end-reservation-date' : true,
                    )
                    .some((x) => x.value) ? (
                    <RiFilterFill size="24px" />
                  ) : (
                    <RiFilterOffFill size="24px" />
                  )
                }
                colorScheme="webInterview"
                onClick={onOpen}
              >
                フィルター
              </Button>
            ) : (
              <IconButton
                aria-label="new"
                icon={
                  filterValues
                    .filter((x) =>
                      isToday ? x.key !== 'begin-reservation-date' : true,
                    )
                    .filter((x) =>
                      isToday ? x.key !== 'end-reservation-date' : true,
                    )
                    .some((x) => x.value) ? (
                    <RiFilterFill size="24px" />
                  ) : (
                    <RiFilterOffFill size="24px" />
                  )
                }
                colorScheme="webInterview"
                onClick={onOpen}
              />
            )}
          </Flex>
          <InterviewResultsTable
            loading={loading}
            interviewResults={interviewResults}
            showStatus={showPid}
            showCreatedAt={isMobile}
            filterChange={filterChange}
            setFilterChange={setFilterChange}
          />
        </Authorization>
      </Layout>
      <FilterModal
        isOpen={isOpen}
        onClose={onClose}
        isToday={isToday}
        setIsToday={setIsToday}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    </>
  );
};
