import React, { useReducer } from 'react';
import {
  QuestionItemContext,
  SetQuestionItemContext,
} from '../contexts/QuestionItemContext';
import {
  questionItemReducer,
  initialState,
} from '../reducers/questionItemReducer';

type Props = {
  children: React.ReactNode;
};

export const QuestionItemProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(questionItemReducer, initialState);
  return (
    <QuestionItemContext.Provider value={{ state }}>
      <SetQuestionItemContext.Provider value={{ dispatch }}>
        {children}
      </SetQuestionItemContext.Provider>
    </QuestionItemContext.Provider>
  );
};
