import React from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';

type Props = {
  text: string;
  buttonProps?: ButtonProps;
  onClick?: () => void;
};

export const BaseButtonProps: ButtonProps = {
  color: 'white',
  bg: 'blue.200',
  _hover: { bg: 'blue.300' },
  _active: {
    bg: 'blue.400',
    borderColor: '#bec3c9',
  },
  _focus: {
    boxShadow:
      '0 0 1px 2px rgba(88, 144, 255, .75), 0 1px 1px rgba(0, 0, 0, .15)',
  },
};

export const BUTTON: React.VFC<Props> = ({ text, buttonProps, onClick }) => {
  return (
    <Button {...BaseButtonProps} {...buttonProps} onClick={onClick}>
      {text}
    </Button>
  );
};
