import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { Footer } from './Footer';
import { EntryHeader } from './EntryHeader';
import { ScrollTopTo } from '../ScrollToTop';

type Props = {
  children: React.ReactNode;
  hideHeader?: boolean;
};

export const EntryLayout: React.VFC<Props> = ({
  children,
  hideHeader = false,
}) => {
  const px = useBreakpointValue({ base: 2, lg: 0 });

  return (
    <>
      <ScrollTopTo />
      <Box bg="gray.50" minH="100vH">
        {hideHeader ? <></> : <EntryHeader />}
        <Box maxW="1000px" align="center" mx="auto">
          <Box pt={4} minH="65vH">
            <Box px={px}>{children}</Box>
          </Box>
        </Box>
        <Footer isEntry={true} />
      </Box>
    </>
  );
};
