import React from 'react';
import { Icon, IconProps, Box, BoxProps } from '@chakra-ui/react';
import {
  MdNotes,
  MdSubject,
  MdOutlineRadioButtonChecked,
  MdOutlineCheckBox,
  MdOutlineArrowDropDownCircle,
} from 'react-icons/md';
import { AnswerType } from '../../types/webInterview';

type Props = {
  boxProps: BoxProps;
  iconProps: IconProps;
  answerType: AnswerType;
};

export const AnswerTypeIcon: React.VFC<Props> = ({
  boxProps,
  iconProps,
  answerType,
}) => {
  return (
    <Box {...boxProps}>
      <Icon
        as={
          answerType === 'textbox'
            ? MdNotes
            : answerType === 'textline'
            ? MdSubject
            : answerType === 'radiobutton'
            ? MdOutlineRadioButtonChecked
            : answerType === 'checkbox'
            ? MdOutlineCheckBox
            : answerType === 'combobox'
            ? MdOutlineArrowDropDownCircle
            : MdNotes
        }
        {...iconProps}
      />
    </Box>
  );
};
