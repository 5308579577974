import React, { useState } from 'react';
import {
  Box,
  Button,
  Heading,
  useToast,
  Text,
  Flex,
  CircularProgress,
} from '@chakra-ui/react';
import { PersonalInformation } from '../../types/personalInformation';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { toApiPersonalInformation } from '../../domains/toInterviewResultConfirm';
import { ModalDialog } from '../../components/ModalDialog';
import { putInterviewResult } from '../../api/index';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  personalInformation: PersonalInformation;
  save: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const DeleteDialog: React.VFC<Props> = ({
  isOpen,
  onClose,
  personalInformation,
  save,
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const toast = useToast();

  const handleChangeStatus = async () => {
    const putData = async (): Promise<void> => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'update:interview-results',
        });

        const result = await putInterviewResult(
          tenantId,
          id,
          userId,
          userName,
          accessToken,
          toApiPersonalInformation({
            ...personalInformation,
            status: 'deleted',
          }),
        );
        if (result.ok) {
          toast({
            title: '保存完了',
            description: '問診結果を無効にしました',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          save.toggle();
          onClose();
        } else {
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.error({ error });
        onClose();
      } finally {
        setLoading(false);
      }
    };
    putData();
  };
  return (
    <>
      <ModalDialog
        header={
          loading ? (
            <></>
          ) : (
            <Heading size="md" color="gray.600">
              問診結果無効
            </Heading>
          )
        }
        body={
          loading ? (
            <Flex w="full" h="min" justifyContent="center" alignItems="center">
              <CircularProgress isIndeterminate color="blue.300" />
            </Flex>
          ) : (
            <Box>
              <Text color="gray.600" mb="2">
                問診結果を無効にします。よろしいですか？
              </Text>
              <Text color="red.500" fontWeight="bold">
                ※無効後は編集不可となり、元に戻すことはできません
              </Text>
            </Box>
          )
        }
        footer={
          loading ? (
            <></>
          ) : (
            <Box>
              <Button
                colorScheme="red"
                onClick={() => handleChangeStatus()}
                mr={3}
              >
                無効
              </Button>
              <Button onClick={onClose}>キャンセル</Button>
            </Box>
          )
        }
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      />
    </>
  );
};
