import { GetTags } from '../types/tagApiTypes';
import { Tag } from '../types/tag';

export const toTags = (data: GetTags) =>
  data.map<Tag>(({ id, name, enable, is_system_only, version }) => ({
    tagId: id,
    name: name,
    enable: enable,
    isSystemOnly: is_system_only,
    filtered: false,
    editing: false,
    version: version,
  }));
