import React from 'react';
import { Circle, Flex, Heading, Text } from '@chakra-ui/react';

type Props = {
  text: string;
  iconElement: React.ReactElement;
};

export const Header: React.VFC<Props> = ({ text, iconElement }) => {
  return (
    <Heading size="md" mb="4">
      <Flex align="center">
        <Circle color="gray.600" bg="gray.600" size="30px" mr="3">
          {iconElement}
        </Circle>
        <Text color="gray.600">{text}</Text>
      </Flex>
    </Heading>
  );
};
