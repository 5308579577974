import React from 'react';
import {
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';

type Props = {
  headerText: string;
  bodyText: string;
  confirmOk: React.ReactElement;
  confirmCancel: React.ReactElement;
  isOpen: boolean;
  cancelRef: React.RefObject<HTMLButtonElement>;
  onClose: () => void;
};

export const ConfirmDialog: React.VFC<Props> = ({
  headerText,
  bodyText,
  confirmOk,
  confirmCancel,
  isOpen,
  cancelRef,
  onClose,
}) => {
  return (
    <AlertDialog
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader fontSize="lg" fontWeight="bold" color="gray.600">
            {headerText}
          </AlertDialogHeader>
          <AlertDialogBody color="gray.600">{bodyText}</AlertDialogBody>
          <AlertDialogFooter>
            {confirmOk}
            {confirmCancel}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};
