import React, { useContext, memo, useCallback, useState } from 'react';
import { Text, Textarea } from '@chakra-ui/react';
import { AnswerType } from '../../types/webInterview';
import { InputWithAffix } from './InputWithAffix';
import {
  QuestionItemContext,
  SetQuestionItemContext,
} from '../../contexts/QuestionItemContext';
import { CHANGE_CHOICE_TEXT } from '../../actions/questionItemAction';
import { defaultQuestionItem } from '../../reducers/questionItemReducer';

type Props = {
  questionItemId: string;
  answerType: AnswerType;
};

export const QuestionItemInputWithAffix: React.VFC<Props> = memo(
  ({ questionItemId, answerType }) => {
    const { state } = useContext(QuestionItemContext);
    const { dispatch } = useContext(SetQuestionItemContext);
    const {
      choiceText,
      descriptionPrefix,
      descriptionSuffix,
      descriptionPlaceholder,
    } =
      state.find((c) => c.questionItemId === questionItemId) ??
      defaultQuestionItem;
    const isDescription = answerType === 'textbox' || answerType === 'textline';
    const handleInputChange = useCallback(
      (choice: string) => {
        dispatch({
          type: CHANGE_CHOICE_TEXT,
          questionItemId: questionItemId,
          text: choice,
        });
      },
      [dispatch, questionItemId],
    );
    const [text, setText] = useState(choiceText);

    return (
      <>
        {answerType === 'textline' ? (
          <>
            <Text align="start" color="gray.600">
              {descriptionPrefix}
            </Text>
            <Textarea
              placeholder={isDescription ? descriptionPlaceholder : ''}
              disabled={true}
            />
            <Text align="start" color="gray.600">
              {descriptionSuffix}
            </Text>
          </>
        ) : (
          <InputWithAffix
            prefix={isDescription ? descriptionPrefix : ''}
            suffix={isDescription ? descriptionSuffix : ''}
            value={isDescription ? '' : text}
            placeholder={isDescription ? descriptionPlaceholder : ''}
            disabled={isDescription}
            onChange={(e) => setText(e.target.value)}
            onBlur={() => handleInputChange(text)}
          />
        )}
      </>
    );
  },
);
