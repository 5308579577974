import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  Heading,
  Flex,
  IconButton,
  Tooltip,
  useBreakpointValue,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewResults } from '../../api';
import { GetInterviewResults } from '../../types/interviewResultApiTypes';
import { dateConvert } from '../../utils/dateConvert';
import { MdRefresh } from 'react-icons/md';
import { InterviewResultsTable } from '../../components/InterviewResultsTable';
import { DataType } from '../../components/InterviewResultsTable';

type Props = {};

export const NewArrivalInterviewResults: React.VFC<Props> = () => {
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const [loading, setLoading] = useState(false);

  const isTablet = useBreakpointValue({ base: true, lg: false });
  const [interviewResults, setInterviewResults] = useState<DataType[]>([]);

  const getData = useCallback(async (): Promise<void> => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'read:interview-results',
      });
      const result = await getInterviewResults(
        tenantId,
        userId,
        userName,
        accessToken,
        [],
      );
      if (result.ok) {
        const data: GetInterviewResults = await result.json();
        setInterviewResults(
          data
            .map((d) => ({
              interviewResultId: d.id,
              reservationDate: dateConvert(d.reservation_date, 'minutes'),
              patientName: `${d.last_name} ${d.first_name}`,
              createdAt: d.created_at,
              pid: d.pid,
              status: d.status,
            }))
            .sort(
              (a, b) =>
                new Date(b.createdAt).getTime() -
                new Date(a.createdAt).getTime(),
            )
            .map((d) => ({
              ...d,
              createdAt: dateConvert(d.createdAt, 'seconds'),
            })),
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }, [getAccessTokenSilently, tenantId, userId, userName]);

  useEffect(() => {
    getData();
  }, [getData]);

  return (
    <Box w="full" py="3" px={isTablet ? '3' : '10'}>
      <Flex pb="3" alignItems="center">
        <Heading
          size={isTablet ? 'md' : 'lg'}
          textAlign="left"
          color="gray.600"
        >
          新着問診票
        </Heading>
        <Tooltip label="最新を取得します">
          <IconButton
            aria-label="refresh"
            colorScheme="webInterview"
            icon={<MdRefresh size="24" />}
            size="sm"
            onClick={() => getData()}
            mx="3"
          />
        </Tooltip>
      </Flex>
      <InterviewResultsTable
        interviewResults={interviewResults}
        loading={loading}
        showPid={false}
        showStatus={false}
        showFooter={false}
      />
    </Box>
  );
};
