import React, { memo } from 'react';
import {
  Text,
  Icon,
  Flex,
  Link,
  Stack,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  useColorModeValue,
  VStack,
} from '@chakra-ui/react';
import {
  MdSearch,
  MdDashboardCustomize,
  MdDescription,
  MdExpandMore,
  MdOutlineWeb,
} from 'react-icons/md';
import { IconType } from 'react-icons';
import { useGetMetadata } from '../hooks/useGetMetadata';
import { ROLES } from '../constant/constant';

type NavItem = {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  icon?: IconType;
  roles?: string[];
  isBlank?: boolean;
};

export const PopoverNavItem = memo(() => {
  const popoverContentBgColor = useColorModeValue('white', 'gray.800');
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const encodedTenanId = btoa(tenantId);
  const NAV_ITEMS: Array<NavItem> = [
    {
      label: '管理機能',
      children: [
        {
          label: '問診結果',
          subLabel:
            '問診結果を表示します。患者情報、入力日時などの条件で絞り込みができます。',
          href: '/interviewResults',
          icon: MdSearch,
          roles: [ROLES.USER, ROLES.MANAGER, ROLES.ADMINISTRATOR],
          isBlank: false,
        },
        {
          label: '問診票カスタマイズ',
          subLabel: '問診票の追加、編集、公開・非公開の設定を行います。',
          href: '/interviewsheets',
          icon: MdDashboardCustomize,
          roles: [ROLES.MANAGER, ROLES.ADMINISTRATOR],
          isBlank: false,
        },
        {
          label: '問診票入力',
          subLabel: '紙で受け付けた問診票を患者の代わりに入力します。',
          href: `/tenants/${encodedTenanId}/interviewSelect`,
          icon: MdDescription,
          roles: [ROLES.USER, ROLES.MANAGER, ROLES.ADMINISTRATOR],
          isBlank: true,
        },
        {
          label: '患者サイト編集',
          subLabel:
            '患者用ページに表示するイメージ画像や、医院からのお知らせを編集します。',
          href: `/PatientSite`,
          icon: MdOutlineWeb,
          roles: [ROLES.USER, ROLES.MANAGER, ROLES.ADMINISTRATOR],
          isBlank: false,
        },
      ],
    },
  ];

  return (
    <Stack direction={'row'} spacing={4}>
      {NAV_ITEMS.map((navItem) => (
        <Box key={navItem.label}>
          <Popover trigger="click" placement="bottom-start">
            <PopoverTrigger>
              <Box role="group">
                <Flex
                  px="2"
                  transition="all .2s ease"
                  borderRadius="5"
                  _groupHover={{
                    bg: 'blue.600',
                    cursor: 'pointer',
                  }}
                >
                  <Text p="2" fontSize="sm" fontWeight="bold" color="white">
                    {navItem.label}
                  </Text>
                  <Icon as={MdExpandMore} color="white" mt="2" />
                </Flex>
              </Box>
            </PopoverTrigger>

            {navItem.children && (
              <PopoverContent
                border="0"
                boxShadow="xl"
                bg={popoverContentBgColor}
                p="4"
                rounded="xl"
                minW="sm"
                _focus={{ boxShadow: 'xl' }}
                _focusVisible={{ outline: 'none' }}
              >
                <Stack>
                  {navItem.children.map((child) => (
                    <DesktopSubNav key={child.label} {...child} />
                  ))}
                </Stack>
              </PopoverContent>
            )}
          </Popover>
        </Box>
      ))}
    </Stack>
  );
});

const DesktopSubNav = ({
  label,
  href,
  subLabel,
  icon,
  roles,
  isBlank,
}: NavItem) => {
  const { auth0Roles } = useGetMetadata();
  return roles
    ?.map((role) => auth0Roles.some((auth0Role) => role === auth0Role))
    .some((role) => role) ? (
    <Link
      href={href}
      role="group"
      display="block"
      p="2"
      rounded="md"
      target={isBlank ? '_blank' : '_self'}
      _hover={{ bg: 'blue.50' }}
      _focus={{ boxShadow: 'none' }}
    >
      <Flex direction="row">
        <Box>
          <Icon
            as={icon}
            fontWeight="500"
            transition="all .3s ease"
            _groupHover={{ color: 'blue.400' }}
            w="7"
            h="7"
            mx="5"
          />
        </Box>
        <VStack mx="2">
          <Box w="full">
            <Text
              fontWeight="500"
              transition="all .3s ease"
              _groupHover={{ color: 'blue.400' }}
            >
              {label}
            </Text>
          </Box>
          <Box w="full">
            <Text fontSize="sm" color="gray.500">
              {subLabel}
            </Text>
          </Box>
        </VStack>
      </Flex>
    </Link>
  ) : (
    <></>
  );
};
