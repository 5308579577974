import { Sex } from '../types/webInterview';

export const CLEAR_INTERVIEW_RESULTS = 'CLEAR_INTERVIEW_RESULTS';
export const ADD_INTERVIEW_RESULT = 'ADD_INTERVIEW_RESULT';
export const CHANGE_GUEST_INFO = 'CHANGE_GUEST_INFO';
export const CHANGE_ANSWER_ITEMS = 'CHANGE_ANSWER_ITEMS';

export type InterviewResultAction =
  | { type: 'CLEAR_INTERVIEW_RESULTS' }
  | {
      type: 'ADD_INTERVIEW_RESULT';
      interviewResultId: string;
      interviewSheetId: string;
      interviewSheetHistoryId: string;
      interviewSheetName: string;
    }
  | {
      type: 'CHANGE_GUEST_INFO';
      interviewResultId: string;
      isNew: boolean;
      pId: string;
      firstName: string;
      lastName: string;
      firstKana: string;
      lastKana: string;
      sex: Sex;
      birthDay: string;
      zipCode: string;
      streetAddress: string;
      phoneNumber: string;
      mailAddress: string;
      reservationDate: string;
    }
  | {
      type: 'CHANGE_ANSWER_ITEMS';
      interviewResultId: string;
      answers: {
        answerId: string;
        questionId: string;
        answerItems: {
          answerItemId: string;
          questionItemId: string;
          description: string;
          checked: boolean;
          choiceNotes: string;
        }[];
      }[];
    };
