import { InterviewResult } from '../types/InterviewResult';
import { PostInterviewResult } from '../types/interviewResultApiTypes';

export const toApiInterviewResult = (data: InterviewResult) => {
  const result: PostInterviewResult = {
    id: data.interviewResultId,
    pnumber: data.pnumber.toString(),
    pid: data.pid,
    confirmed_date: data.confirmedDate,
    reservation_date: data.reservationDate,
    last_name: data.lastName,
    first_name: data.firstName,
    last_kana: data.lastKana,
    first_kana: data.firstKana,
    sex: data.sex,
    birthday: data.birthday,
    zip_code: data.zipCode,
    street_address: data.streetAddress,
    phone_number: data.phoneNumber,
    mail_address: data.mailAddress,
    interview_sheet_id: data.interviewSheetId,
    interview_sheet_history_id: data.interviewSheetHistoryId,
    answers: data.answers.map(({ answerId, questionId, answerItems }) => ({
      id: answerId,
      question_id: questionId,
      answer_items: answerItems.map(
        ({
          answerItemId,
          questionItemId,
          description,
          checked,
          choiceNotes,
        }) => ({
          id: answerItemId,
          question_item_id: questionItemId,
          description: description,
          checked: checked,
          choice_notes: choiceNotes,
        }),
      ),
    })),
  };
  return result;
};
