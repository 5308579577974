import React, { useContext, memo } from 'react';
import { Text, IconButton, Flex, Select } from '@chakra-ui/react';
import { MdClear } from 'react-icons/md';
import { Row } from './Row';
import { QuestionContext } from '../../contexts/QuestionContext';
import { SetConditionContext } from '../../contexts/ConditionContext';
import { SetPrerequisiteContext } from '../../contexts/PrerequisiteContext';
import {
  CHANGE_QUESTION_REQUIRED,
  CHANGE_PREREQUISITE_QUESTION_ID,
} from '../../actions/conditionAction';
import { CHANGE_PREREQUISITE_QUESTION } from '../../actions/prerequisiteAction';

type Props = {
  questionId: string;
  conditionId: string;
  prerequisiteQuestionId: string;
};

export const ConditionQuestion: React.VFC<Props> = memo(
  ({ questionId, conditionId, prerequisiteQuestionId }) => {
    const { state } = useContext(QuestionContext);
    const { dispatch } = useContext(SetConditionContext);
    const { dispatch: prerequisiteDispath } = useContext(
      SetPrerequisiteContext,
    );
    const options = state
      .filter(
        (q) =>
          q.questionId !== questionId &&
          q.answerType !== 'textbox' &&
          q.answerType !== 'textline',
      )
      .map((q) => ({
        questionId: q.questionId,
        text: q.text,
      }));
    const handleOnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
      dispatch({
        type: CHANGE_PREREQUISITE_QUESTION_ID,
        questionId: e.target.value,
        conditionId,
      });
      prerequisiteDispath({
        type: CHANGE_PREREQUISITE_QUESTION,
        questionId: e.target.value,
        conditionId,
      });
    };

    return (
      <Row
        mainTitle={
          <Text color="gray.600" mt="2" align="right">
            質問
          </Text>
        }
        contents={
          <Flex>
            <Select
              placeholder="未設定"
              onChange={(e) => handleOnChange(e)}
              defaultValue={prerequisiteQuestionId}
              color="gray.600"
            >
              {options.map((option, idx) => (
                <option value={option.questionId} key={idx}>
                  {option.text}
                </option>
              ))}
            </Select>
          </Flex>
        }
        controlButton={
          <IconButton
            aria-label="delete condition"
            icon={<MdClear />}
            isRound={true}
            variant="outline"
            border="none"
            onClick={() =>
              dispatch({
                type: CHANGE_QUESTION_REQUIRED,
                conditionId: conditionId,
              })
            }
          />
        }
      />
    );
  },
);
