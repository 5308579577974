import React, { memo } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import {
  Box,
  IconButton,
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  MenuGroup,
  useBreakpointValue,
  useColorModeValue,
} from '@chakra-ui/react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { MdAccountCircle, MdOutlineLogout } from 'react-icons/md';
import { dateConvert } from '../../utils/dateConvert';

type Props = {};

export const UserMenu: React.VFC<Props> = memo(() => {
  const { user, logout } = useAuth0();
  const { auth0AppData } = useGetMetadata();
  const { previousLogin } = auth0AppData;

  const handleSignOut = () => {
    logout({ returnTo: process.env.REACT_APP_LOGOUT_URL });
  };

  return (
    <Menu>
      <MenuButton
        as={IconButton}
        aria-label="user"
        icon={<MdAccountCircle size="24" />}
        color="white"
        colorScheme="blue"
        size={useBreakpointValue({ base: 'sm', md: 'lg' })}
        isRound
      />
      <MenuList>
        <MenuGroup title={user ? user.name : ''} fontSize="md">
          <Box mx="4">
            <Text fontSize="sm" color="gray.600">
              前回ログイン
            </Text>
            <Text fontSize="sm" color="gray.600">
              {dateConvert(previousLogin.toString(), 'seconds')}
            </Text>
          </Box>
          <MenuDivider />
          <MenuItem
            icon={<MdOutlineLogout size="20" />}
            color="gray.600"
            onClick={handleSignOut}
            _hover={{
              bg: useColorModeValue('blue.50', 'gray.900'),
              color: 'blue.400',
            }}
            _focus={{ bg: 'blue.50', color: 'blue.400' }}
            transition="all .3s ease"
          >
            ログアウト
          </MenuItem>
        </MenuGroup>
      </MenuList>
    </Menu>
  );
});
