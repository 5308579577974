import {
  QuestionItemAction,
  ADD_QUESTION_ITEM,
  REMOVE_QUESTION_ITEM,
  CHANGE_DESCRIPTION_PREFIX,
  CHANGE_DESCRIPTION_SUFFIX,
  CHANGE_DESCRIPTION_PLACEHOLDER,
  CHANGE_HAS_CHOICE_NOTES,
  CHANGE_CHOICE_NOTES_PREFIX,
  CHANGE_CHOICE_NOTES_SUFFIX,
  CHANGE_CHOICE_NOTES_PLACEHOLDER,
  CHANGE_CHOICE_TEXT,
  CHANGE_TAGS,
  CHANGE_CHOICE_SORT_NUMBER,
  GET_INTERVIEWSHEET,
  REMOVE_QUESTION_ITEMS,
} from '../actions/questionItemAction';
import { QuestionItem } from '../types/questionItem';
import { newId } from '../domains/newId';

export const defaultQuestionItem: QuestionItem = {
  questionItemId: '',
  choiceText: '',
  hasChoiceNotes: false,
  choiceNotesPrefix: '',
  choiceNotesSuffix: '',
  choicePlaceholder: '',
  descriptionPrefix: '',
  descriptionSuffix: '',
  descriptionPlaceholder: '',
  sortNumber: 1,
  tags: [],
  questionId: '',
};

export const initialState: QuestionItem[] = [];

export const questionItemReducer = (
  state = initialState,
  action: QuestionItemAction,
) => {
  switch (action.type) {
    case ADD_QUESTION_ITEM:
      const questionItemId = newId();
      const { sortNumber } = state.reduce(
        (previous: QuestionItem, current: QuestionItem) =>
          previous.sortNumber > current.sortNumber ? previous : current,
        defaultQuestionItem,
      );
      return [
        ...state,
        {
          ...defaultQuestionItem,
          questionId: action.questionId,
          questionItemId,
          sortNumber,
        },
      ];
    case REMOVE_QUESTION_ITEM:
      return state.filter(
        (item) => item.questionItemId !== action.questionItemId,
      );
    case CHANGE_CHOICE_TEXT:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, choiceText: action.text }
          : item,
      );
    case CHANGE_DESCRIPTION_PREFIX:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, descriptionPrefix: action.prefix }
          : item,
      );
    case CHANGE_DESCRIPTION_SUFFIX:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, descriptionSuffix: action.suffix }
          : item,
      );
    case CHANGE_DESCRIPTION_PLACEHOLDER:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, descriptionPlaceholder: action.placeholder }
          : item,
      );
    case CHANGE_HAS_CHOICE_NOTES:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, hasChoiceNotes: action.hasNotes }
          : item,
      );
    case CHANGE_CHOICE_NOTES_PREFIX:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, choiceNotesPrefix: action.prefix }
          : item,
      );
    case CHANGE_CHOICE_NOTES_SUFFIX:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, choiceNotesSuffix: action.suffix }
          : item,
      );
    case CHANGE_CHOICE_NOTES_PLACEHOLDER:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, choicePlaceholder: action.placeholder }
          : item,
      );
    case CHANGE_CHOICE_SORT_NUMBER:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, sortNumber: action.sortNumber }
          : item,
      );
    case CHANGE_TAGS:
      return state.map((item) =>
        item.questionItemId === action.questionItemId
          ? { ...item, tags: action.tags }
          : item,
      );
    case GET_INTERVIEWSHEET:
      return action.data;
    case REMOVE_QUESTION_ITEMS:
      return initialState;
    default:
      return state;
  }
};
