import React, {
  useState,
  useContext,
  useEffect,
  useCallback,
  memo,
} from 'react';
import { CheckboxGroup, VStack, Checkbox } from '@chakra-ui/react';
import { QuestionItemContext } from '../../contexts/QuestionItemContext';
import {
  PrerequisiteContext,
  SetPrerequisiteContext,
} from '../../contexts/PrerequisiteContext';
import {
  ADD_PREREQUISITE_ITEM,
  REMOVE_PREREQUISITE_ITEM,
} from '../../actions/prerequisiteAction';
import { Row } from './Row';

type Props = {
  conditionId: string;
  prerequisiteQuestionId: string;
};

type QuestionItem = {
  questionItemId: string;
  choiceText: string;
  checked: boolean;
  prerequisiteId: string;
};

export const ConditionPrerequisiteItems: React.VFC<Props> = memo(
  ({ conditionId, prerequisiteQuestionId }) => {
    const { state: questionItemState } = useContext(QuestionItemContext);
    const { state: prerequisiteState } = useContext(PrerequisiteContext);
    const { dispatch } = useContext(SetPrerequisiteContext);
    const [questionItems, setQuestionItems] = useState<QuestionItem[]>(
      questionItemState
        .filter((qi) => qi.questionId === prerequisiteQuestionId)
        .map((qi) => ({
          questionItemId: qi.questionItemId,
          choiceText: qi.choiceText,
          checked: prerequisiteState.some(
            (p) =>
              p.conditionId === conditionId &&
              p.questionItemId === qi.questionItemId,
          ),
          prerequisiteId:
            prerequisiteState.find(
              (p) =>
                p.conditionId === conditionId &&
                p.questionItemId === qi.questionItemId,
            )?.prerequisiteId ?? '',
        })),
    );

    const handleOnChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>, questionItem: QuestionItem) => {
        if (e.target.checked) {
          setQuestionItems(
            questionItems.map((qi) =>
              qi.questionItemId === questionItem.questionItemId
                ? { ...qi, checked: true }
                : qi,
            ),
          );
          dispatch({
            type: ADD_PREREQUISITE_ITEM,
            conditionId,
            questionId: prerequisiteQuestionId,
            questionItemId: e.target.value,
          });
        } else {
          setQuestionItems(
            questionItems.map((qi) =>
              qi.questionItemId === questionItem.questionItemId
                ? { ...qi, checked: false }
                : qi,
            ),
          );
          dispatch({
            type: REMOVE_PREREQUISITE_ITEM,
            prerequisiteId: questionItem.prerequisiteId,
          });
        }
      },
      [dispatch, conditionId, questionItems, prerequisiteQuestionId],
    );

    useEffect(() => {
      setQuestionItems(
        questionItemState
          .filter((qi) => qi.questionId === prerequisiteQuestionId)
          .map((qi) => ({
            questionItemId: qi.questionItemId,
            choiceText: qi.choiceText,
            checked: prerequisiteState.some(
              (p) =>
                p.conditionId === conditionId &&
                p.questionItemId === qi.questionItemId,
            ),
            prerequisiteId:
              prerequisiteState.find(
                (p) =>
                  p.conditionId === conditionId &&
                  p.questionItemId === qi.questionItemId,
              )?.prerequisiteId ?? '',
          })),
      );
    }, [
      questionItemState,
      prerequisiteQuestionId,
      prerequisiteState,
      conditionId,
    ]);

    return (
      <Row
        contents={
          <CheckboxGroup
            colorScheme="webInterview"
            value={questionItems
              .filter((item) => item.checked)
              .map((item) => item['questionItemId'])}
          >
            <VStack align="left">
              {questionItems.map((questionItem, idx) => (
                <Checkbox
                  value={questionItem.questionItemId}
                  key={idx}
                  isChecked={questionItem.checked}
                  onChange={(e) => handleOnChange(e, questionItem)}
                  color="gray.600"
                >
                  {questionItem.choiceText}
                </Checkbox>
              ))}
            </VStack>
          </CheckboxGroup>
        }
      />
    );
  },
);
