import React from 'react';
import { Box } from '@chakra-ui/react';
import { Answer, Question } from '../../types/interviewResultConfirm';
import { InterviewResultQuestion } from '../InterviewResult/InterviewResultQuestion';

type Props = {
  answers: Answer[];
  questions: Question[];
};

export const Questions: React.VFC<Props> = ({ answers, questions }) => {
  return (
    <Box mt="4" py="3" borderTopWidth="1px">
      {questions ? (
        questions.map((q, idx) => (
          <InterviewResultQuestion key={idx} question={q} answers={answers} />
        ))
      ) : (
        <></>
      )}
    </Box>
  );
};
