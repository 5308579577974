import React, { useContext, memo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Text,
  TextProps,
  Input,
  FormControl,
  FormErrorMessage,
} from '@chakra-ui/react';
import { Paper } from '../../components/atoms/Paper';
import { Row } from './Row';
import {
  InterviewSheetContext,
  SetInterviewSheetContext,
} from '../../contexts/InterviewSheetContext';
import { CHANGE_NAME } from '../../actions/interviewSheetAction';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';

export const Title: React.VFC<TextProps> = memo((props) => {
  const { state } = useContext(InterviewSheetContext);
  const { dispatch } = useContext(SetInterviewSheetContext);
  const urlParams = useParams<{ id: string }>();
  const { interviewSheetId, name } =
    state.find((s) => s.interviewSheetId === urlParams.id) ??
    defaultInterviewSheet;
  const [title, setTitle] = useState(name);
  const handleChangeTitle = (name: string) => {
    dispatch({
      type: CHANGE_NAME,
      interviewSheetId,
      name,
    });
  };
  const isError = title.length > 20;

  return (
    <Paper px="4" py="3">
      <Row
        mainTitle={<Text {...props}>{'タイトル'}</Text>}
        contents={
          <FormControl isInvalid={isError}>
            <Input
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              onBlur={() => handleChangeTitle(title)}
              color={props.color}
              maxLength={20}
            />
            {!isError ? (
              <></>
            ) : (
              <FormErrorMessage>
                タイトルは20文字以内で入力してください
              </FormErrorMessage>
            )}
          </FormControl>
        }
      />
    </Paper>
  );
});
