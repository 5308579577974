import {
  TagAction,
  ADD_TAG,
  CHANGE_ENABLE,
  CHANGE_NAME,
  SEARCH_NAME,
  CHANGE_FILTERED,
  CHANGE_EDITING,
  GET_TAGS,
} from '../actions/tagAction';
import { Tag } from '../types/tag';
import { newId } from '../domains/newId';

export const defaultTag: Tag = {
  tagId: '',
  name: '',
  enable: true,
  isSystemOnly: false,
  filtered: false,
  editing: false,
  version: 1,
};

export const initialState: Tag[] = [];
export const tagReducer = (state = initialState, action: TagAction) => {
  switch (action.type) {
    case ADD_TAG:
      return [...state, { ...defaultTag, tagId: newId('Tag') }];
    case CHANGE_ENABLE:
      return state.map((t) =>
        t.tagId === action.tagId
          ? {
              ...t,
              enable: action.enable,
            }
          : t,
      );
    case CHANGE_NAME:
      return state.map((t) =>
        t.tagId === action.tagId
          ? {
              ...t,
              name: action.name,
            }
          : t,
      );
    case SEARCH_NAME:
      return state.filter((t) => t.name.indexOf(action.name) > -1);
    case CHANGE_FILTERED:
      return state.map((t) =>
        t.tagId === action.tagId
          ? {
              ...t,
              filtered: action.fileted,
            }
          : t,
      );
    case CHANGE_EDITING:
      return state.map((t) =>
        t.tagId === action.tagId
          ? {
              ...t,
              editing: !t.editing,
            }
          : t,
      );
    case GET_TAGS:
      return action.data;
    default:
      return state;
  }
};
