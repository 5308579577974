import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Flex,
  Heading,
  useToast,
  Text,
} from '@chakra-ui/react';
import { Status } from '../../types/webInterview';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { toApiPersonalInformation } from '../../domains/toInterviewResultConfirm';
import { PersonalInformation } from '../../types/personalInformation';
import { ModalDialog } from '../../components/ModalDialog';
import { getInterviewResult, putInterviewResult } from '../../api/index';
import { GetInterviewResult } from '../../types/interviewResultConfirmApiTypes';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  personalInformation: PersonalInformation;
  save: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const IdentifiedDialog: React.VFC<Props> = ({
  isOpen,
  onClose,
  personalInformation,
  save,
}) => {
  const [loading, setLoading] = useState(false);
  const { id } = useParams<{ id: string }>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const toast = useToast();

  const status = personalInformation.status;

  const handleChangeStatus = async (status: Status) => {
    const putData = async (): Promise<void> => {
      try {
        setLoading(true);
        const getAccessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:interview-results',
        });
        const statusData = await getInterviewResult(
          tenantId,
          id,
          userId,
          userName,
          getAccessToken,
        );
        if (statusData.ok) {
          const resultData: GetInterviewResult = await statusData.json();
          if (resultData.status === 'deleted') {
            toast({
              title: 'エラー',
              description: '問診結果が無効に変更されています',
              status: 'error',
              duration: 5000,
              isClosable: true,
            });
            save.toggle();
            onClose();
          } else {
            const putAccessToken = await getAccessTokenSilently({
              audience: 'https://www.webinterview.mic.jp/',
              scope: 'update:interview-results',
            });
            const result = await putInterviewResult(
              tenantId,
              id,
              userId,
              userName,
              putAccessToken,
              toApiPersonalInformation({
                ...personalInformation,
                status: status,
                pnumber:
                  status === 'unidentified' ? '0' : personalInformation.pnumber,
              }),
            );
            if (result.ok) {
              toast({
                title: '保存完了',
                description:
                  status === 'unidentified'
                    ? '本人確認を解除しました。'
                    : '本人確認を確定しました',
                status: 'success',
                duration: 5000,
                isClosable: true,
              });
              save.toggle();
              onClose();
            } else {
              throw new Error(result.status.toString());
            }
          }
        } else {
          throw new Error(statusData.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        console.error({ error });
        onClose();
      } finally {
        setLoading(false);
      }
    };
    putData();
  };
  return (
    <>
      <ModalDialog
        header={
          loading ? (
            <></>
          ) : (
            <Heading size="md" color="gray.600">
              本人確認
            </Heading>
          )
        }
        body={
          loading ? (
            <Flex w="full" h="min" justifyContent="center" alignItems="center">
              <CircularProgress isIndeterminate color="blue.300" />
            </Flex>
          ) : (
            <Box>
              <Text color="gray.600" mb="2">
                本人確認状態を{status === 'unidentified' ? '確定' : '解除'}
                します。よろしいですか？
              </Text>
            </Box>
          )
        }
        footer={
          loading ? (
            <></>
          ) : (
            <Box>
              <Button
                colorScheme={status === 'unidentified' ? 'blue' : 'red'}
                onClick={() =>
                  handleChangeStatus(
                    status === 'unidentified' ? 'identified' : 'unidentified',
                  )
                }
                mr={3}
              >
                {status === 'unidentified' ? '確定' : '解除'}
              </Button>
              <Button onClick={onClose}>キャンセル</Button>
            </Box>
          )
        }
        isOpen={isOpen}
        onClose={onClose}
        size="md"
      />
    </>
  );
};
