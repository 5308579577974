import { Tag } from '../types/tag';

export const ADD_TAG = 'ADD_TAG';
export const CHANGE_NAME = 'CHANGE_NAME';
export const CHANGE_ENABLE = 'CHANGE_ENABLE';
export const GET_TAGS = 'GET_TAGS';
export const GET_TAG = 'GET_TAG';
export const SEARCH_NAME = 'SEARCH_NAME';
export const CHANGE_FILTERED = 'CHANGE_FILTERED';
export const CHANGE_EDITING = 'CHANGE_EDITING';

export type TagAction =
  | {
      type: 'ADD_TAG';
    }
  | {
      type: 'CHANGE_NAME';
      tagId: string;
      name: string;
    }
  | {
      type: 'CHANGE_ENABLE';
      tagId: string;
      enable: boolean;
    }
  | { type: 'GET_TAGS'; data: Tag[] }
  | { type: 'GET_TAG'; data: Tag }
  | { type: 'SEARCH_NAME'; name: string }
  | { type: 'CHANGE_FILTERED'; tagId: string; fileted: boolean }
  | { type: 'CHANGE_EDITING'; tagId: string };
