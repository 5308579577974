import {
  InterviewSheetAction,
  GET_INTERVIEWSHEETS,
  CHANGE_NAME,
  CHANGE_DESCRIPTION,
  CHANGE_RELEASE_DATE,
  CHANGE_CONDITION_PID,
  CHANGE_CONDITION_ZIP_CODE,
  CHANGE_CONDITION_STREET_ADDRESS,
  CHANGE_CONDITION_MAIL_ADDRESS,
  ADD_INTERVIEW_SHEET,
  GET_INTERVIEWSHEET,
  REMOVE_INTERVIEWSHEETS,
} from '../actions/interviewSheetAction';
import { InterviewSheet } from '../types/interviewSheet';

export const defaultInterviewSheet: InterviewSheet = {
  interviewSheetId: '',
  name: '',
  description: '',
  releaseDate: '2100-01-31T15:00',
  conditionPid: 'required',
  conditionZipCode: 'required',
  conditionStreetAddress: 'required',
  conditionMailAddress: 'required',
  createdAt: '1900-01-31T15:00',
  updatedAt: '1900-01-31T15:00',
  createdBy: '',
  updatedBy: '',
  displayPriority: 0,
  version: 1,
  isNew: false,
};

export const initialState: InterviewSheet[] = [];

export const interviewSheetReducer = (
  state = initialState,
  action: InterviewSheetAction,
) => {
  switch (action.type) {
    case GET_INTERVIEWSHEETS:
      return action.data;
    case GET_INTERVIEWSHEET:
      return [action.data];
    case ADD_INTERVIEW_SHEET:
      return [
        ...state,
        {
          ...defaultInterviewSheet,
          interviewSheetId: action.interviewSheetId,
          isNew: true,
        },
      ];
    case CHANGE_NAME:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              name: action.name,
            }
          : sheet,
      );
    case CHANGE_DESCRIPTION:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              description: action.description,
            }
          : sheet,
      );
    case CHANGE_RELEASE_DATE:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              releaseDate: action.releaseDate,
            }
          : sheet,
      );
    case CHANGE_CONDITION_PID:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              conditionPid: action.conditionPid,
            }
          : sheet,
      );
    case CHANGE_CONDITION_ZIP_CODE:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              conditionZipCode: action.conditionZipCode,
            }
          : sheet,
      );
    case CHANGE_CONDITION_STREET_ADDRESS:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              conditionStreetAddress: action.conditionStreetAddress,
            }
          : sheet,
      );
    case CHANGE_CONDITION_MAIL_ADDRESS:
      return state.map((sheet) =>
        sheet.interviewSheetId === action.interviewSheetId
          ? {
              ...sheet,
              conditionMailAddress: action.conditionMailAddress,
            }
          : sheet,
      );
    case REMOVE_INTERVIEWSHEETS:
      return initialState;
    default:
      return state;
  }
};
