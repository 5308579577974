import React from 'react';
import { Box } from '@chakra-ui/layout';
import { Header } from './Header';
import { Footer } from './Footer';

type Props = {
  children: React.ReactNode;
};

export const Layout: React.VFC<Props> = ({ children }) => {
  const headerHeight = '4rem';

  return (
    <Box bg="gray.50" minH="100vH">
      <Header height={headerHeight} />
      <Box maxW="1000px" align="center" mx="auto">
        <Box mt={headerHeight} pt={8} minH="65vH">
          {children}
        </Box>
      </Box>
      <Footer />
    </Box>
  );
};
