import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Input,
  InputLeftElement,
  InputGroup,
  Checkbox,
  Text,
  useBoolean,
  CircularProgress,
  Flex,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { MdSearch } from 'react-icons/md';
import { ModalDialog } from '../../components/ModalDialog';
import { BUTTON } from '../../components/atoms/Button';
import { TagRow } from './TagRow';
import { TagRowEdit } from './TagRowEdit';
import { Paper } from '../../components/atoms/Paper';
import { TagContext, SetTagContext } from '../../contexts/TagContext';
import { defaultTag } from '../../reducers/tagReducer';
import { TagEditButtons } from './TagEditButtons';
import { GetTags } from '../../types/tagApiTypes';
import {
  CHANGE_FILTERED,
  CHANGE_EDITING,
  GET_TAGS,
} from '../../actions/tagAction';
import { toTags } from '../../domains/toTags';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getTags } from '../../api';

type Props = {
  isOpen: boolean;
  onClose: () => void;
};

export const TagEditModalDialog: React.VFC<Props> = ({ isOpen, onClose }) => {
  const { state } = useContext(TagContext);
  const { dispatch } = useContext(SetTagContext);
  const [editing, setEditing] = useBoolean();
  const [showDeletedTags, setShowDeletedTags] = useBoolean();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const [loadingCircle, setLoadingCircle] = useState(false);
  const [loaded, setLoaded] = useBoolean(false);

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoadingCircle(true);
        const userId = user?.sub ?? '';
        const userName = encodeURI(user?.name ?? '');
        const accessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:tags',
        });
        const result = await getTags(tenantId, userId, userName, accessToken);
        if (result.ok) {
          const data: GetTags = await result.json();
          dispatch({ type: GET_TAGS, data: toTags(data) });
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoadingCircle(false);
      }
    };
    getData();
  }, [tenantId, user, dispatch, loaded, getAccessTokenSilently]);

  const handleClose = () => {
    setShowDeletedTags.off();
    setEditing.off();
    onClose();
  };

  return (
    <ModalDialog
      header={
        <>
          <InputGroup mb="2">
            <InputLeftElement
              pointerEvents="none"
              children={<MdSearch size="24px" />}
              color="gray.400"
            />
            <Input
              placeholder="タグを検索"
              color="gray.600"
              onChange={(e) => {
                state.forEach(({ tagId, name }) => {
                  dispatch({
                    type: CHANGE_FILTERED,
                    tagId,
                    fileted: name.indexOf(e.target.value) === -1,
                  });
                });
              }}
            />
          </InputGroup>
          <Checkbox
            checked={showDeletedTags}
            onChange={setShowDeletedTags.toggle}
            colorScheme="webInterview"
          >
            <Text color="gray.600" fontWeight="normal">
              削除済みのタグを表示する
            </Text>
          </Checkbox>
          <Box mb="2" align="end">
            <BUTTON text="タグを追加" onClick={setEditing.on} />
          </Box>
          {editing ? (
            <Paper p="3">
              <TagRowEdit
                tag={defaultTag}
                handleCancel={setEditing.off}
                setLoaded={setLoaded}
                setEditing={setEditing}
                setLoadingCircle={setLoadingCircle}
              />
            </Paper>
          ) : (
            <></>
          )}
        </>
      }
      body={
        <>
          {loadingCircle ? (
            <Flex w="full" h="min" justifyContent="center" alignItems="center">
              <CircularProgress isIndeterminate color="blue.300" />
            </Flex>
          ) : (
            state
              .filter(({ filtered }) => !filtered)
              .map((tag, idx) =>
                tag.enable || showDeletedTags ? (
                  <TagRow
                    tag={tag}
                    key={idx}
                    buttonArea={
                      <TagEditButtons
                        tag={tag}
                        editing={tag.editing}
                        setLoaded={setLoaded}
                        handleEdit={() =>
                          dispatch({
                            type: CHANGE_EDITING,
                            tagId: tag.tagId,
                          })
                        }
                        setLoadingCircle={setLoadingCircle}
                      />
                    }
                    editArea={
                      tag.editing ? (
                        <Box mb="1">
                          <TagRowEdit
                            tag={tag}
                            handleCancel={() =>
                              dispatch({
                                type: CHANGE_EDITING,
                                tagId: tag.tagId,
                              })
                            }
                            setLoaded={setLoaded}
                            setLoadingCircle={setLoadingCircle}
                          />
                        </Box>
                      ) : (
                        <></>
                      )
                    }
                  />
                ) : (
                  <Box key={idx}></Box>
                ),
              )
          )}
        </>
      }
      isOpen={isOpen}
      onClose={handleClose}
      scrollBehavior="inside"
    />
  );
};
