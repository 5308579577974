import React from 'react';
import { Route } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

export function ProtectedRoute({ component, ...args }: any) {
  return (
    <Route
      exact
      component={withAuthenticationRequired(component, {})}
      {...args}
    />
  );
}
