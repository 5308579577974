import React, { useReducer } from 'react';
import {
  ConditionContext,
  SetConditionContext,
} from '../contexts/ConditionContext';
import { conditionReducer, initialState } from '../reducers/conditionReducer';

type Props = {
  children: React.ReactNode;
};

export const ConditionProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(conditionReducer, initialState);
  return (
    <ConditionContext.Provider value={{ state }}>
      <SetConditionContext.Provider value={{ dispatch }}>
        {children}
      </SetConditionContext.Provider>
    </ConditionContext.Provider>
  );
};
