import {
  QuestionAction,
  ADD_QUESTION,
  REMOVE_QUESTION,
  CHANGE_ANSWER_TYPE,
  CHANGE_QUESTION_TEXT,
  CHANGE_QUESTION_REQUIRED,
  CHANGE_QUESTION_SORT_NUMBER,
  CHANGE_HAS_CONDITION,
  GET_INTERVIEWSHEET,
  REMOVE_QUESTIONS,
} from '../actions/questionAction';
import { Question } from '../types/question';
import { newId } from '../domains/newId';

export const defaultQuestion: Question = {
  questionId: '',
  interviewSheetId: '',
  answerType: 'textbox',
  text: '',
  required: false,
  sortNumber: 1,
  hasCondition: false,
};

export const initialState: Question[] = [];

export const questionReducer = (
  state = initialState,
  action: QuestionAction,
) => {
  switch (action.type) {
    case ADD_QUESTION:
      const questionId = newId();
      const newSortNumber =
        state.reduce(
          (previous: Question, current: Question) =>
            previous.sortNumber > current.sortNumber ? previous : current,
          { ...defaultQuestion, sortNumber: 0 },
        ).sortNumber + 1;
      return [
        ...state,
        {
          ...defaultQuestion,
          questionId,
          interviewSheetId: action.interviewSheetId,
          sortNumber: newSortNumber,
        },
      ];
    case REMOVE_QUESTION:
      return state.filter(
        (question) => question.questionId !== action.questionId,
      );
    case CHANGE_ANSWER_TYPE:
      return state.map((question) =>
        question.questionId === action.questionId
          ? { ...question, answerType: action.answerType }
          : question,
      );
    case CHANGE_QUESTION_TEXT:
      return state.map((question) =>
        question.questionId === action.questionId
          ? { ...question, text: action.questionText }
          : question,
      );
    case CHANGE_QUESTION_REQUIRED:
      return state.map((question) =>
        question.questionId === action.questionId
          ? { ...question, required: !question.required }
          : question,
      );
    case CHANGE_QUESTION_SORT_NUMBER:
      return state.map((question) =>
        question.questionId === action.questionId
          ? { ...question, sortNumber: action.sortNumber }
          : question,
      );
    case CHANGE_HAS_CONDITION:
      return state.map((question) =>
        question.questionId === action.questionId
          ? { ...question, hasCondition: !question.hasCondition }
          : question,
      );
    case GET_INTERVIEWSHEET:
      return action.data;
    case REMOVE_QUESTIONS:
      return initialState;
    default:
      return state;
  }
};
