import React from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
} from '@chakra-ui/react';
import { MdOutlineFactCheck } from 'react-icons/md';
import { PersonalInformation } from '../../types/personalInformation';
import { IdentifiedDialog } from './IdentifiedDialog';
import { DeleteDialog } from './DeleteDialog';

type Props = {
  personalInformation: PersonalInformation;
  save: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  menuDisabled: boolean;
};

export const IdentificationMenu: React.VFC<Props> = ({
  personalInformation,
  save,
  menuDisabled,
}) => {
  const {
    isOpen: identifiedIsOpen,
    onOpen: identifiedOnOpen,
    onClose: identifiedOnClose,
  } = useDisclosure();
  const {
    isOpen: deleteIsOpen,
    onOpen: deleteOnOpen,
    onClose: deleteOnClose,
  } = useDisclosure();

  const status = personalInformation.status;
  return (
    <>
      <Menu>
        <MenuButton
          as={Button}
          leftIcon={<MdOutlineFactCheck size="24px" />}
          colorScheme="webInterview"
          disabled={menuDisabled}
        >
          本人確認
        </MenuButton>
        <MenuList>
          <MenuItem
            onClick={identifiedOnOpen}
            isDisabled={status === 'identified' || status === 'deleted'}
          >
            確認済みにする
          </MenuItem>
          <MenuItem
            onClick={identifiedOnOpen}
            isDisabled={status === 'unidentified' || status === 'deleted'}
          >
            確認済みを解除する
          </MenuItem>
          <MenuItem onClick={deleteOnOpen} isDisabled={status === 'deleted'}>
            無効にする
          </MenuItem>
        </MenuList>
      </Menu>
      <IdentifiedDialog
        isOpen={identifiedIsOpen}
        onClose={identifiedOnClose}
        personalInformation={personalInformation}
        save={save}
      />
      <DeleteDialog
        isOpen={deleteIsOpen}
        onClose={deleteOnClose}
        personalInformation={personalInformation}
        save={save}
      />
    </>
  );
};
