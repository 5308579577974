import React, { useContext, useCallback, memo } from 'react';
import {
  Text,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Select,
  IconButton,
} from '@chakra-ui/react';
import { Row } from './Row';
import { AgeOperator } from '../../types/webInterview';
import { MdClear } from 'react-icons/md';
import {
  ConditionContext,
  SetConditionContext,
} from '../../contexts/ConditionContext';
import {
  CHANGE_AGE_REQUIRED,
  CHANGE_AGE,
  CHANGE_AGE_OPERATOR,
} from '../../actions/conditionAction';
import { defaultCondition } from '../../reducers/conditionReducer';

type Props = {
  conditionId: string;
};

export const ConditionAge: React.VFC<Props> = memo(({ conditionId }) => {
  const { state } = useContext(ConditionContext);
  const { dispatch } = useContext(SetConditionContext);
  const condition =
    state.find((c) => c.conditionId === conditionId) ?? defaultCondition;
  const handleChangeAge = useCallback(
    (value: string) => {
      dispatch({
        type: CHANGE_AGE,
        conditionId: conditionId,
        age: Number(value),
      });
    },
    [dispatch, conditionId],
  );

  return (
    <Row
      mainTitle={
        <Text color="gray.600" mt="2" align="right">
          年齢
        </Text>
      }
      contents={
        <Flex>
          <NumberInput
            value={condition.age}
            min={0}
            max={110}
            onChange={(value) => handleChangeAge(value)}
            color="gray.600"
          >
            <NumberInputField />
            <NumberInputStepper>
              <NumberIncrementStepper />
              <NumberDecrementStepper />
            </NumberInputStepper>
          </NumberInput>
          <Text color="gray.600" mt="2" mx="3">
            歳
          </Text>
          <Select
            defaultValue={condition.ageOperator}
            onChange={(e) =>
              dispatch({
                type: CHANGE_AGE_OPERATOR,
                conditionId: conditionId,
                ageOperator: e.target.value as AgeOperator,
              })
            }
            color="gray.600"
          >
            <option value="greater">以上</option>
            <option value="less">以下</option>
          </Select>
        </Flex>
      }
      controlButton={
        <IconButton
          aria-label="delete condition"
          icon={<MdClear />}
          isRound={true}
          variant="outline"
          border="none"
          onClick={() =>
            dispatch({
              type: CHANGE_AGE_REQUIRED,
              conditionId: conditionId,
            })
          }
        />
      }
    />
  );
});
