export const dateConvert = (
  data: string,
  type: 'date' | 'minutes' | 'seconds',
  delimiter?: 'T',
  jst?: '+09:00' | '%2B09:00',
) => {
  const date = new Date(data);

  const convertDate = `${date.getFullYear()}-${(
    '00' +
    (date.getMonth() + 1)
  ).slice(-2)}-${('00' + date.getDate()).slice(-2)}`;

  const convertDateTime =
    type === 'minutes' || type === 'seconds'
      ? `${('00' + date.getHours()).slice(-2)}:${(
          '00' + date.getMinutes()
        ).slice(-2)}`
      : '';

  const convertSeconds =
    type === 'seconds' ? `:${('00' + date.getSeconds()).slice(-2)}` : '';

  return (
    convertDate +
    (type !== 'date' ? delimiter ?? ' ' : '') +
    convertDateTime +
    convertSeconds +
    (type === 'seconds' ? jst ?? '' : '')
  );
};
