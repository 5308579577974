import React, { createContext } from 'react';
import { InterviewSheetAction } from '../actions/interviewSheetAction';
import { InterviewSheet } from '../types/interviewSheet';

export const InterviewSheetContext = createContext(
  {} as {
    state: InterviewSheet[];
  },
);

export const SetInterviewSheetContext = createContext(
  {} as {
    dispatch: React.Dispatch<InterviewSheetAction>;
  },
);
