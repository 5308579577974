import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  CircularProgress,
  Text,
} from '@chakra-ui/react';
import { getClinicNotice } from '../api/index';
import { ClinicNotice } from '../types/clinicNotice';

type Props = {
  tenantId: string;
};

export const NoticeMobile: React.VFC<Props> = ({ tenantId }) => {
  const [loading, setLoading] = useState(false);
  const [notice, setNotice] = useState<ClinicNotice>({
    tenantId: '',
    contents: [],
  });

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoading(true);
        const result = await getClinicNotice(tenantId);
        if (result.ok) {
          const data: ClinicNotice = await result.json();
          if (data) {
            setNotice(data);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [tenantId]);

  return loading ? (
    <CircularProgress isIndeterminate color="blue.300" />
  ) : (
    <Box bgColor="white">
      <Accordion
        allowMultiple
        defaultIndex={notice.contents.map((_, idx) => idx)}
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box flex="1" textAlign="left">
                <Text color="gray.600" fontWeight="bold">
                  医院からのお知らせ
                </Text>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel>
            {notice.contents.map((content, idx) => (
              <Box key={idx}>
                <hr />
                {content?.split(/\n/).map((line, idx) => (
                  <Text color="gray.600" align="start" key={idx}>
                    {line}
                  </Text>
                ))}
              </Box>
            ))}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Box>
  );
};
