import React from 'react';
import {
  Box,
  Text,
  IconButton,
  HStack,
  Link,
  Tooltip,
  useToast,
} from '@chakra-ui/react';
import { Link as ReactRouterLink } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { MdEdit, MdFileCopy } from 'react-icons/md';
import { createPostData } from './createPostData';
import { PostInterviewSheet } from '../../types/interviewSheetApiTypes';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { getInterviewSheet, postInterviewSheet } from '../../api';
import { dateConvert } from '../../utils/dateConvert';
import { ReleaseBadge } from './ReleaseBadge';

type Props = {
  id: string;
  title: string;
  summary: string;
  updatedAt: string;
  releaseDate: string;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setReload: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
};

export const InterviewSheetCard: React.VFC<Props> = ({
  id,
  title,
  summary,
  updatedAt,
  releaseDate,
  setLoading,
  setReload,
}) => {
  const toast = useToast();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const userId = user?.sub ?? '';
  const userName = user?.name ?? '';
  const { tenantId } = auth0UserData;

  const handleCopy = async () => {
    try {
      setLoading(true);
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'create:interview-sheets',
      });
      // get
      const result = await getInterviewSheet(tenantId, id);
      if (result.ok) {
        const data: PostInterviewSheet = createPostData(await result.json());
        // post
        const response = await postInterviewSheet(
          tenantId,
          userId,
          encodeURI(userName),
          accessToken,
          data,
        );
        if (response.ok) {
          toast({
            title: '問診票のコピーを作成しました',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
        } else {
          throw new Error(result.status.toString());
        }
      } else {
        throw new Error(result.status.toString());
      }
    } catch (error) {
      toast({
        title: `エラー {${error}}`,
        description: (
          <>
            <Text>エラーが発生しました。</Text>
            <Text>恐れ入りますが、再度操作をやり直してください。</Text>
          </>
        ),
        status: 'error',
        duration: 8000,
        isClosable: true,
      });
      console.error(error);
    } finally {
      setReload.toggle();
    }
  };
  return (
    <Box
      p="6"
      borderWidth="1px"
      borderRadius="lg"
      background="white"
      shadow="lg"
    >
      <Text
        fontSize="2xl"
        fontWeight="bold"
        color="blue.600"
        align="left"
        maxH="16"
        minH="16"
      >
        {title}
      </Text>
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="gray.600"
        align="left"
        mt="3"
        mb="2"
        maxH="16"
        minH="16"
      >
        {summary.length > 20 ? summary.substring(0, 20) + '...' : summary}
      </Text>
      <Box w="full" align="left">
        <ReleaseBadge releaseDate={new Date(releaseDate)} />
      </Box>
      <HStack align="start" spacing="3" my="3">
        <Link as={ReactRouterLink} to={`/InterviewSheets/${id}`}>
          <Tooltip label="問診票を編集">
            <IconButton
              aria-label="edit"
              icon={<MdEdit />}
              colorScheme="webInterview"
              fontSize="24px"
            />
          </Tooltip>
        </Link>
        <Tooltip label="問診票をコピーして追加">
          <IconButton
            aria-label="copy"
            icon={<MdFileCopy />}
            colorScheme="webInterview"
            fontSize="24px"
            onClick={handleCopy}
          />
        </Tooltip>
      </HStack>
      <Box align="left" mt="1">
        <Text color="gray.600" fontWeight="medium">
          公開日：{dateConvert(releaseDate, 'minutes')}
        </Text>
        <Text color="gray.600" mt="1" fontWeight="medium">
          更新日：{dateConvert(updatedAt, 'seconds')}
        </Text>
      </Box>
    </Box>
  );
};
