import React, { useReducer } from 'react';
import { TagContext, SetTagContext } from '../contexts/TagContext';
import { tagReducer, initialState } from '../reducers/tagReducer';

type Props = {
  children: React.ReactNode;
};

export const TagProvider: React.VFC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(tagReducer, initialState);
  return (
    <TagContext.Provider value={{ state }}>
      <SetTagContext.Provider value={{ dispatch }}>
        {children}
      </SetTagContext.Provider>
    </TagContext.Provider>
  );
};
