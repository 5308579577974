import React, { useRef } from 'react';
import {
  Box,
  IconButton,
  Button,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import { MdEdit, MdClear } from 'react-icons/md';
import { useAuth0 } from '@auth0/auth0-react';
import { Tag } from '../../types/tag';
import { ConfirmDialog } from '../../components/ConfirmDialog';
import { PutTag } from '../../types/tagApiTypes';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { putTag } from '../../api';

type Props = {
  tag: Tag;
  editing: boolean;
  setLoaded: {
    readonly on: () => void;
    readonly off: () => void;
    readonly toggle: () => void;
  };
  handleEdit?: () => void;
  setLoadingCircle: React.Dispatch<React.SetStateAction<boolean>>;
};

const createDeleteData: (tag: Tag) => PutTag = (tag) => {
  return {
    name: tag.name,
    enable: false,
    is_system_only: tag.isSystemOnly,
    version: tag.version,
  };
};

export const TagEditButtons: React.VFC<Props> = ({
  tag,
  editing,
  setLoaded,
  handleEdit,
  setLoadingCircle,
}) => {
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const { user, getAccessTokenSilently } = useAuth0();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = useRef<HTMLButtonElement>(null);
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const toast = useToast();

  const handleDelete = () => {
    const deleteData = async (): Promise<void> => {
      const accessToken = await getAccessTokenSilently({
        audience: 'https://www.webinterview.mic.jp/',
        scope: 'update:tags',
      });
      try {
        setLoadingCircle(true);
        const result = await putTag(
          tenantId,
          tag.tagId,
          userId,
          userName,
          accessToken,
          createDeleteData(tag),
        );
        if (result.ok) {
          toast({
            title: '保存完了',
            description: 'タグを削除しました',
            status: 'success',
            duration: 5000,
            isClosable: true,
          });
          setLoaded.toggle();
        } else {
          throw new Error(result.status.toString());
        }
      } catch (error) {
        toast({
          title: `エラー {${error}}`,
          description: (
            <>
              <Text>エラーが発生しました。</Text>
              <Text>恐れ入りますが、再度操作をやり直してください。</Text>
            </>
          ),
          status: 'error',
          duration: 8000,
          isClosable: true,
        });
        setLoaded.toggle();
        console.log(error);
      }
    };
    deleteData();
    onClose();
  };
  return (
    <>
      {tag.isSystemOnly || !tag.enable ? (
        <Box minH="32px"></Box>
      ) : editing ? (
        <IconButton
          aria-label="clear tag"
          icon={<MdClear />}
          bg="white"
          isRound={true}
          size="sm"
          onClick={onOpen}
        />
      ) : (
        <>
          <IconButton
            aria-label="edit tag"
            icon={<MdEdit />}
            bg="white"
            isRound={true}
            size="sm"
            onClick={handleEdit}
          />
          <IconButton
            aria-label="clear tag"
            icon={<MdClear />}
            bg="white"
            isRound={true}
            size="sm"
            onClick={onOpen}
          />
        </>
      )}
      <ConfirmDialog
        isOpen={isOpen}
        cancelRef={cancelRef}
        onClose={onClose}
        headerText={'タグの削除'}
        bodyText={'本当に削除しますか？この操作は元に戻せません。'}
        confirmOk={
          <Button colorScheme="red" onClick={handleDelete} mr={3}>
            削除
          </Button>
        }
        confirmCancel={
          <Button ref={cancelRef} onClick={onClose}>
            キャンセル
          </Button>
        }
      />
    </>
  );
};
