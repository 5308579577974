import { Condition } from '../types/condition';
import { Prerequisite } from '../types/prerequisite';
import { Sex } from '../types/webInterview';

type Inputs = {
  questions: {
    questionId: string;
    focused: boolean;
    answers: {
      questionItemId: string;
      checked: boolean;
      description: string;
      choiceNotes: string;
    }[];
  }[];
};

type Props = {
  readonly condition: Condition | undefined;
  readonly prerequisites: Prerequisite[];
  readonly ageValue?: number;
  readonly sexValue?: Sex;
  readonly inputs?: Inputs;
};

export const isInvalidQuestion: (props: Props) => boolean = ({
  condition,
  prerequisites,
  ageValue = 0,
  sexValue = 'not-known',
  inputs = { questions: [] },
}) => {
  if (!condition) {
    return false;
  }

  const {
    ageRequired,
    sexRequired,
    questionRequired,
    age: ageCondition,
    ageOperator,
    sex: sexCondition,
  } = condition;
  if (!(ageRequired || sexRequired || questionRequired)) {
    return false;
  }

  const isAgeValid = (() => {
    if (!ageRequired) {
      return true;
    }
    switch (ageOperator) {
      case 'greater':
        return ageValue >= ageCondition;
      case 'less':
        return ageValue <= ageCondition;
      default:
        return true;
    }
  })();

  const isSexValid = (() => {
    if (!sexRequired) {
      return true;
    }
    return sexValue === sexCondition;
  })();

  const isQuestionValid = (() => {
    if (!(questionRequired && condition)) {
      return true;
    }

    const { conditionId, prerequisiteQuestionId } = condition;
    const { questions } = inputs;
    const answers = questions.find(
      ({ questionId }) => questionId === prerequisiteQuestionId,
    )?.answers;

    if (!answers) {
      return true;
    }

    return prerequisites
      .filter((p) => p.conditionId === conditionId)
      .some((p) =>
        answers
          .filter((a) => a.checked)
          .some((a) => a.questionItemId === p.questionItemId),
      );
  })();

  return !(isAgeValid && isSexValid && isQuestionValid);
};
