import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Circle,
  Flex,
  Heading,
  Spinner,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';
import { MdFace } from 'react-icons/md';

import { Paper } from '../../components/atoms/Paper';
import { InterviewPersonalInformationForm } from './InterviewPersonalInformationForm';

import { InterviewResultContext } from '../../contexts/InterviewResultContext';
import { InterviewSheetContext } from '../../contexts/InterviewSheetContext';
import { defaultInterviewSheet } from '../../reducers/interviewSheetReducer';
import { defaultInterviewResult } from '../../reducers/interviewResultReducer';
import { NotFoundContent } from '../NotFound';

type Props = {
  loading: boolean;
  tenantId: string;
  interviewSheetId: string;
};

export const PersonalInformation: React.VFC<Props> = ({
  loading,
  tenantId,
  interviewSheetId,
}) => {
  const formSpan = useBreakpointValue({ base: 2, md: 1 });
  const formMargin = useBreakpointValue({ base: '0', md: '2' });
  const history = useHistory();
  const encodedTenantId = btoa(tenantId);

  const { state: interviewResultState } = useContext(InterviewResultContext);
  const { state: interviewSheetState } = useContext(InterviewSheetContext);
  const { interviewResultId } =
    interviewResultState?.[0] ?? defaultInterviewResult;
  const {
    conditionPid,
    conditionZipCode,
    conditionStreetAddress,
    conditionMailAddress,
    releaseDate,
  } =
    interviewSheetState.find((s) => s.interviewSheetId === interviewSheetId) ??
    defaultInterviewSheet;

  if (new Date(releaseDate) > new Date() && !loading) {
    return (
      <Box pb="3">
        <NotFoundContent />
      </Box>
    );
  }

  return (
    <Paper w="full" p="4" mb="4">
      <Heading size="md" mb="4">
        <Flex align="center">
          <Circle color="gray.600" bg="gray.600" size="30px" mr="3">
            <MdFace size="24px" color="white" />
          </Circle>
          <Text color="gray.600">個人情報を入力してください</Text>
        </Flex>
      </Heading>
      {loading ? (
        <Spinner color="blue.400" />
      ) : (
        <InterviewPersonalInformationForm
          interviewResultId={interviewResultId}
          conditionPid={conditionPid}
          conditionZipCode={conditionZipCode}
          conditionStreetAddress={conditionStreetAddress}
          conditionMailAddress={conditionMailAddress}
          handleNext={() =>
            history.push(`/tenants/${encodedTenantId}/interviewQuestion`)
          }
          handlePrevious={() => history.goBack()}
          span={formSpan}
          m={formMargin}
        />
      )}
    </Paper>
  );
};
