import React from 'react';
import {
  Box,
  Button,
  Circle,
  Flex,
  Heading,
  Spinner,
  Text,
} from '@chakra-ui/react';
import { useHistory, useParams } from 'react-router-dom';
import { MdTask } from 'react-icons/md';
import { Paper } from '../../components/atoms/Paper';

type Props = {
  loading: boolean;
  sheets: { id: string; name: string }[];
};

export const SheetSelector: React.VFC<Props> = ({ loading, sheets }) => {
  const { id: tenantId } = useParams<{ id: string }>();
  const history = useHistory();
  const handleSelect = async (interviewSheetId: string) => {
    history.push(
      `/tenants/${tenantId}/interviewPersonalInformation?interviewSheetId=${interviewSheetId}`,
    );
  };

  return (
    <Paper w="full" p="4" mb="4">
      <Heading size="md" mb="4">
        <Flex align="center">
          <Circle color="gray.600" bg="gray.600" size="30px" mr="3">
            <MdTask size="24px" color="white" />
          </Circle>
          <Text color="gray.600">問診票を選択してください</Text>
        </Flex>
      </Heading>
      {loading ? (
        <Spinner color="blue.400" />
      ) : sheets.length ? (
        sheets.map(({ id, name }, idx) => (
          <Box my="4" key={idx}>
            <Button
              w="full"
              colorScheme="webInterview"
              onClick={() => handleSelect(id)}
            >
              {name}
            </Button>
          </Box>
        ))
      ) : (
        <Text color="gray.600">該当するデータがありません</Text>
      )}
    </Paper>
  );
};
