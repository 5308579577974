import React, { useContext, useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Radio,
  RadioGroup,
  Select,
  Text,
  Textarea,
  VStack,
} from '@chakra-ui/react';
import { QuestionContext } from '../../contexts/QuestionContext';
import { QuestionItemContext } from '../../contexts/QuestionItemContext';
import {
  InterviewResultContext,
  SetInterviewResultContext,
} from '../../contexts/InterviewResultContext';
import { ConditionContext } from '../../contexts/ConditionContext';
import { PrerequisiteContext } from '../../contexts/PrerequisiteContext';
import { ageCalculation } from '../../utils/ageCalculation';
import { defaultInterviewResult } from '../../reducers/interviewResultReducer';
import { CHANGE_ANSWER_ITEMS } from '../../actions/interviewResultAction';
import { newId } from '../../domains/newId';
import { AnswerType } from '../../types/webInterview';
import { isInvalidQuestion } from '../../domains/isInvalidQuestion';

type Props = {
  handleNext: () => void;
  handlePrevious: () => void;
};

type Inputs = {
  questions: {
    questionId: string;
    focused: boolean;
    answers: {
      questionItemId: string;
      checked: boolean;
      description: string;
      choiceNotes: string;
    }[];
  }[];
};

export const InterviewQuestionForm: React.VFC<Props> = ({
  handleNext,
  handlePrevious,
}) => {
  const { state: resultState } = useContext(InterviewResultContext);
  const { interviewResultId, interviewSheetId, birthday, sex, answers } =
    resultState[0] ?? defaultInterviewResult;
  const age = ageCalculation(birthday);
  const { state: questionState } = useContext(QuestionContext);
  const { state: questionItemState } = useContext(QuestionItemContext);
  const { state: conditionState } = useContext(ConditionContext);
  const { state: prerequisiteState } = useContext(PrerequisiteContext);
  const { dispatch } = useContext(SetInterviewResultContext);

  const [inputs, setInputs] = useState<Inputs>({
    questions: questionState
      .filter((q) => q.interviewSheetId === interviewSheetId)
      .filter(
        ({ questionId }) =>
          !isInvalidQuestion({
            condition: conditionState.find((c) => c.questionId === questionId),
            prerequisites: prerequisiteState,
            ageValue: age,
            sexValue: sex,
          }),
      )
      .map((q) => ({
        questionId: q.questionId,
        focused: false,
        answers: questionItemState
          .filter((qi) => qi.questionId === q.questionId)
          .map((qi) => ({
            questionItemId: qi.questionItemId,
            checked:
              answers
                .find((a) => a.questionId === q.questionId)
                ?.answerItems.find(
                  (ai) => ai.questionItemId === qi.questionItemId,
                )?.checked ?? false,
            description:
              answers
                .find((a) => a.questionId === q.questionId)
                ?.answerItems.find(
                  (ai) => ai.questionItemId === qi.questionItemId,
                )?.description ?? '',
            choiceNotes:
              answers
                .find((a) => a.questionId === q.questionId)
                ?.answerItems.find(
                  (ai) => ai.questionItemId === qi.questionItemId,
                )?.choiceNotes ?? '',
          })),
      })),
  });

  const questions = questionState
    .filter((q) => q.interviewSheetId === interviewSheetId)
    .filter(
      ({ questionId }) =>
        !isInvalidQuestion({
          condition: conditionState.find((c) => c.questionId === questionId),
          prerequisites: prerequisiteState,
          ageValue: age,
          sexValue: sex,
          inputs: inputs,
        }),
    )
    .slice()
    .sort((a, b) => a.sortNumber - b.sortNumber)
    .map((q, idx) => ({ ...q, sortNumber: idx + 1 }));

  const validations = questionState.map(
    ({ questionId, required, answerType }) => ({
      questionId,
      required,
      onValidate: (inputs: Inputs, isSubmit: boolean = false) => {
        if (!required) {
          return true;
        }
        if (
          isInvalidQuestion({
            condition: conditionState.find((c) => c.questionId === questionId),
            prerequisites: prerequisiteState,
            ageValue: age,
            sexValue: sex,
            inputs: inputs,
          })
        ) {
          return true;
        }
        const { questions } = inputs;
        const targetQuestions = questions.filter(
          (q) => q.questionId === questionId,
        );
        if (!isSubmit && targetQuestions.every((q) => !q.focused)) {
          return true;
        }
        switch (answerType) {
          case 'textbox':
          case 'textline':
            return targetQuestions.every((q) =>
              q.answers.every((a) => a.description),
            );
          case 'checkbox':
          case 'radiobutton':
          case 'combobox':
            return targetQuestions.every((q) =>
              q.answers.some((a) => a.checked),
            );
          default:
            // errror
            return false;
        }
      },
    }),
  );
  const onSubmit = () => {
    const { questions } = inputs;
    setInputs({
      questions: questions.map(({ questionId, answers }) => ({
        questionId,
        answers,
        focused: true,
      })),
    });

    const isValid: boolean = validations.some(
      (x) => !x.onValidate(inputs, true),
    );

    if (isValid) {
      return;
    }

    dispatch({
      type: CHANGE_ANSWER_ITEMS,
      interviewResultId: interviewResultId,
      answers: questions.map(({ questionId, answers }) => {
        const isInvalid = isInvalidQuestion({
          condition: conditionState.find((c) => c.questionId === questionId),
          prerequisites: prerequisiteState,
          ageValue: age,
          sexValue: sex,
          inputs: inputs,
        });

        return {
          answerId: newId('None'),
          questionId: questionId,
          answerItems: answers.map(
            ({ questionItemId, checked, description, choiceNotes }) => ({
              answerItemId: newId('None'),
              questionItemId: questionItemId,
              description: isInvalid ? '' : description,
              checked: isInvalid ? false : checked,
              choiceNotes: isInvalid ? '' : choiceNotes,
            }),
          ),
        };
      }),
    });

    handleNext();
  };

  const handleInputChange = (
    questionId: string,
    questionItemId: string,
    targetProperty: 'checked' | 'choiceNotes' | 'descrption',
    value: string,
    answerType: AnswerType,
  ) => {
    const thisAnswers = inputs.questions.find(
      (q) => q.questionId === questionId,
    ) ?? {
      questionId: '',
      focused: false,
      answers: [
        {
          questionItemId: '',
          checked: false,
          description: '',
          choiceNotes: '',
        },
      ],
    };

    const newAnswers =
      targetProperty === 'checked' && answerType === 'checkbox'
        ? thisAnswers.answers.map((x) =>
            x.questionItemId === questionItemId
              ? { ...x, checked: !x.checked }
              : x,
          )
        : targetProperty === 'checked' &&
          (answerType === 'radiobutton' || answerType === 'combobox')
        ? thisAnswers.answers.map((x) =>
            x.questionItemId === questionItemId
              ? { ...x, checked: true }
              : { ...x, checked: false },
          )
        : targetProperty === 'choiceNotes'
        ? thisAnswers.answers.map((x) =>
            x.questionItemId === questionItemId
              ? { ...x, choiceNotes: value }
              : x,
          )
        : targetProperty === 'descrption'
        ? thisAnswers.answers.map((x) =>
            x.questionItemId === questionItemId
              ? { ...x, description: value }
              : x,
          )
        : undefined;

    if (!newAnswers) {
      return;
    }

    const others = inputs.questions.filter((q) => q.questionId !== questionId);

    setInputs({
      questions: [
        ...others,
        { questionId, focused: true, answers: newAnswers },
      ],
    });
  };

  return (
    <form>
      {questions.map(
        ({ questionId, text, answerType, sortNumber, required }, idx) => (
          <div key={idx}>
            <Box mb="5" value={questionId}>
              <Flex>
                <Text
                  fontSize="lg"
                  fontWeight="bold"
                  color="gray.600"
                  align="start"
                >
                  {`${sortNumber}. ${text}`}
                </Text>
                {required ? (
                  <Text fontSize="lg" fontWeight="bold" color="red.400" ml="1">
                    *
                  </Text>
                ) : (
                  <></>
                )}
              </Flex>
              {answerType === 'checkbox' ? (
                <Box>
                  <VStack spacing="1" align="start" mt="1">
                    {questionItemState
                      .filter((c) => c.questionId === questionId)
                      .slice()
                      .sort((a, b) => a.sortNumber - b.sortNumber)
                      .map(
                        (
                          {
                            questionItemId,
                            choiceText,
                            hasChoiceNotes,
                            choiceNotesPrefix,
                            choiceNotesSuffix,
                            choicePlaceholder,
                          },
                          choiceIdx,
                        ) => (
                          <Box key={choiceIdx} w="full" textAlign="start">
                            <Checkbox
                              colorScheme="webInterview"
                              size="lg"
                              value={questionItemId}
                              onChange={(e) =>
                                handleInputChange(
                                  questionId,
                                  questionItemId,
                                  'checked',
                                  e.target.value,
                                  answerType,
                                )
                              }
                              defaultChecked={
                                inputs.questions
                                  .find((q) => q.questionId === questionId)
                                  ?.answers.find(
                                    (a) => a.questionItemId === questionItemId,
                                  )?.checked
                              }
                            >
                              <Text
                                fontSize="lg"
                                fontWeight="bold"
                                color="gray.600"
                              >
                                {choiceText}
                              </Text>
                            </Checkbox>
                            {hasChoiceNotes ? (
                              <InputGroup size="sm" mt="1">
                                {choiceNotesPrefix.length ? (
                                  <InputLeftAddon
                                    children={choiceNotesPrefix}
                                  />
                                ) : (
                                  <></>
                                )}
                                <Input
                                  placeholder={choicePlaceholder}
                                  color="gray.600"
                                  onChange={(e) =>
                                    handleInputChange(
                                      questionId,
                                      questionItemId,
                                      'choiceNotes',
                                      e.target.value,
                                      answerType,
                                    )
                                  }
                                  value={
                                    inputs.questions
                                      .find((q) => q.questionId === questionId)
                                      ?.answers.find(
                                        (a) =>
                                          a.questionItemId === questionItemId,
                                      )?.choiceNotes
                                  }
                                />
                                {choiceNotesSuffix.length ? (
                                  <InputRightAddon
                                    children={choiceNotesSuffix}
                                  />
                                ) : (
                                  <></>
                                )}
                              </InputGroup>
                            ) : (
                              <></>
                            )}
                          </Box>
                        ),
                      )}
                  </VStack>
                  {validations
                    .find((x) => x.questionId === questionId)
                    ?.onValidate(inputs) ? (
                    <></>
                  ) : (
                    <Text align="start" color="red.400">
                      必須入力です
                    </Text>
                  )}
                </Box>
              ) : answerType === 'radiobutton' ? (
                <RadioGroup
                  onChange={(value) =>
                    handleInputChange(
                      questionId,
                      value,
                      'checked',
                      value,
                      answerType,
                    )
                  }
                  value={
                    inputs.questions
                      .find((q) => q.questionId === questionId)
                      ?.answers.find((a) => a.checked)?.questionItemId
                  }
                >
                  <VStack spacing="1" align="start" mt="1">
                    {questionItemState
                      .filter((c) => c.questionId === questionId)
                      .slice()
                      .sort((a, b) => a.sortNumber - b.sortNumber)
                      .map(
                        (
                          {
                            questionItemId,
                            choiceText,
                            hasChoiceNotes,
                            choiceNotesPrefix,
                            choiceNotesSuffix,
                            choicePlaceholder,
                          },
                          choiceIdx,
                        ) => (
                          <Box key={choiceIdx} w="full" textAlign="start">
                            <Radio
                              colorScheme="webInterview"
                              size="lg"
                              value={questionItemId}
                              isChecked={
                                inputs.questions
                                  .find((q) => q.questionId === questionId)
                                  ?.answers.find(
                                    (a) => a.questionItemId === questionItemId,
                                  )?.checked
                              }
                            >
                              <Text
                                fontSize="lg"
                                fontWeight="bold"
                                color="gray.600"
                              >
                                {choiceText}
                              </Text>
                            </Radio>
                            {hasChoiceNotes ? (
                              <InputGroup size="sm" mt="1">
                                {choiceNotesPrefix.length ? (
                                  <InputLeftAddon
                                    children={choiceNotesPrefix}
                                  />
                                ) : (
                                  <></>
                                )}
                                <Input
                                  placeholder={choicePlaceholder}
                                  color="gray.600"
                                  onChange={(e) =>
                                    handleInputChange(
                                      questionId,
                                      questionItemId,
                                      'choiceNotes',
                                      e.target.value,
                                      answerType,
                                    )
                                  }
                                  value={
                                    inputs.questions
                                      .find((q) => q.questionId === questionId)
                                      ?.answers.find(
                                        (a) =>
                                          a.questionItemId === questionItemId,
                                      )?.choiceNotes
                                  }
                                />
                                {choiceNotesSuffix.length ? (
                                  <InputRightAddon
                                    children={choiceNotesSuffix}
                                  />
                                ) : (
                                  <></>
                                )}
                              </InputGroup>
                            ) : (
                              <></>
                            )}
                          </Box>
                        ),
                      )}
                  </VStack>
                  {validations
                    .find((x) => x.questionId === questionId)
                    ?.onValidate(inputs) ? (
                    <></>
                  ) : (
                    <Text align="start" color="red.400">
                      必須入力です
                    </Text>
                  )}
                </RadioGroup>
              ) : answerType === 'combobox' ? (
                <>
                  <Select
                    placeholder="選択してください"
                    onChange={(e) =>
                      handleInputChange(
                        questionId,
                        e.target.value,
                        'checked',
                        e.target.value,
                        answerType,
                      )
                    }
                    value={
                      inputs.questions
                        .find((q) => q.questionId === questionId)
                        ?.answers.find((a) => a.checked)?.questionItemId
                    }
                    mt="1"
                  >
                    {questionItemState
                      .filter((c) => c.questionId === questionId)
                      .slice()
                      .sort((a, b) => a.sortNumber - b.sortNumber)
                      .map(({ questionItemId, choiceText }, idx) => (
                        <option value={questionItemId} key={idx}>
                          {choiceText}
                        </option>
                      ))}
                  </Select>
                  {questionItemState
                    .filter((c) => c.questionId === questionId)
                    .slice()
                    .sort((a, b) => a.sortNumber - b.sortNumber)
                    .map(
                      (
                        {
                          questionItemId,
                          hasChoiceNotes,
                          choiceNotesPrefix,
                          choiceNotesSuffix,
                          choicePlaceholder,
                        },
                        idx,
                      ) =>
                        hasChoiceNotes &&
                        inputs.questions
                          .find((x) => x.questionId === questionId)
                          ?.answers.find(
                            (x) => x.questionItemId === questionItemId,
                          )?.checked ? (
                          <InputGroup size="sm" mt="1" key={idx}>
                            {choiceNotesPrefix.length ? (
                              <InputLeftAddon children={choiceNotesPrefix} />
                            ) : (
                              <></>
                            )}
                            <Input
                              placeholder={choicePlaceholder}
                              color="gray.600"
                              onChange={(e) =>
                                handleInputChange(
                                  questionId,
                                  questionItemId,
                                  'choiceNotes',
                                  e.target.value,
                                  answerType,
                                )
                              }
                              value={
                                inputs.questions
                                  .find((q) => q.questionId === questionId)
                                  ?.answers.find(
                                    (a) => a.questionItemId === questionItemId,
                                  )?.choiceNotes
                              }
                            />
                            {choiceNotesSuffix.length ? (
                              <InputRightAddon children={choiceNotesSuffix} />
                            ) : (
                              <></>
                            )}
                          </InputGroup>
                        ) : (
                          <div key={idx}></div>
                        ),
                    )}
                  {validations
                    .find((x) => x.questionId === questionId)
                    ?.onValidate(inputs) ? (
                    <></>
                  ) : (
                    <Text align="start" color="red.400">
                      必須入力です
                    </Text>
                  )}
                </>
              ) : answerType === 'textbox' ? (
                <Box mt="1">
                  {questionItemState
                    .filter((c) => c.questionId === questionId)
                    .map(
                      (
                        {
                          questionItemId,
                          descriptionPlaceholder,
                          descriptionPrefix,
                          descriptionSuffix,
                        },
                        choiceIdx,
                      ) => (
                        <div key={choiceIdx}>
                          <InputGroup mt="1">
                            {descriptionPrefix.length ? (
                              <InputLeftAddon children={descriptionPrefix} />
                            ) : (
                              <></>
                            )}
                            <Input
                              color="gray.600"
                              placeholder={descriptionPlaceholder}
                              onChange={(e) => {
                                handleInputChange(
                                  questionId,
                                  questionItemId,
                                  'descrption',
                                  e.target.value,
                                  answerType,
                                );
                              }}
                              value={
                                inputs.questions
                                  .find((q) => q.questionId === questionId)
                                  ?.answers.find(
                                    (a) => a.questionItemId === questionItemId,
                                  )?.description
                              }
                            />
                            {descriptionSuffix.length ? (
                              <InputRightAddon children={descriptionSuffix} />
                            ) : (
                              <></>
                            )}
                          </InputGroup>
                          {validations
                            .find((x) => x.questionId === questionId)
                            ?.onValidate(inputs) ? (
                            <></>
                          ) : (
                            <Text align="start" color="red.400">
                              必須入力です
                            </Text>
                          )}
                        </div>
                      ),
                    )}
                </Box>
              ) : answerType === 'textline' ? (
                <Box mt="1">
                  {questionItemState
                    .filter((c) => c.questionId === questionId)
                    .map(
                      (
                        {
                          questionItemId,
                          descriptionPrefix,
                          descriptionSuffix,
                          descriptionPlaceholder,
                        },
                        choiceIdx,
                      ) => (
                        <Box key={choiceIdx}>
                          <Text color="gray.600" textAlign="left">
                            {descriptionPrefix}
                          </Text>
                          <Textarea
                            placeholder={descriptionPlaceholder}
                            onChange={(e) => {
                              handleInputChange(
                                questionId,
                                questionItemId,
                                'descrption',
                                e.target.value,
                                answerType,
                              );
                            }}
                            value={
                              inputs.questions
                                .find((q) => q.questionId === questionId)
                                ?.answers.find(
                                  (a) => a.questionItemId === questionItemId,
                                )?.description
                            }
                          />
                          <Text color="gray.600" textAlign="left">
                            {descriptionSuffix}
                          </Text>
                        </Box>
                      ),
                    )}
                  {validations
                    .find((x) => x.questionId === questionId)
                    ?.onValidate(inputs) ? (
                    <></>
                  ) : (
                    <Text align="start" color="red.400">
                      必須入力です
                    </Text>
                  )}
                </Box>
              ) : (
                <Text key={idx}>定義されてない</Text>
              )}
            </Box>
          </div>
        ),
      )}
      <Flex>
        <Button borderRadius="full" w="full" mr="4" onClick={handlePrevious}>
          戻る
        </Button>
        <Button
          borderRadius="full"
          w="full"
          colorScheme="webInterview"
          onClick={() => onSubmit()}
        >
          次に進む
        </Button>
      </Flex>
    </form>
  );
};
