import React, { createContext } from 'react';
import { TagAction } from '../actions/tagAction';
import { Tag } from '../types/tag';

export const TagContext = createContext(
  {} as {
    state: Tag[];
  },
);

export const SetTagContext = createContext(
  {} as {
    dispatch: React.Dispatch<TagAction>;
  },
);
