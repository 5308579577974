import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Button,
  Flex,
  Heading,
  HStack,
  Spacer,
  Box,
  CircularProgress,
  useBoolean,
} from '@chakra-ui/react';
import { useAuth0 } from '@auth0/auth0-react';
import { MdDownload } from 'react-icons/md';
import { GetInterviewResult } from '../../types/interviewResultConfirmApiTypes';
import { Answer } from '../../types/answer';
import { InterviewSheetHistory } from '../../types/interviewSheetHistory';
import { useGetMetadata } from '../../hooks/useGetMetadata';
import { Layout } from '../../components/Layout/Layout';
import { PersonalInformation } from './PersonalInformation';
import {
  toAnswers,
  toInterviewSheetHistory,
  toPersonalInformation,
} from '../../domains/toInterviewResultConfirm';
import { UpdateHistory } from './UpdateHistory';
import { InterviewResultQuestions } from './InterviewResultQuestions';
import { PersonalInformation as PersonalInformationType } from '../../types/personalInformation';
import { IdentificationMenu } from './IdentificationMenu';
import { getInterviewResult } from '../../api/index';
import { NoData } from '../../components/NoData';
import { Authorization } from '../../components/Authorization';
import { PATHNAME } from '../../constant/constant';

type Props = {};

export const InterviewResult: React.VFC<Props> = () => {
  const { id } = useParams<{ id: string }>();
  const { user, getAccessTokenSilently } = useAuth0();
  const { auth0UserData } = useGetMetadata();
  const { tenantId } = auth0UserData;
  const userId = user?.sub ?? '';
  const userName = encodeURI(user?.name ?? '');
  const [loading, setLoading] = useState(false);
  const [save, setSave] = useBoolean(false);
  const [status, setStatus] = useState(200);

  const [personalInformation, setPersonalInformation] = useState(
    {} as PersonalInformationType,
  );
  const [answers, setAnswers] = useState({} as Answer[]);
  const [interviewSheetHistory, setInterviewSheetHistory] = useState(
    {} as InterviewSheetHistory,
  );

  useEffect(() => {
    const getData = async (): Promise<void> => {
      try {
        setLoading(true);
        const accessToken = await getAccessTokenSilently({
          audience: 'https://www.webinterview.mic.jp/',
          scope: 'read:interview-results',
        });
        const result = await getInterviewResult(
          tenantId,
          id,
          userId,
          userName,
          accessToken,
        );
        if (result.ok) {
          const data: GetInterviewResult = await result.json();
          setPersonalInformation(toPersonalInformation(data));
          data.answers ? setAnswers(toAnswers(data.answers)) : ({} as Answer[]);
          data.interview_sheet_history
            ? setInterviewSheetHistory(
                toInterviewSheetHistory({
                  ...data.interview_sheet_history,
                  questions: data.interview_sheet_history.questions
                    .map((question) => ({
                      ...question,
                      question_items: question.question_items
                        .slice()
                        .sort((a, b) => a.sort_number - b.sort_number),
                    }))
                    .slice()
                    .sort((a, b) => a.sort_number - b.sort_number),
                }),
              )
            : ({} as InterviewSheetHistory);
        } else {
          console.log(result.status);
          setStatus(result.status);
        }
      } catch (error) {
        console.error({ error });
      } finally {
        setLoading(false);
      }
    };
    getData();
  }, [user, getAccessTokenSilently, save, id, tenantId, userId, userName]);
  return (
    <Layout>
      <Authorization pathName={PATHNAME.INTERVIEW_RESULT}>
        <Flex m="4">
          <Heading size="lg" color="gray.600">
            問診結果
          </Heading>
          <Spacer />
          <HStack spacing="3">
            <IdentificationMenu
              personalInformation={personalInformation}
              save={setSave}
              menuDisabled={loading || status > 200}
            />
            <Button
              aria-label="download"
              leftIcon={<MdDownload size="24px" />}
              colorScheme="webInterview"
              onClick={window.print}
              isDisabled={loading || status > 200}
            >
              出力
            </Button>
          </HStack>
        </Flex>
        {loading ? (
          <CircularProgress isIndeterminate color="blue.300" />
        ) : status === 404 ? (
          <NoData />
        ) : (
          <>
            <Box mb="8">
              <PersonalInformation
                personalInformation={personalInformation}
                save={setSave}
              />
            </Box>
            <Box mb="8">
              <InterviewResultQuestions
                answers={answers}
                questions={interviewSheetHistory.questions}
              />
            </Box>
            <Box>
              <UpdateHistory />
            </Box>
          </>
        )}
      </Authorization>
    </Layout>
  );
};
