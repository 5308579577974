import React from 'react';
import { Box, Text } from '@chakra-ui/react';
import { Question, Answer } from '../../types/interviewResultConfirm';
import { InterviewResultAnswer } from './InterviewResultAnswer';

type Props = {
  question: Question;
  answers: Answer[];
};

export const InterviewResultQuestion: React.VFC<Props> = ({
  question,
  answers,
}) => {
  const { id, text, sortNumber, questionItems, type } = question;
  const answer =
    answers.find((a) => a.questionId === id) ??
    ({ id: '', questionId: '', answerItems: [] } as Answer);
  return (
    <Box mb="5">
      <Text
        fontSize="lg"
        fontWeight="bold"
        color="gray.600"
        textAlign="left"
        my="2"
      >
        {sortNumber}. {text}
      </Text>
      <Box ml="3">
        {questionItems.map((qi, idx) => (
          <InterviewResultAnswer
            key={idx}
            questionItem={qi}
            answer={answer}
            answerType={type}
          />
        ))}
      </Box>
    </Box>
  );
};
