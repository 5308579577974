import { createBreakpoints } from '@chakra-ui/theme-tools';
import { extendTheme } from '@chakra-ui/react';

const breakpoints = createBreakpoints({
  sm: '321px',
  md: '769px',
  lg: '961px',
  xl: '1025px',
  '2xl': '1281px',
});

export const theme = extendTheme({
  breakpoints,
  components: {
    Button: { baseStyle: { _focus: { boxShadow: 'none' } } },

    Accordion: { baseStyle: { button: { _focus: { boxShadow: 'none' } } } },
    Link: { baseStyle: { _focus: { boxShadow: 'none' } } },
  },
  colors: {
    webInterview: {
      500: '#90CDF4',
      600: '#63B3ED',
      700: '#4299E1',
    },
  },
});
